export const fabrics = [
  {
    article_number: "1ESPR01027",
    color: "27 sunset orange",
    fabric_uuid: "VADAIN-70035104-1ESPR01027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01027&h=500&w=500&type=live",
    name: "Espri 27 sunset orange",
    rgb: {
      r: 157,
      g: 86,
      b: 72,
    },
  },
  {
    article_number: "1ESPR01093",
    color: "93 steel",
    fabric_uuid: "VADAIN-70035082-1ESPR01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01093&h=500&w=500&type=live",
    name: "Espri 93 steel",
    rgb: {
      r: 118,
      g: 118,
      b: 122,
    },
  },
  {
    article_number: "1LINO01011",
    color: "11 off white",
    fabric_uuid: "VADAIN-2599-1LINO01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01011&h=500&w=500&type=live",
    name: "Linos 11 off white",
    rgb: {
      r: 217,
      g: 217,
      b: 209,
    },
  },
  {
    article_number: "1LINO01045",
    color: "45 oudroze",
    fabric_uuid: "VADAIN-70000198-1LINO01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01045&h=500&w=500&type=live",
    name: "Linos 45 oudroze",
    rgb: {
      r: 206,
      g: 181,
      b: 167,
    },
  },
  {
    article_number: "1REAL02035",
    color: "35 tranquil dawn",
    fabric_uuid: "VADAIN-70037639-1REAL02035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1REAL02035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1REAL02035&h=500&w=500&type=live",
    name: "Real linen 35 tranquil dawn",
    rgb: {
      r: 174,
      g: 177,
      b: 174,
    },
  },
  {
    article_number: "1REAL02040",
    color: "40 rose water",
    fabric_uuid: "VADAIN-70037641-1REAL02040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1REAL02040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1REAL02040&h=500&w=500&type=live",
    name: "Real linen 40 rose water",
    rgb: {
      r: 186,
      g: 178,
      b: 169,
    },
  },
  {
    article_number: "1ROOT02017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70037684-1ROOT02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ROOT02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ROOT02017&h=500&w=500&type=live",
    name: "Roots 17 linen",
    rgb: {
      r: 172,
      g: 164,
      b: 153,
    },
  },
  {
    article_number: "1SASA02030",
    color: "30 green",
    fabric_uuid: "VADAIN-70037690-1SASA02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1SASA02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1SASA02030&h=500&w=500&type=live",
    name: "Sasa kh 30 green",
    rgb: {
      r: 159,
      g: 154,
      b: 143,
    },
  },
  {
    article_number: "1SASA02047",
    color: "47 red dust",
    fabric_uuid: "VADAIN-70037691-1SASA02047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1SASA02047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1SASA02047&h=500&w=500&type=live",
    name: "Sasa kh 47 red dust",
    rgb: {
      r: 167,
      g: 161,
      b: 155,
    },
  },
  {
    article_number: "1STARN01060",
    color: "60 heather",
    fabric_uuid: "VADAIN-70036152-1STARN01060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1STARN01060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1STARN01060&h=500&w=500&type=live",
    name: "Starnight 60 heather",
    rgb: {
      r: 151,
      g: 126,
      b: 135,
    },
  },
  {
    article_number: "1VITA01063",
    color: "63 lilac",
    fabric_uuid: "VADAIN-70036164-1VITA01063",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1VITA01063.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1VITA01063&h=500&w=500&type=live",
    name: "Vita 63 lilac",
    rgb: {
      r: 177,
      g: 165,
      b: 176,
    },
  },
  {
    article_number: "1AFRE02045",
    color: "45 soft pink",
    fabric_uuid: "VADAIN-70044206-1AFRE02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02045&h=500&w=500&type=live",
    name: "A fresh start 45 soft pink",
    rgb: {
      r: 204,
      g: 182,
      b: 175,
    },
  },
  {
    article_number: "1AFRE02011",
    color: "11 cotton",
    fabric_uuid: "VADAIN-70044198-1AFRE02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02011&h=500&w=500&type=live",
    name: "A fresh start kh 11 cotton",
    rgb: {
      r: 207,
      g: 200,
      b: 192,
    },
  },
  {
    article_number: "1AFRE02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70044199-1AFRE02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02015&h=500&w=500&type=live",
    name: "A fresh start kh 15 cream",
    rgb: {
      r: 201,
      g: 193,
      b: 181,
    },
  },
  {
    article_number: "1AFRE02017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70044200-1AFRE02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02017&h=500&w=500&type=live",
    name: "A fresh start kh 17 sand",
    rgb: {
      r: 174,
      g: 161,
      b: 146,
    },
  },
  {
    article_number: "1AFRE02020",
    color: "20 lemon",
    fabric_uuid: "VADAIN-70044201-1AFRE02020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02020&h=500&w=500&type=live",
    name: "A fresh start kh 20 lemon",
    rgb: {
      r: 200,
      g: 184,
      b: 148,
    },
  },
  {
    article_number: "1AFRE02030",
    color: "30 spring grass",
    fabric_uuid: "VADAIN-70044202-1AFRE02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02030&h=500&w=500&type=live",
    name: "A fresh start kh 30 spring gra",
    rgb: {
      r: 168,
      g: 166,
      b: 145,
    },
  },
  {
    article_number: "1AFRE02033",
    color: "33 pine tree",
    fabric_uuid: "VADAIN-70044203-1AFRE02033",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02033.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02033&h=500&w=500&type=live",
    name: "A fresh start kh 33 pine tree",
    rgb: {
      r: 152,
      g: 160,
      b: 152,
    },
  },
  {
    article_number: "1AFRE02035",
    color: "35 orange",
    fabric_uuid: "VADAIN-70044204-1AFRE02035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02035&h=500&w=500&type=live",
    name: "A fresh start kh 35 orange",
    rgb: {
      r: 174,
      g: 143,
      b: 132,
    },
  },
  {
    article_number: "1AFRE02040",
    color: "40 lipstick",
    fabric_uuid: "VADAIN-70044205-1AFRE02040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02040&h=500&w=500&type=live",
    name: "A fresh start kh 40 lipstick",
    rgb: {
      r: 183,
      g: 135,
      b: 133,
    },
  },
  {
    article_number: "1AFRE02046",
    color: "46 pink",
    fabric_uuid: "VADAIN-70044207-1AFRE02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02046&h=500&w=500&type=live",
    name: "A fresh start kh 46 pink",
    rgb: {
      r: 182,
      g: 157,
      b: 154,
    },
  },
  {
    article_number: "1AFRE02050",
    color: "50 blue sky",
    fabric_uuid: "VADAIN-70044208-1AFRE02050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02050&h=500&w=500&type=live",
    name: "A fresh start kh 50 blue sky",
    rgb: {
      r: 153,
      g: 162,
      b: 171,
    },
  },
  {
    article_number: "1AFRE02052",
    color: "52 navy",
    fabric_uuid: "VADAIN-70044209-1AFRE02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02052&h=500&w=500&type=live",
    name: "A fresh start kh 52 navy",
    rgb: {
      r: 125,
      g: 125,
      b: 129,
    },
  },
  {
    article_number: "1AFRE02055",
    color: "55 blue fog",
    fabric_uuid: "VADAIN-70044210-1AFRE02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02055&h=500&w=500&type=live",
    name: "A fresh start kh 55 blue fog",
    rgb: {
      r: 175,
      g: 181,
      b: 179,
    },
  },
  {
    article_number: "1AFRE02059",
    color: "59 mint",
    fabric_uuid: "VADAIN-70044211-1AFRE02059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02059&h=500&w=500&type=live",
    name: "A fresh start kh 59 mint",
    rgb: {
      r: 166,
      g: 176,
      b: 160,
    },
  },
  {
    article_number: "1AFRE02062",
    color: "62 lilac",
    fabric_uuid: "VADAIN-70044095-1AFRE02062",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02062.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02062&h=500&w=500&type=live",
    name: "A fresh start kh 62 lilac",
    rgb: {
      r: 175,
      g: 167,
      b: 169,
    },
  },
  {
    article_number: "1AFRE02080",
    color: "80 chestnut",
    fabric_uuid: "VADAIN-70044212-1AFRE02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02080&h=500&w=500&type=live",
    name: "A fresh start kh 80 chestnut",
    rgb: {
      r: 152,
      g: 139,
      b: 127,
    },
  },
  {
    article_number: "1AFRE02082",
    color: "82 cacao",
    fabric_uuid: "VADAIN-70045606-1AFRE02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02082&h=500&w=500&type=live",
    name: "A fresh start kh 82 cacao",
    rgb: {
      r: 152,
      g: 143,
      b: 134,
    },
  },
  {
    article_number: "1AFRE02083",
    color: "83 milkchocolate",
    fabric_uuid: "VADAIN-70044214-1AFRE02083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02083&h=500&w=500&type=live",
    name: "A fresh start kh 83 milkchocol",
    rgb: {
      r: 173,
      g: 163,
      b: 149,
    },
  },
  {
    article_number: "1AFRE02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70044215-1AFRE02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02092&h=500&w=500&type=live",
    name: "A fresh start kh 92 silver",
    rgb: {
      r: 177,
      g: 174,
      b: 165,
    },
  },
  {
    article_number: "1AFRE02093",
    color: "93 lead",
    fabric_uuid: "VADAIN-70044216-1AFRE02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02093&h=500&w=500&type=live",
    name: "A fresh start kh 93 lead",
    rgb: {
      r: 160,
      g: 158,
      b: 153,
    },
  },
  {
    article_number: "1AFRE02094",
    color: "94 pewter",
    fabric_uuid: "VADAIN-70044217-1AFRE02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02094&h=500&w=500&type=live",
    name: "A fresh start kh 94 pewter",
    rgb: {
      r: 158,
      g: 155,
      b: 147,
    },
  },
  {
    article_number: "1AFRE02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70044218-1AFRE02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AFRE02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AFRE02095&h=500&w=500&type=live",
    name: "A fresh start kh 95 black",
    rgb: {
      r: 124,
      g: 122,
      b: 117,
    },
  },
  {
    article_number: "1ANEW01023",
    color: "23A silverwheat",
    fabric_uuid: "VADAIN-70044219-1ANEW01023",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01023.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01023&h=500&w=500&type=live",
    name: "A new beginning 23a silver whe",
    rgb: {
      r: 167,
      g: 157,
      b: 145,
    },
  },
  {
    article_number: "1ANEW01023B",
    color: "23B wheat",
    fabric_uuid: "VADAIN-70044191-1ANEW01023B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01023.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01023B&h=500&w=500&type=live",
    name: "A new beginning 23b wheat",
    rgb: {
      r: 168,
      g: 152,
      b: 132,
    },
  },
  {
    article_number: "1ANEW01027",
    color: "27A grey siena",
    fabric_uuid: "VADAIN-70044220-1ANEW01027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01027&h=500&w=500&type=live",
    name: "A new beginning 27a grey siena",
    rgb: {
      r: 156,
      g: 139,
      b: 133,
    },
  },
  {
    article_number: "1ANEW01027B",
    color: "27B siena",
    fabric_uuid: "VADAIN-70044092-1ANEW01027B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01027B&h=500&w=500&type=live",
    name: "A new beginning 27b siena",
    rgb: {
      r: 162,
      g: 131,
      b: 120,
    },
  },
  {
    article_number: "1ANEW01030",
    color: "30A silvermoss",
    fabric_uuid: "VADAIN-70044221-1ANEW01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01030&h=500&w=500&type=live",
    name: "A new beginning 30a silver mos",
    rgb: {
      r: 140,
      g: 136,
      b: 125,
    },
  },
  {
    article_number: "1ANEW01030B",
    color: "30B moss",
    fabric_uuid: "VADAIN-70044222-1ANEW01030B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01030B&h=500&w=500&type=live",
    name: "A new beginning 30b moss",
    rgb: {
      r: 147,
      g: 146,
      b: 140,
    },
  },
  {
    article_number: "1ANEW01037",
    color: "37A lightocean",
    fabric_uuid: "VADAIN-70044223-1ANEW01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01037&h=500&w=500&type=live",
    name: "A new beginning 37a light ocea",
    rgb: {
      r: 134,
      g: 138,
      b: 137,
    },
  },
  {
    article_number: "1ANEW01037B",
    color: "37B dark ocean",
    fabric_uuid: "VADAIN-70044224-1ANEW01037B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01037B&h=500&w=500&type=live",
    name: "A new beginning 37b dark ocean",
    rgb: {
      r: 142,
      g: 146,
      b: 145,
    },
  },
  {
    article_number: "1ANEW01083",
    color: "83A mixedcaramel",
    fabric_uuid: "VADAIN-70044225-1ANEW01083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01083&h=500&w=500&type=live",
    name: "A new beginning 83a mixed cara",
    rgb: {
      r: 140,
      g: 129,
      b: 117,
    },
  },
  {
    article_number: "1ANEW01083B",
    color: "83B caramel",
    fabric_uuid: "VADAIN-70044226-1ANEW01083B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01083B&h=500&w=500&type=live",
    name: "A new beginning 83b caramel",
    rgb: {
      r: 156,
      g: 139,
      b: 119,
    },
  },
  {
    article_number: "1ANEW01092",
    color: "92A meleesilver",
    fabric_uuid: "VADAIN-70044227-1ANEW01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01092&h=500&w=500&type=live",
    name: "A new beginning 92a warm melee",
    rgb: {
      r: 154,
      g: 152,
      b: 150,
    },
  },
  {
    article_number: "1ANEW01092B",
    color: "92B melee silver",
    fabric_uuid: "VADAIN-70044197-1ANEW01092B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEW01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEW01092B&h=500&w=500&type=live",
    name: "A new beginning 92b melee silv",
    rgb: {
      r: 170,
      g: 167,
      b: 163,
    },
  },
  {
    article_number: "1ADJUS2110",
    color: "110 white",
    fabric_uuid: "VADAIN-70039427-1ADJUS2110",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2110.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2110&h=500&w=500&type=live",
    name: "Adjust kh 110 white",
    rgb: {
      r: 225,
      g: 225,
      b: 225,
    },
  },
  {
    article_number: "1ADJUS2120",
    color: "120 cotton",
    fabric_uuid: "VADAIN-70039440-1ADJUS2120",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2120.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2120&h=500&w=500&type=live",
    name: "Adjust kh 120 cotton",
    rgb: {
      r: 203,
      g: 191,
      b: 178,
    },
  },
  {
    article_number: "1ADJUS2130",
    color: "130 cashew",
    fabric_uuid: "VADAIN-70039439-1ADJUS2130",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2130.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2130&h=500&w=500&type=live",
    name: "Adjust kh 130 cashew",
    rgb: {
      r: 188,
      g: 174,
      b: 160,
    },
  },
  {
    article_number: "1ADJUS2160",
    color: "160 meringue",
    fabric_uuid: "VADAIN-70039438-1ADJUS2160",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2160&h=500&w=500&type=live",
    name: "Adjust kh 160 meringue",
    rgb: {
      r: 183,
      g: 167,
      b: 154,
    },
  },
  {
    article_number: "1ADJUS2170",
    color: "170 linen",
    fabric_uuid: "VADAIN-70039437-1ADJUS2170",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2170.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2170&h=500&w=500&type=live",
    name: "Adjust kh 170 linen",
    rgb: {
      r: 187,
      g: 176,
      b: 160,
    },
  },
  {
    article_number: "1ADJUS2360",
    color: "360 pistache",
    fabric_uuid: "VADAIN-70039436-1ADJUS2360",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2360.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2360&h=500&w=500&type=live",
    name: "Adjust kh 360 pistache",
    rgb: {
      r: 112,
      g: 115,
      b: 104,
    },
  },
  {
    article_number: "1ADJUS2450",
    color: "450 soft pink",
    fabric_uuid: "VADAIN-70039435-1ADJUS2450",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2450.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2450&h=500&w=500&type=live",
    name: "Adjust kh 450 soft pink",
    rgb: {
      r: 163,
      g: 139,
      b: 134,
    },
  },
  {
    article_number: "1ADJUS2510",
    color: "510 sky",
    fabric_uuid: "VADAIN-70039433-1ADJUS2510",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2510.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2510&h=500&w=500&type=live",
    name: "Adjust kh 510 sky",
    rgb: {
      r: 168,
      g: 165,
      b: 166,
    },
  },
  {
    article_number: "1ADJUS2860",
    color: "860 glamorous taupe",
    fabric_uuid: "VADAIN-70039432-1ADJUS2860",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2860.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2860&h=500&w=500&type=live",
    name: "Adjust kh 860 glamorous taupe",
    rgb: {
      r: 128,
      g: 112,
      b: 99,
    },
  },
  {
    article_number: "1ADJUS2900",
    color: "900 grey",
    fabric_uuid: "VADAIN-70039430-1ADJUS2900",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2900.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2900&h=500&w=500&type=live",
    name: "Adjust KH 900 grey",
    rgb: {
      r: 184,
      g: 180,
      b: 176,
    },
  },
  {
    article_number: "1ADJUS2920",
    color: "920 silver",
    fabric_uuid: "VADAIN-70039429-1ADJUS2920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2920&h=500&w=500&type=live",
    name: "Adjust kh 920 silver",
    rgb: {
      r: 189,
      g: 190,
      b: 190,
    },
  },
  {
    article_number: "1ADJUS2940",
    color: "940 cloudy",
    fabric_uuid: "VADAIN-70039428-1ADJUS2940",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADJUS2940.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADJUS2940&h=500&w=500&type=live",
    name: "Adjust kh 940 cloudy",
    rgb: {
      r: 151,
      g: 149,
      b: 147,
    },
  },
  {
    article_number: "1ADVE01017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70022172-1ADVE01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADVE01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADVE01017&h=500&w=500&type=live",
    name: "Adventure 17 sand",
    rgb: {
      r: 163,
      g: 153,
      b: 142,
    },
  },
  {
    article_number: "1ADVE01065",
    color: "65 heartwood",
    fabric_uuid: "VADAIN-70022175-1ADVE01065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADVE01065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADVE01065&h=500&w=500&type=live",
    name: "Adventure 65 heartwood",
    rgb: {
      r: 161,
      g: 150,
      b: 146,
    },
  },
  {
    article_number: "1ADVE01086",
    color: "86 taupe",
    fabric_uuid: "VADAIN-70022174-1ADVE01086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADVE01086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADVE01086&h=500&w=500&type=live",
    name: "Adventure 86 taupe",
    rgb: {
      r: 107,
      g: 101,
      b: 98,
    },
  },
  {
    article_number: "1ADVE01093",
    color: "93 pewter",
    fabric_uuid: "VADAIN-70022173-1ADVE01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ADVE01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ADVE01093&h=500&w=500&type=live",
    name: "Adventure 93 pewter",
    rgb: {
      r: 117,
      g: 118,
      b: 117,
    },
  },
  {
    article_number: "1AMOR01012",
    color: "12 pebble",
    fabric_uuid: "VADAIN-70035905-1AMOR01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01012&h=500&w=500&type=live",
    name: "Amor 12 pebble",
    rgb: {
      r: 181,
      g: 172,
      b: 161,
    },
  },
  {
    article_number: "1AMOR01014",
    color: "14 greige",
    fabric_uuid: "VADAIN-70035934-1AMOR01014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01014&h=500&w=500&type=live",
    name: "Amor 14 greige",
    rgb: {
      r: 189,
      g: 186,
      b: 180,
    },
  },
  {
    article_number: "1AMOR01015",
    color: "15 ecru",
    fabric_uuid: "VADAIN-70035920-1AMOR01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01015&h=500&w=500&type=live",
    name: "Amor 15 ecru",
    rgb: {
      r: 202,
      g: 198,
      b: 189,
    },
  },
  {
    article_number: "1AMOR01016",
    color: "16 biscuit",
    fabric_uuid: "VADAIN-70035939-1AMOR01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01016&h=500&w=500&type=live",
    name: "Amor 16 biscuit",
    rgb: {
      r: 176,
      g: 163,
      b: 147,
    },
  },
  {
    article_number: "1AMOR01017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70035917-1AMOR01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01017&h=500&w=500&type=live",
    name: "Amor 17 sand",
    rgb: {
      r: 163,
      g: 146,
      b: 126,
    },
  },
  {
    article_number: "1AMOR01018",
    color: "18 oatmeal",
    fabric_uuid: "VADAIN-70035932-1AMOR01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01018&h=500&w=500&type=live",
    name: "Amor 18 oatmeal",
    rgb: {
      r: 185,
      g: 178,
      b: 164,
    },
  },
  {
    article_number: "1AMOR01019",
    color: "19 clay",
    fabric_uuid: "VADAIN-70035909-1AMOR01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01019&h=500&w=500&type=live",
    name: "Amor 19 clay",
    rgb: {
      r: 155,
      g: 142,
      b: 134,
    },
  },
  {
    article_number: "1AMOR01020",
    color: "20 mustard",
    fabric_uuid: "VADAIN-70035930-1AMOR01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01020&h=500&w=500&type=live",
    name: "Amor 20 mustard",
    rgb: {
      r: 185,
      g: 156,
      b: 86,
    },
  },
  {
    article_number: "1AMOR01021",
    color: "21 curry",
    fabric_uuid: "VADAIN-70035907-1AMOR01021",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01021.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01021&h=500&w=500&type=live",
    name: "Amor 21 curry",
    rgb: {
      r: 145,
      g: 115,
      b: 80,
    },
  },
  {
    article_number: "1AMOR01022",
    color: "22 golden syrup",
    fabric_uuid: "VADAIN-70035922-1AMOR01022",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01022.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01022&h=500&w=500&type=live",
    name: "Amor 22 golden syrup",
    rgb: {
      r: 169,
      g: 131,
      b: 85,
    },
  },
  {
    article_number: "1AMOR01028",
    color: "28 rooibos tea",
    fabric_uuid: "VADAIN-70035927-1AMOR01028",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01028.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01028&h=500&w=500&type=live",
    name: "Amor 28 rooibos tea",
    rgb: {
      r: 133,
      g: 72,
      b: 64,
    },
  },
  {
    article_number: "1AMOR01029",
    color: "29 siena",
    fabric_uuid: "VADAIN-70035913-1AMOR01029",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01029.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01029&h=500&w=500&type=live",
    name: "Amor 29 siena",
    rgb: {
      r: 116,
      g: 72,
      b: 64,
    },
  },
  {
    article_number: "1AMOR01030",
    color: "30 emerald",
    fabric_uuid: "VADAIN-70035918-1AMOR01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01030&h=500&w=500&type=live",
    name: "Amor 30 emerald",
    rgb: {
      r: 73,
      g: 102,
      b: 94,
    },
  },
  {
    article_number: "1AMOR01032",
    color: "32 light mint",
    fabric_uuid: "VADAIN-70035933-1AMOR01032",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01032.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01032&h=500&w=500&type=live",
    name: "Amor 32 light mint",
    rgb: {
      r: 177,
      g: 178,
      b: 175,
    },
  },
  {
    article_number: "1AMOR01033",
    color: "33 antique green",
    fabric_uuid: "VADAIN-70035931-1AMOR01033",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01033.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01033&h=500&w=500&type=live",
    name: "Amor 33 antique green",
    rgb: {
      r: 134,
      g: 145,
      b: 127,
    },
  },
  {
    article_number: "1AMOR01036",
    color: "36 green bay",
    fabric_uuid: "VADAIN-70035910-1AMOR01036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01036&h=500&w=500&type=live",
    name: "Amor 36 green bay",
    rgb: {
      r: 125,
      g: 133,
      b: 128,
    },
  },
  {
    article_number: "1AMOR01040",
    color: "40 red",
    fabric_uuid: "VADAIN-70035914-1AMOR01040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01040&h=500&w=500&type=live",
    name: "Amor 40 red",
    rgb: {
      r: 144,
      g: 60,
      b: 69,
    },
  },
  {
    article_number: "1AMOR01046",
    color: "46 rose water",
    fabric_uuid: "VADAIN-70035921-1AMOR01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01046&h=500&w=500&type=live",
    name: "Amor 46 rose water",
    rgb: {
      r: 195,
      g: 178,
      b: 167,
    },
  },
  {
    article_number: "1AMOR01047",
    color: "47 soft salmon",
    fabric_uuid: "VADAIN-70035935-1AMOR01047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01047&h=500&w=500&type=live",
    name: "Amor 47 soft salmon",
    rgb: {
      r: 196,
      g: 167,
      b: 148,
    },
  },
  {
    article_number: "1AMOR01048",
    color: "48 valerian",
    fabric_uuid: "VADAIN-70035925-1AMOR01048",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01048.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01048&h=500&w=500&type=live",
    name: "Amor 48 valerian",
    rgb: {
      r: 157,
      g: 127,
      b: 123,
    },
  },
  {
    article_number: "1AMOR01050",
    color: "50 royal blue",
    fabric_uuid: "VADAIN-70035912-1AMOR01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01050&h=500&w=500&type=live",
    name: "Amor 50 royal blue",
    rgb: {
      r: 64,
      g: 82,
      b: 111,
    },
  },
  {
    article_number: "1AMOR01051",
    color: "51 washed blue",
    fabric_uuid: "VADAIN-70035937-1AMOR01051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01051&h=500&w=500&type=live",
    name: "Amor 51 washed blue",
    rgb: {
      r: 97,
      g: 113,
      b: 136,
    },
  },
  {
    article_number: "1AMOR01058",
    color: "58 blue mist",
    fabric_uuid: "VADAIN-70035916-1AMOR01058",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01058.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01058&h=500&w=500&type=live",
    name: "Amor 58 blue mist",
    rgb: {
      r: 161,
      g: 167,
      b: 171,
    },
  },
  {
    article_number: "1AMOR01059",
    color: "59 scandinavian blue",
    fabric_uuid: "VADAIN-70035928-1AMOR01059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01059&h=500&w=500&type=live",
    name: "Amor 59 scandinavian blue",
    rgb: {
      r: 113,
      g: 132,
      b: 141,
    },
  },
  {
    article_number: "1AMOR01065",
    color: "65 dusty purple",
    fabric_uuid: "VADAIN-70035906-1AMOR01065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01065&h=500&w=500&type=live",
    name: "Amor 65 dusty purple",
    rgb: {
      r: 128,
      g: 118,
      b: 125,
    },
  },
  {
    article_number: "1AMOR01080",
    color: "80 chestnut",
    fabric_uuid: "VADAIN-70035926-1AMOR01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01080&h=500&w=500&type=live",
    name: "Amor 80 chestnut",
    rgb: {
      r: 107,
      g: 74,
      b: 63,
    },
  },
  {
    article_number: "1AMOR01083",
    color: "83 caramel",
    fabric_uuid: "VADAIN-70035938-1AMOR01083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01083&h=500&w=500&type=live",
    name: "Amor 83 caramel",
    rgb: {
      r: 145,
      g: 100,
      b: 73,
    },
  },
  {
    article_number: "1AMOR01084",
    color: "84 latte",
    fabric_uuid: "VADAIN-70035908-1AMOR01084",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01084.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01084&h=500&w=500&type=live",
    name: "Amor 84 latte",
    rgb: {
      r: 150,
      g: 127,
      b: 104,
    },
  },
  {
    article_number: "1AMOR01085",
    color: "85 coffee",
    fabric_uuid: "VADAIN-70035929-1AMOR01085",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01085.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01085&h=500&w=500&type=live",
    name: "Amor 85 coffee",
    rgb: {
      r: 125,
      g: 105,
      b: 85,
    },
  },
  {
    article_number: "1AMOR01090",
    color: "90 medium grey",
    fabric_uuid: "VADAIN-70035919-1AMOR01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01090&h=500&w=500&type=live",
    name: "Amor 90 medium grey",
    rgb: {
      r: 153,
      g: 152,
      b: 151,
    },
  },
  {
    article_number: "1AMOR01091",
    color: "91 fog",
    fabric_uuid: "VADAIN-70035923-1AMOR01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01091&h=500&w=500&type=live",
    name: "Amor 91 fog",
    rgb: {
      r: 170,
      g: 167,
      b: 163,
    },
  },
  {
    article_number: "1AMOR01092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70035924-1AMOR01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01092&h=500&w=500&type=live",
    name: "Amor 92 silver",
    rgb: {
      r: 153,
      g: 152,
      b: 150,
    },
  },
  {
    article_number: "1AMOR01093",
    color: "93 dark grey",
    fabric_uuid: "VADAIN-70035911-1AMOR01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01093&h=500&w=500&type=live",
    name: "Amor 93 dark grey",
    rgb: {
      r: 117,
      g: 119,
      b: 120,
    },
  },
  {
    article_number: "1AMOR01094",
    color: "94 pewter",
    fabric_uuid: "VADAIN-70035915-1AMOR01094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01094&h=500&w=500&type=live",
    name: "Amor 94 pewter",
    rgb: {
      r: 107,
      g: 108,
      b: 109,
    },
  },
  {
    article_number: "1AMOR01096",
    color: "96 graphite",
    fabric_uuid: "VADAIN-70035936-1AMOR01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AMOR01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AMOR01096&h=500&w=500&type=live",
    name: "Amor 96 graphite",
    rgb: {
      r: 67,
      g: 69,
      b: 69,
    },
  },
  {
    article_number: "1ANEC02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70037676-1ANEC02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02010&h=500&w=500&type=live",
    name: "Anecdote 10 white",
    rgb: {
      r: 170,
      g: 170,
      b: 168,
    },
  },
  {
    article_number: "1ANEC02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70037681-1ANEC02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02011&h=500&w=500&type=live",
    name: "Anecdote 11 off white",
    rgb: {
      r: 178,
      g: 179,
      b: 174,
    },
  },
  {
    article_number: "1ANEC02016",
    color: "16 almond",
    fabric_uuid: "VADAIN-70037672-1ANEC02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02016&h=500&w=500&type=live",
    name: "Anecdote 16 almond",
    rgb: {
      r: 177,
      g: 174,
      b: 166,
    },
  },
  {
    article_number: "1ANEC02027",
    color: "27 burnt orange",
    fabric_uuid: "VADAIN-70037680-1ANEC02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02027&h=500&w=500&type=live",
    name: "Anecdote 27 burnt orange",
    rgb: {
      r: 161,
      g: 141,
      b: 134,
    },
  },
  {
    article_number: "1ANEC02044",
    color: "44 rose water",
    fabric_uuid: "VADAIN-70037674-1ANEC02044",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02044.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02044&h=500&w=500&type=live",
    name: "Anecdote 44 rose water",
    rgb: {
      r: 173,
      g: 168,
      b: 161,
    },
  },
  {
    article_number: "1ANEC02055",
    color: "55 duck egg",
    fabric_uuid: "VADAIN-70037670-1ANEC02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02055&h=500&w=500&type=live",
    name: "Anecdote 55 duck egg",
    rgb: {
      r: 151,
      g: 154,
      b: 151,
    },
  },
  {
    article_number: "1ANEC02060",
    color: "60 heather",
    fabric_uuid: "VADAIN-70037671-1ANEC02060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02060&h=500&w=500&type=live",
    name: "Anecdote 60 heather",
    rgb: {
      r: 154,
      g: 150,
      b: 144,
    },
  },
  {
    article_number: "1ANEC02082",
    color: "82 seal",
    fabric_uuid: "VADAIN-70037678-1ANEC02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02082&h=500&w=500&type=live",
    name: "Anecdote 82 seal",
    rgb: {
      r: 164,
      g: 155,
      b: 144,
    },
  },
  {
    article_number: "1ANEC02085",
    color: "85 cacao",
    fabric_uuid: "VADAIN-70037669-1ANEC02085",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02085.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02085&h=500&w=500&type=live",
    name: "Anecdote 85 cacao",
    rgb: {
      r: 151,
      g: 144,
      b: 135,
    },
  },
  {
    article_number: "1ANEC02091",
    color: "91 fog",
    fabric_uuid: "VADAIN-70037673-1ANEC02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02091&h=500&w=500&type=live",
    name: "Anecdote 91 fog",
    rgb: {
      r: 153,
      g: 151,
      b: 146,
    },
  },
  {
    article_number: "1ANEC02092",
    color: "92 light grey",
    fabric_uuid: "VADAIN-70037679-1ANEC02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02092&h=500&w=500&type=live",
    name: "Anecdote 92 light grey",
    rgb: {
      r: 168,
      g: 169,
      b: 164,
    },
  },
  {
    article_number: "1ARCAD1150",
    color: "150 cream",
    fabric_uuid: "VADAIN-70033949-1ARCAD1150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARCAD1150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARCAD1150&h=500&w=500&type=live",
    name: "Arcade 150 cream",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1ARCAD1470",
    color: "470 red dust",
    fabric_uuid: "VADAIN-70033951-1ARCAD1470",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARCAD1470.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARCAD1470&h=500&w=500&type=live",
    name: "Arcade 470 red dust",
    rgb: {
      r: 165,
      g: 121,
      b: 110,
    },
  },
  {
    article_number: "1ARCAD1500",
    color: "500 midnight",
    fabric_uuid: "VADAIN-70033952-1ARCAD1500",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARCAD1500.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARCAD1500&h=500&w=500&type=live",
    name: "Arcade 500 midnight",
    rgb: {
      r: 86,
      g: 85,
      b: 93,
    },
  },
  {
    article_number: "1ARCAD1920",
    color: "920 cool silver",
    fabric_uuid: "VADAIN-70033950-1ARCAD1920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARCAD1920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARCAD1920&h=500&w=500&type=live",
    name: "Arcade 920 cool silver",
    rgb: {
      r: 182,
      g: 187,
      b: 190,
    },
  },
  {
    article_number: "1ARTEM2110",
    color: "110 off white",
    fabric_uuid: "VADAIN-70033966-1ARTEM2110",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2110.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2110&h=500&w=500&type=live",
    name: "Artemis 110 off white",
    rgb: {
      r: 224,
      g: 224,
      b: 223,
    },
  },
  {
    article_number: "1ARTEM2150",
    color: "150 cream",
    fabric_uuid: "VADAIN-70033968-1ARTEM2150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2150&h=500&w=500&type=live",
    name: "Artemis 150 cream",
    rgb: {
      r: 222,
      g: 218,
      b: 215,
    },
  },
  {
    article_number: "1ARTEM2160",
    color: "160 oatmeal",
    fabric_uuid: "VADAIN-70033961-1ARTEM2160",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2160&h=500&w=500&type=live",
    name: "Artemis 160 oatmeal",
    rgb: {
      r: 209,
      g: 200,
      b: 187,
    },
  },
  {
    article_number: "1ARTEM2170",
    color: "170 linen",
    fabric_uuid: "VADAIN-70033964-1ARTEM2170",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2170.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2170&h=500&w=500&type=live",
    name: "Artemis 170 linen",
    rgb: {
      r: 217,
      g: 210,
      b: 198,
    },
  },
  {
    article_number: "1ARTEM2180",
    color: "180 mushroom",
    fabric_uuid: "VADAIN-70033970-1ARTEM2180",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2180.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2180&h=500&w=500&type=live",
    name: "Artemis 180 mushroom",
    rgb: {
      r: 209,
      g: 201,
      b: 191,
    },
  },
  {
    article_number: "1ARTEM2270",
    color: "270 soft terra",
    fabric_uuid: "VADAIN-70033962-1ARTEM2270",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2270.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2270&h=500&w=500&type=live",
    name: "Artemis 270 soft terra",
    rgb: {
      r: 201,
      g: 178,
      b: 156,
    },
  },
  {
    article_number: "1ARTEM2330",
    color: "330 dive",
    fabric_uuid: "VADAIN-70033967-1ARTEM2330",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2330.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2330&h=500&w=500&type=live",
    name: "Artemis 330 dive",
    rgb: {
      r: 204,
      g: 201,
      b: 185,
    },
  },
  {
    article_number: "1ARTEM2920",
    color: "920 silver",
    fabric_uuid: "VADAIN-70033969-1ARTEM2920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2920&h=500&w=500&type=live",
    name: "Artemis 920 silver",
    rgb: {
      r: 191,
      g: 190,
      b: 187,
    },
  },
  {
    article_number: "1ARTEM2930",
    color: "930 warm grey",
    fabric_uuid: "VADAIN-70033963-1ARTEM2930",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2930.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2930&h=500&w=500&type=live",
    name: "Artemis 930 warm grey",
    rgb: {
      r: 202,
      g: 198,
      b: 189,
    },
  },
  {
    article_number: "1ARTEM2940",
    color: "940 rock",
    fabric_uuid: "VADAIN-70033965-1ARTEM2940",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ARTEM2940.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ARTEM2940&h=500&w=500&type=live",
    name: "Artemis 940 rock",
    rgb: {
      r: 167,
      g: 160,
      b: 151,
    },
  },
  {
    article_number: "1ANEC02055",
    color: "55 duck egg",
    fabric_uuid: "VADAIN-30007622-1ANEC02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ANEC02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ANEC02055&h=500&w=500&type=live",
    name: "ASSAI 55",
    rgb: {
      r: 151,
      g: 154,
      b: 151,
    },
  },
  {
    article_number: "1ATTIC01012",
    color: "12 pearl",
    fabric_uuid: "VADAIN-70029542-1ATTIC01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01012&h=500&w=500&type=live",
    name: "Attic 12 pearl",
    rgb: {
      r: 189,
      g: 185,
      b: 178,
    },
  },
  {
    article_number: "1ATTIC01015",
    color: "15 sandstone",
    fabric_uuid: "VADAIN-70029546-1ATTIC01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01015&h=500&w=500&type=live",
    name: "Attic 15 sandstone",
    rgb: {
      r: 181,
      g: 164,
      b: 146,
    },
  },
  {
    article_number: "1ATTIC01020",
    color: "20 honey mustard",
    fabric_uuid: "VADAIN-70029540-1ATTIC01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01020&h=500&w=500&type=live",
    name: "Attic 20 honey mustard",
    rgb: {
      r: 177,
      g: 148,
      b: 108,
    },
  },
  {
    article_number: "1ATTIC01045",
    color: "45 blush",
    fabric_uuid: "VADAIN-70029544-1ATTIC01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01045&h=500&w=500&type=live",
    name: "Attic 45 blush",
    rgb: {
      r: 182,
      g: 151,
      b: 140,
    },
  },
  {
    article_number: "1ATTIC01080",
    color: "80 chestnut",
    fabric_uuid: "VADAIN-70029545-1ATTIC01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01080&h=500&w=500&type=live",
    name: "Attic 80 chestnut",
    rgb: {
      r: 160,
      g: 130,
      b: 112,
    },
  },
  {
    article_number: "1ATTIC01093",
    color: "93 elephant",
    fabric_uuid: "VADAIN-70029541-1ATTIC01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01093&h=500&w=500&type=live",
    name: "Attic 93 elephant",
    rgb: {
      r: 143,
      g: 135,
      b: 127,
    },
  },
  {
    article_number: "1ATTIC01096",
    color: "96 rock",
    fabric_uuid: "VADAIN-70029543-1ATTIC01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTIC01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTIC01096&h=500&w=500&type=live",
    name: "Attic 96 rock",
    rgb: {
      r: 129,
      g: 130,
      b: 129,
    },
  },
  {
    article_number: "1ATTI02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70022067-1ATTI02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTI02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTI02011&h=500&w=500&type=live",
    name: "Attitude kh 11 off white",
    rgb: {
      r: 190,
      g: 190,
      b: 187,
    },
  },
  {
    article_number: "1ATTI02016",
    color: "16 natural",
    fabric_uuid: "VADAIN-70022069-1ATTI02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTI02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTI02016&h=500&w=500&type=live",
    name: "Attitude kh 16 natural",
    rgb: {
      r: 185,
      g: 179,
      b: 170,
    },
  },
  {
    article_number: "1ATTI02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70022068-1ATTI02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ATTI02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ATTI02092&h=500&w=500&type=live",
    name: "Attitude kh 92 silver",
    rgb: {
      r: 172,
      g: 173,
      b: 172,
    },
  },
  {
    article_number: "1AVALO1140",
    color: "140 biscuit",
    fabric_uuid: "VADAIN-70033977-1AVALO1140",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1140.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1140&h=500&w=500&type=live",
    name: "Avalon 140 biscuit",
    rgb: {
      r: 217,
      g: 205,
      b: 185,
    },
  },
  {
    article_number: "1AVALO1150",
    color: "150 oatmeal",
    fabric_uuid: "VADAIN-70033981-1AVALO1150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1150&h=500&w=500&type=live",
    name: "Avalon 150 oatmeal",
    rgb: {
      r: 211,
      g: 213,
      b: 200,
    },
  },
  {
    article_number: "1AVALO1160",
    color: "160 beach",
    fabric_uuid: "VADAIN-70033992-1AVALO1160",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1160&h=500&w=500&type=live",
    name: "Avalon 160 beach",
    rgb: {
      r: 168,
      g: 157,
      b: 137,
    },
  },
  {
    article_number: "1AVALO1170",
    color: "170 almond",
    fabric_uuid: "VADAIN-70033971-1AVALO1170",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1170.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1170&h=500&w=500&type=live",
    name: "Avalon 170 almond",
    rgb: {
      r: 205,
      g: 196,
      b: 180,
    },
  },
  {
    article_number: "1AVALO1180",
    color: "180 sand",
    fabric_uuid: "VADAIN-70033984-1AVALO1180",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1180.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1180&h=500&w=500&type=live",
    name: "Avalon 180 sand",
    rgb: {
      r: 150,
      g: 137,
      b: 122,
    },
  },
  {
    article_number: "1AVALO1190",
    color: "190 greige",
    fabric_uuid: "VADAIN-70033998-1AVALO1190",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1190.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1190&h=500&w=500&type=live",
    name: "Avalon 190 greige",
    rgb: {
      r: 204,
      g: 201,
      b: 184,
    },
  },
  {
    article_number: "1AVALO1220",
    color: "220 tiger",
    fabric_uuid: "VADAIN-70033978-1AVALO1220",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1220.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1220&h=500&w=500&type=live",
    name: "Avalon 220 tiger",
    rgb: {
      r: 180,
      g: 110,
      b: 66,
    },
  },
  {
    article_number: "1AVALO1290",
    color: "290 rope",
    fabric_uuid: "VADAIN-70033982-1AVALO1290",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1290.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1290&h=500&w=500&type=live",
    name: "Avalon 290 rope",
    rgb: {
      r: 182,
      g: 157,
      b: 115,
    },
  },
  {
    article_number: "1AVALO1310",
    color: "310 moss",
    fabric_uuid: "VADAIN-70034003-1AVALO1310",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1310.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1310&h=500&w=500&type=live",
    name: "Avalon 310 moss",
    rgb: {
      r: 175,
      g: 149,
      b: 57,
    },
  },
  {
    article_number: "1AVALO1320",
    color: "320 olive",
    fabric_uuid: "VADAIN-70033973-1AVALO1320",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1320.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1320&h=500&w=500&type=live",
    name: "Avalon 320 olive",
    rgb: {
      r: 166,
      g: 152,
      b: 81,
    },
  },
  {
    article_number: "1AVALO1330",
    color: "330 pesto",
    fabric_uuid: "VADAIN-70033993-1AVALO1330",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1330.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1330&h=500&w=500&type=live",
    name: "Avalon 330 pesto",
    rgb: {
      r: 112,
      g: 108,
      b: 76,
    },
  },
  {
    article_number: "1AVALO1340",
    color: "340 khaki",
    fabric_uuid: "VADAIN-70034000-1AVALO1340",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1340.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1340&h=500&w=500&type=live",
    name: "Avalon 340 khaki",
    rgb: {
      r: 150,
      g: 144,
      b: 113,
    },
  },
  {
    article_number: "1AVALO1350",
    color: "350 sea salt",
    fabric_uuid: "VADAIN-70033979-1AVALO1350",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1350.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1350&h=500&w=500&type=live",
    name: "Avalon 350 sea salt",
    rgb: {
      r: 192,
      g: 190,
      b: 168,
    },
  },
  {
    article_number: "1AVALO1360",
    color: "360 pine tree",
    fabric_uuid: "VADAIN-70033990-1AVALO1360",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1360.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1360&h=500&w=500&type=live",
    name: "Avalon 360 pine tree",
    rgb: {
      r: 85,
      g: 106,
      b: 97,
    },
  },
  {
    article_number: "1AVALO1365",
    color: "365 sage",
    fabric_uuid: "VADAIN-70034005-1AVALO1365",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1365.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1365&h=500&w=500&type=live",
    name: "Avalon 365 sage",
    rgb: {
      r: 185,
      g: 168,
      b: 117,
    },
  },
  {
    article_number: "1AVALO1370",
    color: "370 ledar",
    fabric_uuid: "VADAIN-70033987-1AVALO1370",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1370.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1370&h=500&w=500&type=live",
    name: "Avalon 370 ledar",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1AVALO1375",
    color: "375 green metal",
    fabric_uuid: "VADAIN-70033991-1AVALO1375",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1375.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1375&h=500&w=500&type=live",
    name: "Avalon 375 green metal",
    rgb: {
      r: 98,
      g: 109,
      b: 111,
    },
  },
  {
    article_number: "1AVALO1400",
    color: "400 red",
    fabric_uuid: "VADAIN-70034004-1AVALO1400",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1400.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1400&h=500&w=500&type=live",
    name: "Avalon 400 red",
    rgb: {
      r: 174,
      g: 81,
      b: 83,
    },
  },
  {
    article_number: "1AVALO1420",
    color: "420 tomato red",
    fabric_uuid: "VADAIN-70033983-1AVALO1420",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1420.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1420&h=500&w=500&type=live",
    name: "Avalon 420 tomato red",
    rgb: {
      r: 219,
      g: 103,
      b: 83,
    },
  },
  {
    article_number: "1AVALO1425",
    color: "425 wine red",
    fabric_uuid: "VADAIN-70033994-1AVALO1425",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1425.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1425&h=500&w=500&type=live",
    name: "Avalon 425 wine red",
    rgb: {
      r: 107,
      g: 64,
      b: 74,
    },
  },
  {
    article_number: "1AVALO1450",
    color: "450 soft rose",
    fabric_uuid: "VADAIN-70033972-1AVALO1450",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1450.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1450&h=500&w=500&type=live",
    name: "Avalon 450 soft rose",
    rgb: {
      r: 184,
      g: 152,
      b: 139,
    },
  },
  {
    article_number: "1AVALO1460",
    color: "460 orchid",
    fabric_uuid: "VADAIN-70033986-1AVALO1460",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1460.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1460&h=500&w=500&type=live",
    name: "Avalon 460 orchid",
    rgb: {
      r: 191,
      g: 143,
      b: 146,
    },
  },
  {
    article_number: "1AVALO1510",
    color: "510 scandinavian blue",
    fabric_uuid: "VADAIN-70033989-1AVALO1510",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1510.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1510&h=500&w=500&type=live",
    name: "Avalon 510 scandinavian blue",
    rgb: {
      r: 151,
      g: 167,
      b: 156,
    },
  },
  {
    article_number: "1AVALO1520",
    color: "520 deep ocean",
    fabric_uuid: "VADAIN-70033975-1AVALO1520",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1520.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1520&h=500&w=500&type=live",
    name: "Avalon 520 deep ocean",
    rgb: {
      r: 109,
      g: 141,
      b: 135,
    },
  },
  {
    article_number: "1AVALO1525",
    color: "525 midnight",
    fabric_uuid: "VADAIN-70033980-1AVALO1525",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1525.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1525&h=500&w=500&type=live",
    name: "Avalon 525 midnight",
    rgb: {
      r: 82,
      g: 90,
      b: 105,
    },
  },
  {
    article_number: "1AVALO1530",
    color: "530 navy",
    fabric_uuid: "VADAIN-70034002-1AVALO1530",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1530.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1530&h=500&w=500&type=live",
    name: "Avalon 530 navy",
    rgb: {
      r: 79,
      g: 85,
      b: 103,
    },
  },
  {
    article_number: "1AVALO1590",
    color: "590 duck egg",
    fabric_uuid: "VADAIN-70033996-1AVALO1590",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1590.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1590&h=500&w=500&type=live",
    name: "Avalon 590 duck egg",
    rgb: {
      r: 173,
      g: 185,
      b: 166,
    },
  },
  {
    article_number: "1AVALO1800",
    color: "800 cacao",
    fabric_uuid: "VADAIN-70033985-1AVALO1800",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1800.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1800&h=500&w=500&type=live",
    name: "Avalon 800 cacao",
    rgb: {
      r: 105,
      g: 100,
      b: 94,
    },
  },
  {
    article_number: "1AVALO1810",
    color: "810 beaver",
    fabric_uuid: "VADAIN-70033999-1AVALO1810",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1810.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1810&h=500&w=500&type=live",
    name: "Avalon 810 beaver",
    rgb: {
      r: 156,
      g: 141,
      b: 126,
    },
  },
  {
    article_number: "1AVALO1820",
    color: "820 otter",
    fabric_uuid: "VADAIN-70033976-1AVALO1820",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1820.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1820&h=500&w=500&type=live",
    name: "Avalon 820 otter",
    rgb: {
      r: 118,
      g: 102,
      b: 89,
    },
  },
  {
    article_number: "1AVALO1900",
    color: "900 grey",
    fabric_uuid: "VADAIN-70033988-1AVALO1900",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1900.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1900&h=500&w=500&type=live",
    name: "Avalon 900  grey",
    rgb: {
      r: 155,
      g: 150,
      b: 137,
    },
  },
  {
    article_number: "1AVALO1910",
    color: "910 koala",
    fabric_uuid: "VADAIN-70033997-1AVALO1910",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1910.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1910&h=500&w=500&type=live",
    name: "Avalon 910 koala",
    rgb: {
      r: 192,
      g: 184,
      b: 176,
    },
  },
  {
    article_number: "1AVALO1930",
    color: "930 hippo",
    fabric_uuid: "VADAIN-70033974-1AVALO1930",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1930.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1930&h=500&w=500&type=live",
    name: "Avalon 930 hippe",
    rgb: {
      r: 130,
      g: 133,
      b: 121,
    },
  },
  {
    article_number: "1AVALO1940",
    color: "940 concrete",
    fabric_uuid: "VADAIN-70033995-1AVALO1940",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1940.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1940&h=500&w=500&type=live",
    name: "Avalon 940 concrete",
    rgb: {
      r: 166,
      g: 156,
      b: 145,
    },
  },
  {
    article_number: "1AVALO1950",
    color: "950 black",
    fabric_uuid: "VADAIN-70034001-1AVALO1950",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1AVALO1950.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1AVALO1950&h=500&w=500&type=live",
    name: "Avalon 950 black",
    rgb: {
      r: 75,
      g: 75,
      b: 73,
    },
  },
  {
    article_number: "1BALI01010",
    color: "10 wit",
    fabric_uuid: "VADAIN-70000038-1BALI01010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01010&h=500&w=500&type=live",
    name: "Bali 10 wit",
    rgb: {
      r: 167,
      g: 173,
      b: 186,
    },
  },
  {
    article_number: "1BALI01011",
    color: "11 ivoor",
    fabric_uuid: "VADAIN-70000039-1BALI01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01011&h=500&w=500&type=live",
    name: "Bali 11 ivoor",
    rgb: {
      r: 174,
      g: 176,
      b: 172,
    },
  },
  {
    article_number: "1BALI01015",
    color: "15 ecru",
    fabric_uuid: "VADAIN-70000040-1BALI01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01015&h=500&w=500&type=live",
    name: "Bali 15 ecru",
    rgb: {
      r: 204,
      g: 196,
      b: 180,
    },
  },
  {
    article_number: "1BALI01016",
    color: "16 biscuit",
    fabric_uuid: "VADAIN-70000041-1BALI01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01016&h=500&w=500&type=live",
    name: "Bali 16 biscuit",
    rgb: {
      r: 150,
      g: 131,
      b: 112,
    },
  },
  {
    article_number: "1BALI01017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-70000042-1BALI01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01017&h=500&w=500&type=live",
    name: "Bali 17 linnen",
    rgb: {
      r: 127,
      g: 114,
      b: 96,
    },
  },
  {
    article_number: "1BALI01018",
    color: "18 beige",
    fabric_uuid: "VADAIN-70000043-1BALI01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01018&h=500&w=500&type=live",
    name: "Bali 18 beige",
    rgb: {
      r: 160,
      g: 152,
      b: 142,
    },
  },
  {
    article_number: "1BALI01020",
    color: "20 vanille",
    fabric_uuid: "VADAIN-70000044-1BALI01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01020&h=500&w=500&type=live",
    name: "Bali 20 vanille",
    rgb: {
      r: 169,
      g: 166,
      b: 134,
    },
  },
  {
    article_number: "1BALI01022",
    color: "22 citroen",
    fabric_uuid: "VADAIN-70000045-1BALI01022",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01022.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01022&h=500&w=500&type=live",
    name: "Bali 22 citroen",
    rgb: {
      r: 168,
      g: 160,
      b: 72,
    },
  },
  {
    article_number: "1BALI01025",
    color: "25 orange",
    fabric_uuid: "VADAIN-70000046-1BALI01025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01025&h=500&w=500&type=live",
    name: "Bali 25 orange",
    rgb: {
      r: 145,
      g: 79,
      b: 44,
    },
  },
  {
    article_number: "1BALI01028",
    color: "28 terra",
    fabric_uuid: "VADAIN-70000047-1BALI01028",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01028.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01028&h=500&w=500&type=live",
    name: "Bali 28 terra",
    rgb: {
      r: 111,
      g: 38,
      b: 36,
    },
  },
  {
    article_number: "1BALI01035",
    color: "35 appel",
    fabric_uuid: "VADAIN-70000048-1BALI01035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01035&h=500&w=500&type=live",
    name: "Bali 35 appel",
    rgb: {
      r: 112,
      g: 131,
      b: 77,
    },
  },
  {
    article_number: "1BALI01040",
    color: "40 scarlet",
    fabric_uuid: "VADAIN-70000049-1BALI01040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01040&h=500&w=500&type=live",
    name: "Bali 40 scarlet",
    rgb: {
      r: 140,
      g: 16,
      b: 50,
    },
  },
  {
    article_number: "1BALI01045",
    color: "45 pink",
    fabric_uuid: "VADAIN-70000050-1BALI01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01045&h=500&w=500&type=live",
    name: "Bali 45 pink",
    rgb: {
      r: 132,
      g: 26,
      b: 88,
    },
  },
  {
    article_number: "1BALI01050",
    color: "50 royal blue",
    fabric_uuid: "VADAIN-70000051-1BALI01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01050&h=500&w=500&type=live",
    name: "Bali 50 royal blue",
    rgb: {
      r: 30,
      g: 43,
      b: 78,
    },
  },
  {
    article_number: "1BALI01052",
    color: "52 night",
    fabric_uuid: "VADAIN-70000052-1BALI01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01052&h=500&w=500&type=live",
    name: "Bali 52 night",
    rgb: {
      r: 35,
      g: 43,
      b: 61,
    },
  },
  {
    article_number: "1BALI01053",
    color: "53 denim",
    fabric_uuid: "VADAIN-70000053-1BALI01053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01053&h=500&w=500&type=live",
    name: "Bali 53 denim",
    rgb: {
      r: 75,
      g: 81,
      b: 100,
    },
  },
  {
    article_number: "1BALI01059",
    color: "59 petrol",
    fabric_uuid: "VADAIN-70000054-1BALI01059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01059&h=500&w=500&type=live",
    name: "Bali 59 petrol",
    rgb: {
      r: 16,
      g: 85,
      b: 115,
    },
  },
  {
    article_number: "1BALI01060",
    color: "60 violet",
    fabric_uuid: "VADAIN-70000055-1BALI01060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01060&h=500&w=500&type=live",
    name: "Bali 60 violet",
    rgb: {
      r: 68,
      g: 51,
      b: 96,
    },
  },
  {
    article_number: "1BALI01080",
    color: "80 chocolate",
    fabric_uuid: "VADAIN-70000056-1BALI01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01080&h=500&w=500&type=live",
    name: "Bali 80 chocolate",
    rgb: {
      r: 113,
      g: 91,
      b: 77,
    },
  },
  {
    article_number: "1BALI01082",
    color: "82 latte",
    fabric_uuid: "VADAIN-70000057-1BALI01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01082&h=500&w=500&type=live",
    name: "Bali 82 latte",
    rgb: {
      r: 108,
      g: 93,
      b: 78,
    },
  },
  {
    article_number: "1BALI01086",
    color: "86 taupe",
    fabric_uuid: "VADAIN-70000058-1BALI01086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01086&h=500&w=500&type=live",
    name: "Bali 86 taupe",
    rgb: {
      r: 92,
      g: 87,
      b: 86,
    },
  },
  {
    article_number: "1BALI01090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70000059-1BALI01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01090&h=500&w=500&type=live",
    name: "Bali 90 grey",
    rgb: {
      r: 109,
      g: 109,
      b: 111,
    },
  },
  {
    article_number: "1BALI01091",
    color: "91 dove",
    fabric_uuid: "VADAIN-70000060-1BALI01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01091&h=500&w=500&type=live",
    name: "Bali 91 dove",
    rgb: {
      r: 60,
      g: 64,
      b: 76,
    },
  },
  {
    article_number: "1BALI01092",
    color: "92 aluminium",
    fabric_uuid: "VADAIN-70000061-1BALI01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01092&h=500&w=500&type=live",
    name: "Bali 92 aluminium",
    rgb: {
      r: 109,
      g: 112,
      b: 123,
    },
  },
  {
    article_number: "1BALI01093",
    color: "93 antraciet",
    fabric_uuid: "VADAIN-70000062-1BALI01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01093&h=500&w=500&type=live",
    name: "Bali 93 antracite",
    rgb: {
      r: 63,
      g: 61,
      b: 64,
    },
  },
  {
    article_number: "1BALI01095",
    color: "95 black",
    fabric_uuid: "VADAIN-70000063-1BALI01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BALI01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BALI01095&h=500&w=500&type=live",
    name: "Bali 95 black",
    rgb: {
      r: 25,
      g: 29,
      b: 31,
    },
  },
  {
    article_number: "1BOOS01016",
    color: "16 naturel",
    fabric_uuid: "VADAIN-70018434-1BOOS01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOOS01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOOS01016&h=500&w=500&type=live",
    name: "Boost 16 natural",
    rgb: {
      r: 182,
      g: 175,
      b: 164,
    },
  },
  {
    article_number: "1BOOS01018",
    color: "18 greige",
    fabric_uuid: "VADAIN-70018435-1BOOS01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOOS01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOOS01018&h=500&w=500&type=live",
    name: "Boost 18 greige",
    rgb: {
      r: 147,
      g: 139,
      b: 129,
    },
  },
  {
    article_number: "1BOOS01052",
    color: "52 breeze",
    fabric_uuid: "VADAIN-70018436-1BOOS01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOOS01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOOS01052&h=500&w=500&type=live",
    name: "Boost 52 breeze",
    rgb: {
      r: 136,
      g: 139,
      b: 133,
    },
  },
  {
    article_number: "1BOOS01090",
    color: "90 storm",
    fabric_uuid: "VADAIN-70018437-1BOOS01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOOS01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOOS01090&h=500&w=500&type=live",
    name: "Boost 90 storm",
    rgb: {
      r: 130,
      g: 122,
      b: 115,
    },
  },
  {
    article_number: "1BOOS01096",
    color: "96 antraciet",
    fabric_uuid: "VADAIN-30001637-1BOOS01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOOS01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOOS01096&h=500&w=500&type=live",
    name: "Boost 96 antraciet",
    rgb: {
      r: 114,
      g: 111,
      b: 107,
    },
  },
  {
    article_number: "1BOTA01017B",
    color: "17 b white sand",
    fabric_uuid: "VADAIN-70022102-1BOTA01017B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01017B&h=500&w=500&type=live",
    name: "Botanic 17 b white sand",
    rgb: {
      r: 167,
      g: 161,
      b: 155,
    },
  },
  {
    article_number: "1BOTA01017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70022095-1BOTA01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01017&h=500&w=500&type=live",
    name: "Botanic 17 sand",
    rgb: {
      r: 160,
      g: 149,
      b: 135,
    },
  },
  {
    article_number: "1BOTA01037B",
    color: "37 b black green",
    fabric_uuid: "VADAIN-70022100-1BOTA01037B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01037B&h=500&w=500&type=live",
    name: "Botanic 37 b black green",
    rgb: {
      r: 80,
      g: 72,
      b: 59,
    },
  },
  {
    article_number: "1BOTA01037",
    color: "37 jungle green",
    fabric_uuid: "VADAIN-70022101-1BOTA01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01037&h=500&w=500&type=live",
    name: "Botanic 37 jungle green",
    rgb: {
      r: 106,
      g: 90,
      b: 65,
    },
  },
  {
    article_number: "1BOTA01080B",
    color: "80 b black nutmeg",
    fabric_uuid: "VADAIN-70022096-1BOTA01080B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01080B&h=500&w=500&type=live",
    name: "Botanic 80 b black nutmeg",
    rgb: {
      r: 107,
      g: 98,
      b: 91,
    },
  },
  {
    article_number: "1BOTA01080",
    color: "80 nutmeg",
    fabric_uuid: "VADAIN-70022099-1BOTA01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01080&h=500&w=500&type=live",
    name: "Botanic 80 nutmeg",
    rgb: {
      r: 131,
      g: 116,
      b: 101,
    },
  },
  {
    article_number: "1BOTA01090B",
    color: "90 b white grey",
    fabric_uuid: "VADAIN-70022098-1BOTA01090B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01090B&h=500&w=500&type=live",
    name: "Botanic 90 b white grey",
    rgb: {
      r: 164,
      g: 160,
      b: 156,
    },
  },
  {
    article_number: "1BOTA01090",
    color: "90 mid grey",
    fabric_uuid: "VADAIN-70022097-1BOTA01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BOTA01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BOTA01090&h=500&w=500&type=live",
    name: "Botanic 90 mid grey",
    rgb: {
      r: 137,
      g: 133,
      b: 130,
    },
  },
  {
    article_number: "1BRUS01015",
    color: "15 winter",
    fabric_uuid: "VADAIN-70012191-1BRUS01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01015&h=500&w=500&type=live",
    name: "Brush 15 winter",
    rgb: {
      r: 186,
      g: 178,
      b: 170,
    },
  },
  {
    article_number: "1BRUS01018",
    color: "18 almond",
    fabric_uuid: "VADAIN-70012197-1BRUS01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01018&h=500&w=500&type=live",
    name: "Brush 18 almond",
    rgb: {
      r: 167,
      g: 155,
      b: 142,
    },
  },
  {
    article_number: "1BRUS01019",
    color: "19 rock",
    fabric_uuid: "VADAIN-70012204-1BRUS01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01019&h=500&w=500&type=live",
    name: "Brush 19 rock",
    rgb: {
      r: 172,
      g: 168,
      b: 163,
    },
  },
  {
    article_number: "1BRUS01020",
    color: "20 mustard",
    fabric_uuid: "VADAIN-70012189-1BRUS01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01020&h=500&w=500&type=live",
    name: "Brush 20 mustard",
    rgb: {
      r: 180,
      g: 142,
      b: 75,
    },
  },
  {
    article_number: "1BRUS01045",
    color: "45 soft rose",
    fabric_uuid: "VADAIN-70012155-1BRUS01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01045&h=500&w=500&type=live",
    name: "Brush 45 soft rose",
    rgb: {
      r: 180,
      g: 153,
      b: 150,
    },
  },
  {
    article_number: "1BRUS01046",
    color: "46 marsala",
    fabric_uuid: "VADAIN-70012201-1BRUS01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01046&h=500&w=500&type=live",
    name: "Brush 46 marsala",
    rgb: {
      r: 128,
      g: 81,
      b: 81,
    },
  },
  {
    article_number: "1BRUS01050",
    color: "50 denim",
    fabric_uuid: "VADAIN-70012195-1BRUS01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01050&h=500&w=500&type=live",
    name: "Brush 50 denim",
    rgb: {
      r: 58,
      g: 79,
      b: 98,
    },
  },
  {
    article_number: "1BRUS01055",
    color: "55 jade",
    fabric_uuid: "VADAIN-70012199-1BRUS01055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01055&h=500&w=500&type=live",
    name: "Brush 55 jade",
    rgb: {
      r: 122,
      g: 132,
      b: 131,
    },
  },
  {
    article_number: "1BRUS01059",
    color: "59 tree top",
    fabric_uuid: "VADAIN-70012200-1BRUS01059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01059&h=500&w=500&type=live",
    name: "Brush 59 tree top",
    rgb: {
      r: 95,
      g: 107,
      b: 108,
    },
  },
  {
    article_number: "1BRUS01060",
    color: "60 sangria",
    fabric_uuid: "VADAIN-70012202-1BRUS01060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01060&h=500&w=500&type=live",
    name: "Brush 60 sangria",
    rgb: {
      r: 88,
      g: 58,
      b: 65,
    },
  },
  {
    article_number: "1BRUS01080",
    color: "80 brown",
    fabric_uuid: "VADAIN-70012193-1BRUS01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01080&h=500&w=500&type=live",
    name: "Brush 80 brown",
    rgb: {
      r: 73,
      g: 68,
      b: 64,
    },
  },
  {
    article_number: "1BRUS01081",
    color: "81 spice",
    fabric_uuid: "VADAIN-70012196-1BRUS01081",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01081.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01081&h=500&w=500&type=live",
    name: "Brush 81 spice",
    rgb: {
      r: 144,
      g: 132,
      b: 119,
    },
  },
  {
    article_number: "1BRUS01082",
    color: "82 hazel",
    fabric_uuid: "VADAIN-70012188-1BRUS01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01082&h=500&w=500&type=live",
    name: "Brush 82 hazel",
    rgb: {
      r: 117,
      g: 94,
      b: 75,
    },
  },
  {
    article_number: "1BRUS01087",
    color: "87 taupe",
    fabric_uuid: "VADAIN-70012194-1BRUS01087",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01087.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01087&h=500&w=500&type=live",
    name: "Brush 87 taupe",
    rgb: {
      r: 116,
      g: 105,
      b: 97,
    },
  },
  {
    article_number: "1BRUS01090",
    color: "90 titanium",
    fabric_uuid: "VADAIN-70012198-1BRUS01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01090&h=500&w=500&type=live",
    name: "Brush 90 titanium",
    rgb: {
      r: 110,
      g: 110,
      b: 108,
    },
  },
  {
    article_number: "1BRUS01091",
    color: "91 glacier grey",
    fabric_uuid: "VADAIN-70012190-1BRUS01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01091&h=500&w=500&type=live",
    name: "Brush 91 glacier grey",
    rgb: {
      r: 146,
      g: 141,
      b: 137,
    },
  },
  {
    article_number: "1BRUS01093",
    color: "93 melee",
    fabric_uuid: "VADAIN-70012192-1BRUS01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01093&h=500&w=500&type=live",
    name: "Brush 93 melee",
    rgb: {
      r: 131,
      g: 131,
      b: 131,
    },
  },
  {
    article_number: "1BRUS01096",
    color: "96 graphite",
    fabric_uuid: "VADAIN-70012203-1BRUS01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1BRUS01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1BRUS01096&h=500&w=500&type=live",
    name: "Brush 96 graphite",
    rgb: {
      r: 74,
      g: 76,
      b: 75,
    },
  },
  {
    article_number: "1CHARG1110",
    color: "110 off white",
    fabric_uuid: "VADAIN-70039443-1CHARG1110",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1110.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1110&h=500&w=500&type=live",
    name: "Charge 110 off white",
    rgb: {
      r: 233,
      g: 224,
      b: 221,
    },
  },
  {
    article_number: "1CHARG1111",
    color: "111 fog",
    fabric_uuid: "VADAIN-70039444-1CHARG1111",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1111.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1111&h=500&w=500&type=live",
    name: "Charge 111 fog",
    rgb: {
      r: 221,
      g: 213,
      b: 206,
    },
  },
  {
    article_number: "1CHARG1140",
    color: "140 dune",
    fabric_uuid: "VADAIN-70039445-1CHARG1140",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1140.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1140&h=500&w=500&type=live",
    name: "Charge 140 dune",
    rgb: {
      r: 216,
      g: 195,
      b: 173,
    },
  },
  {
    article_number: "1CHARG1150",
    color: "150 golden beach",
    fabric_uuid: "VADAIN-70039446-1CHARG1150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1150&h=500&w=500&type=live",
    name: "Charge 150 golden beach",
    rgb: {
      r: 209,
      g: 187,
      b: 163,
    },
  },
  {
    article_number: "1CHARG1160",
    color: "160 mushroom",
    fabric_uuid: "VADAIN-70039447-1CHARG1160",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1160&h=500&w=500&type=live",
    name: "Charge 160 mushroom",
    rgb: {
      r: 191,
      g: 167,
      b: 147,
    },
  },
  {
    article_number: "1CHARG1170",
    color: "170 nutmeg",
    fabric_uuid: "VADAIN-70039448-1CHARG1170",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1170.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1170&h=500&w=500&type=live",
    name: "Charge 170 nutmeg",
    rgb: {
      r: 166,
      g: 146,
      b: 125,
    },
  },
  {
    article_number: "1CHARG1210",
    color: "210 golden touch",
    fabric_uuid: "VADAIN-70044023-1CHARG1210",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/NOIMAGE.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1210&h=500&w=500&type=live",
    name: "Charge 210 golden touch",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1CHARG1220",
    color: "220 wheat",
    fabric_uuid: "VADAIN-70039449-1CHARG1220",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1220.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1220&h=500&w=500&type=live",
    name: "Charge 220 wheat",
    rgb: {
      r: 220,
      g: 190,
      b: 137,
    },
  },
  {
    article_number: "1CHARG1240",
    color: "240 ocherous",
    fabric_uuid: "VADAIN-70039450-1CHARG1240",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1240.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1240&h=500&w=500&type=live",
    name: "Charge 240 ocherous",
    rgb: {
      r: 170,
      g: 129,
      b: 64,
    },
  },
  {
    article_number: "1CHARG1260",
    color: "260 spice",
    fabric_uuid: "VADAIN-70039451-1CHARG1260",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1260.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1260&h=500&w=500&type=live",
    name: "Charge 260 spice",
    rgb: {
      r: 185,
      g: 118,
      b: 75,
    },
  },
  {
    article_number: "1CHARG1270",
    color: "270 brick",
    fabric_uuid: "VADAIN-70039452-1CHARG1270",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1270.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1270&h=500&w=500&type=live",
    name: "Charge 270 brick",
    rgb: {
      r: 169,
      g: 95,
      b: 73,
    },
  },
  {
    article_number: "1CHARG1280",
    color: "280 sunny",
    fabric_uuid: "VADAIN-70039453-1CHARG1280",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1280.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1280&h=500&w=500&type=live",
    name: "Charge 280 sunny",
    rgb: {
      r: 198,
      g: 105,
      b: 32,
    },
  },
  {
    article_number: "1CHARG1300",
    color: "300 moss",
    fabric_uuid: "VADAIN-70039454-1CHARG1300",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1300.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1300&h=500&w=500&type=live",
    name: "Charge 300 moss",
    rgb: {
      r: 107,
      g: 87,
      b: 54,
    },
  },
  {
    article_number: "1CHARG1310",
    color: "310 fennel",
    fabric_uuid: "VADAIN-70039455-1CHARG1310",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1310.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1310&h=500&w=500&type=live",
    name: "Charge 310 fennel",
    rgb: {
      r: 131,
      g: 125,
      b: 81,
    },
  },
  {
    article_number: "1CHARG1320",
    color: "320 cardemon",
    fabric_uuid: "VADAIN-70039456-1CHARG1320",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1320.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1320&h=500&w=500&type=live",
    name: "Charge 320 cardemon",
    rgb: {
      r: 138,
      g: 128,
      b: 104,
    },
  },
  {
    article_number: "1CHARG1330",
    color: "330 sage green",
    fabric_uuid: "VADAIN-70039457-1CHARG1330",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1330.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1330&h=500&w=500&type=live",
    name: "Charge 330 sage green",
    rgb: {
      r: 156,
      g: 149,
      b: 125,
    },
  },
  {
    article_number: "1CHARG1340",
    color: "340 bottle green",
    fabric_uuid: "VADAIN-70039458-1CHARG1340",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1340.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1340&h=500&w=500&type=live",
    name: "Charge 340 bottle green",
    rgb: {
      r: 54,
      g: 80,
      b: 74,
    },
  },
  {
    article_number: "1CHARG1350",
    color: "350 spring",
    fabric_uuid: "VADAIN-70039459-1CHARG1350",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1350.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1350&h=500&w=500&type=live",
    name: "Charge 350 spring",
    rgb: {
      r: 196,
      g: 156,
      b: 61,
    },
  },
  {
    article_number: "1CHARG1370",
    color: "370 forest",
    fabric_uuid: "VADAIN-70039460-1CHARG1370",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1370.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1370&h=500&w=500&type=live",
    name: "Charge 370 forest",
    rgb: {
      r: 80,
      g: 77,
      b: 58,
    },
  },
  {
    article_number: "1CHARG1400",
    color: "400 fire",
    fabric_uuid: "VADAIN-70039461-1CHARG1400",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1400.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1400&h=500&w=500&type=live",
    name: "Charge 400 fire",
    rgb: {
      r: 176,
      g: 68,
      b: 60,
    },
  },
  {
    article_number: "1CHARG1460",
    color: "460 cherry",
    fabric_uuid: "VADAIN-70039462-1CHARG1460",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1460.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1460&h=500&w=500&type=live",
    name: "Charge 460 cherry",
    rgb: {
      r: 173,
      g: 36,
      b: 58,
    },
  },
  {
    article_number: "1CHARG1470",
    color: "470 oxblood",
    fabric_uuid: "VADAIN-70039463-1CHARG1470",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1470.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1470&h=500&w=500&type=live",
    name: "Charge 470 oxblood",
    rgb: {
      r: 125,
      g: 44,
      b: 52,
    },
  },
  {
    article_number: "1CHARG1480",
    color: "480 bordeaux",
    fabric_uuid: "VADAIN-70039464-1CHARG1480",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1480.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1480&h=500&w=500&type=live",
    name: "Charge 480 bordeaux",
    rgb: {
      r: 92,
      g: 54,
      b: 57,
    },
  },
  {
    article_number: "1CHARG1510",
    color: "510 silver blue",
    fabric_uuid: "VADAIN-70039465-1CHARG1510",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1510.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1510&h=500&w=500&type=live",
    name: "Charge 510 silver blue",
    rgb: {
      r: 153,
      g: 153,
      b: 151,
    },
  },
  {
    article_number: "1CHARG1520",
    color: "520 storm",
    fabric_uuid: "VADAIN-70039466-1CHARG1520",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1520.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1520&h=500&w=500&type=live",
    name: "Charge 520 storm",
    rgb: {
      r: 116,
      g: 118,
      b: 120,
    },
  },
  {
    article_number: "1CHARG1530",
    color: "530 denim",
    fabric_uuid: "VADAIN-70039467-1CHARG1530",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1530.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1530&h=500&w=500&type=live",
    name: "Charge 530 denim",
    rgb: {
      r: 77,
      g: 95,
      b: 112,
    },
  },
  {
    article_number: "1CHARG1540",
    color: "540 navy",
    fabric_uuid: "VADAIN-70039468-1CHARG1540",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1540.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1540&h=500&w=500&type=live",
    name: "Charge 540 navy",
    rgb: {
      r: 51,
      g: 54,
      b: 70,
    },
  },
  {
    article_number: "1CHARG1550",
    color: "550 turquoise",
    fabric_uuid: "VADAIN-70039469-1CHARG1550",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1550.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1550&h=500&w=500&type=live",
    name: "Charge 550 turquoise",
    rgb: {
      r: 96,
      g: 127,
      b: 113,
    },
  },
  {
    article_number: "1CHARG1570",
    color: "570 petrol",
    fabric_uuid: "VADAIN-70039470-1CHARG1570",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1570.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1570&h=500&w=500&type=live",
    name: "Charge 570 petrol",
    rgb: {
      r: 50,
      g: 95,
      b: 98,
    },
  },
  {
    article_number: "1CHARG1600",
    color: "600 violet",
    fabric_uuid: "VADAIN-70039471-1CHARG1600",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1600.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1600&h=500&w=500&type=live",
    name: "Charge 600 violet",
    rgb: {
      r: 122,
      g: 81,
      b: 95,
    },
  },
  {
    article_number: "1CHARG1620",
    color: "620 lilac breeze",
    fabric_uuid: "VADAIN-70039473-1CHARG1620",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1620.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1620&h=500&w=500&type=live",
    name: "Charge 620 lilac breeze",
    rgb: {
      r: 203,
      g: 180,
      b: 172,
    },
  },
  {
    article_number: "1CHARG1830",
    color: "830 chestnut",
    fabric_uuid: "VADAIN-70039475-1CHARG1830",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1830.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1830&h=500&w=500&type=live",
    name: "Charge 830 chestnut",
    rgb: {
      r: 140,
      g: 104,
      b: 81,
    },
  },
  {
    article_number: "1CHARG1840",
    color: "840 dark copper",
    fabric_uuid: "VADAIN-70039476-1CHARG1840",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1840.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1840&h=500&w=500&type=live",
    name: "Charge 840 dark copper",
    rgb: {
      r: 99,
      g: 63,
      b: 52,
    },
  },
  {
    article_number: "1CHARG1860",
    color: "860 light taupe",
    fabric_uuid: "VADAIN-70039477-1CHARG1860",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1860.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1860&h=500&w=500&type=live",
    name: "Charge 860 light taupe",
    rgb: {
      r: 157,
      g: 137,
      b: 122,
    },
  },
  {
    article_number: "1CHARG1870",
    color: "870 wolf",
    fabric_uuid: "VADAIN-70039478-1CHARG1870",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1870.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1870&h=500&w=500&type=live",
    name: "Charge 870 wolf",
    rgb: {
      r: 123,
      g: 106,
      b: 93,
    },
  },
  {
    article_number: "1CHARG1880",
    color: "880 hazel",
    fabric_uuid: "VADAIN-70039479-1CHARG1880",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1880.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1880&h=500&w=500&type=live",
    name: "Charge 880 hazel",
    rgb: {
      r: 97,
      g: 78,
      b: 69,
    },
  },
  {
    article_number: "1CHARG1890",
    color: "890 chocolate",
    fabric_uuid: "VADAIN-70039480-1CHARG1890",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1890.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1890&h=500&w=500&type=live",
    name: "Charge 890 chocolate",
    rgb: {
      r: 81,
      g: 77,
      b: 75,
    },
  },
  {
    article_number: "1CHARG1900",
    color: "900 medium grey",
    fabric_uuid: "VADAIN-70039481-1CHARG1900",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1900.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1900&h=500&w=500&type=live",
    name: "Charge 900 medium grey",
    rgb: {
      r: 152,
      g: 145,
      b: 140,
    },
  },
  {
    article_number: "1CHARG1910",
    color: "910 warm grey",
    fabric_uuid: "VADAIN-70039482-1CHARG1910",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1910.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1910&h=500&w=500&type=live",
    name: "Charge 910 warm grey",
    rgb: {
      r: 192,
      g: 181,
      b: 169,
    },
  },
  {
    article_number: "1CHARG1930",
    color: "930 metal",
    fabric_uuid: "VADAIN-70039483-1CHARG1930",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1930.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1930&h=500&w=500&type=live",
    name: "Charge 930 metal",
    rgb: {
      r: 113,
      g: 108,
      b: 104,
    },
  },
  {
    article_number: "1CHARG1950",
    color: "950 coffee",
    fabric_uuid: "VADAIN-70039484-1CHARG1950",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHARG1950.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHARG1950&h=500&w=500&type=live",
    name: "Charge 950 coffee",
    rgb: {
      r: 53,
      g: 49,
      b: 42,
    },
  },
  {
    article_number: "1CHILL2100",
    color: "100 white",
    fabric_uuid: "VADAIN-70039413-1CHILL2100",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2100.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2100&h=500&w=500&type=live",
    name: "Chill out kh 100 white",
    rgb: {
      r: 213,
      g: 213,
      b: 213,
    },
  },
  {
    article_number: "1CHILL2110",
    color: "110 ivory",
    fabric_uuid: "VADAIN-70039414-1CHILL2110",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2110.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2110&h=500&w=500&type=live",
    name: "Chill out kh 110 ivory",
    rgb: {
      r: 206,
      g: 191,
      b: 174,
    },
  },
  {
    article_number: "1CHILL2120",
    color: "120 pearl",
    fabric_uuid: "VADAIN-70039415-1CHILL2120",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2120.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2120&h=500&w=500&type=live",
    name: "Chill out kh 120 pearl",
    rgb: {
      r: 174,
      g: 166,
      b: 156,
    },
  },
  {
    article_number: "1CHILL2130",
    color: "130 champagne",
    fabric_uuid: "VADAIN-70039416-1CHILL2130",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2130.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2130&h=500&w=500&type=live",
    name: "Chill out kh 130 champagne",
    rgb: {
      r: 184,
      g: 172,
      b: 150,
    },
  },
  {
    article_number: "1CHILL2140",
    color: "140 naturel",
    fabric_uuid: "VADAIN-70039417-1CHILL2140",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2140.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2140&h=500&w=500&type=live",
    name: "Chill out kh 140 naturel",
    rgb: {
      r: 185,
      g: 164,
      b: 143,
    },
  },
  {
    article_number: "1CHILL2150",
    color: "150 nude",
    fabric_uuid: "VADAIN-70039418-1CHILL2150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2150&h=500&w=500&type=live",
    name: "Chill out kh 150 nude",
    rgb: {
      r: 207,
      g: 181,
      b: 158,
    },
  },
  {
    article_number: "1CHILL2160",
    color: "160 sand",
    fabric_uuid: "VADAIN-70039419-1CHILL2160",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2160&h=500&w=500&type=live",
    name: "Chill out kh 160 sand",
    rgb: {
      r: 189,
      g: 171,
      b: 149,
    },
  },
  {
    article_number: "1CHILL2220",
    color: "220 gold",
    fabric_uuid: "VADAIN-70039420-1CHILL2220",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2220.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2220&h=500&w=500&type=live",
    name: "Chill out kh 220 gold",
    rgb: {
      r: 168,
      g: 147,
      b: 114,
    },
  },
  {
    article_number: "1CHILL2550",
    color: "550 ocean",
    fabric_uuid: "VADAIN-70039421-1CHILL2550",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2550.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2550&h=500&w=500&type=live",
    name: "Chill out kh 550 ocean",
    rgb: {
      r: 163,
      g: 160,
      b: 142,
    },
  },
  {
    article_number: "1CHILL2800",
    color: "800 cinnamon",
    fabric_uuid: "VADAIN-70039422-1CHILL2800",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2800.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2800&h=500&w=500&type=live",
    name: "Chill out kh 800 cinnamon",
    rgb: {
      r: 157,
      g: 132,
      b: 113,
    },
  },
  {
    article_number: "1CHILL2920",
    color: "920 silver",
    fabric_uuid: "VADAIN-70039423-1CHILL2920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2920&h=500&w=500&type=live",
    name: "Chill out kh 920 silver",
    rgb: {
      r: 193,
      g: 190,
      b: 188,
    },
  },
  {
    article_number: "1CHILL2930",
    color: "930 platinum",
    fabric_uuid: "VADAIN-70039424-1CHILL2930",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2930.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2930&h=500&w=500&type=live",
    name: "Chill out kh 930 platinum",
    rgb: {
      r: 175,
      g: 173,
      b: 172,
    },
  },
  {
    article_number: "1CHILL2950",
    color: "950 charcoal",
    fabric_uuid: "VADAIN-70039425-1CHILL2950",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CHILL2950.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CHILL2950&h=500&w=500&type=live",
    name: "Chill out kh 950 charcoal",
    rgb: {
      r: 131,
      g: 127,
      b: 123,
    },
  },
  {
    article_number: "1CIRC02030",
    color: "30 forest",
    fabric_uuid: "VADAIN-70044228-1CIRC02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CIRC02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CIRC02030&h=500&w=500&type=live",
    name: "Circle of life kh 30 forest",
    rgb: {
      r: 148,
      g: 149,
      b: 147,
    },
  },
  {
    article_number: "1CIRC02036",
    color: "36 spring",
    fabric_uuid: "VADAIN-70044229-1CIRC02036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CIRC02036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CIRC02036&h=500&w=500&type=live",
    name: "Circle of life kh 36 spring",
    rgb: {
      r: 156,
      g: 154,
      b: 151,
    },
  },
  {
    article_number: "1CIRC02050",
    color: "50 sky",
    fabric_uuid: "VADAIN-70044230-1CIRC02050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CIRC02050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CIRC02050&h=500&w=500&type=live",
    name: "Circle of life kh 50 sky",
    rgb: {
      r: 150,
      g: 148,
      b: 146,
    },
  },
  {
    article_number: "1CLOS01013",
    color: "13 beige",
    fabric_uuid: "VADAIN-2291-1CLOS01013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01013&h=500&w=500&type=live",
    name: "Close 13 beige",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1CLOS01017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-2292-1CLOS01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01017&h=500&w=500&type=live",
    name: "Close 17 linnen",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1CLOS01037",
    color: "37 storm",
    fabric_uuid: "VADAIN-2293-1CLOS01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01037&h=500&w=500&type=live",
    name: "Close 37 storm",
    rgb: {
      r: 106,
      g: 99,
      b: 90,
    },
  },
  {
    article_number: "1CLOS01080",
    color: "80 bruin",
    fabric_uuid: "VADAIN-2336-1CLOS01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01080&h=500&w=500&type=live",
    name: "Close 80 bruin",
    rgb: {
      r: 89,
      g: 83,
      b: 76,
    },
  },
  {
    article_number: "1CLOS01082",
    color: "82 mushroom",
    fabric_uuid: "VADAIN-2294-1CLOS01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01082&h=500&w=500&type=live",
    name: "Close 82 mushroom",
    rgb: {
      r: 146,
      g: 131,
      b: 119,
    },
  },
  {
    article_number: "1CLOS01092",
    color: "92 warmgrijs",
    fabric_uuid: "VADAIN-2295-1CLOS01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01092&h=500&w=500&type=live",
    name: "Close 92 warmgrijs",
    rgb: {
      r: 132,
      g: 121,
      b: 115,
    },
  },
  {
    article_number: "1CLOS01095",
    color: "95 zwart",
    fabric_uuid: "VADAIN-2337-1CLOS01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CLOS01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CLOS01095&h=500&w=500&type=live",
    name: "Close 95 zwart",
    rgb: {
      r: 59,
      g: 57,
      b: 54,
    },
  },
  {
    article_number: "1CONS01015",
    color: "15A cream",
    fabric_uuid: "VADAIN-70044231-1CONS01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01015&h=500&w=500&type=live",
    name: "Conscious 15a cream",
    rgb: {
      r: 207,
      g: 205,
      b: 195,
    },
  },
  {
    article_number: "1CONS01015B",
    color: "15B milk",
    fabric_uuid: "VADAIN-70044232-1CONS01015B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01015B&h=500&w=500&type=live",
    name: "Conscious 15b milk",
    rgb: {
      r: 210,
      g: 207,
      b: 197,
    },
  },
  {
    article_number: "1CONS01019",
    color: "19A pebble",
    fabric_uuid: "VADAIN-70044233-1CONS01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01019&h=500&w=500&type=live",
    name: "Conscious 19a pebble",
    rgb: {
      r: 190,
      g: 184,
      b: 175,
    },
  },
  {
    article_number: "1CONS01019B",
    color: "19B stone",
    fabric_uuid: "VADAIN-70044234-1CONS01019B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01019B&h=500&w=500&type=live",
    name: "Conscious 19b stone",
    rgb: {
      r: 194,
      g: 190,
      b: 183,
    },
  },
  {
    article_number: "1CONS01020",
    color: "20A ochre",
    fabric_uuid: "VADAIN-70044089-1CONS01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01020&h=500&w=500&type=live",
    name: "Conscious 20a ochre",
    rgb: {
      r: 184,
      g: 164,
      b: 135,
    },
  },
  {
    article_number: "1CONS01020B",
    color: "20B mustard",
    fabric_uuid: "VADAIN-70044235-1CONS01020B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01020B&h=500&w=500&type=live",
    name: "Conscious 20b mustard",
    rgb: {
      r: 187,
      g: 172,
      b: 150,
    },
  },
  {
    article_number: "1CONS01059",
    color: "59A egg shell",
    fabric_uuid: "VADAIN-70044236-1CONS01059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01059&h=500&w=500&type=live",
    name: "Conscious 59a egg shell",
    rgb: {
      r: 158,
      g: 167,
      b: 161,
    },
  },
  {
    article_number: "1CONS01059B",
    color: "59B duck egg",
    fabric_uuid: "VADAIN-70044237-1CONS01059B",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CONS01059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CONS01059B&h=500&w=500&type=live",
    name: "Conscious 59b duck egg",
    rgb: {
      r: 166,
      g: 172,
      b: 168,
    },
  },
  {
    article_number: "1CORE02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70044238-1CORE02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02011&h=500&w=500&type=live",
    name: "Core kh 11 off white",
    rgb: {
      r: 182,
      g: 182,
      b: 180,
    },
  },
  {
    article_number: "1CORE02016",
    color: "16 almond",
    fabric_uuid: "VADAIN-70044239-1CORE02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02016&h=500&w=500&type=live",
    name: "Core kh 16 almond",
    rgb: {
      r: 173,
      g: 166,
      b: 154,
    },
  },
  {
    article_number: "1CORE02017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70044194-1CORE02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02017&h=500&w=500&type=live",
    name: "Core kh 17 sand",
    rgb: {
      r: 156,
      g: 147,
      b: 136,
    },
  },
  {
    article_number: "1CORE02018",
    color: "18 oatmeal",
    fabric_uuid: "VADAIN-70044240-1CORE02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02018&h=500&w=500&type=live",
    name: "Core kh 18 oatmeal",
    rgb: {
      r: 167,
      g: 164,
      b: 157,
    },
  },
  {
    article_number: "1CORE02045",
    color: "45 soft pink",
    fabric_uuid: "VADAIN-70044241-1CORE02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02045&h=500&w=500&type=live",
    name: "Core kh 45 soft pink",
    rgb: {
      r: 175,
      g: 161,
      b: 156,
    },
  },
  {
    article_number: "1CORE02047",
    color: "47 sienna",
    fabric_uuid: "VADAIN-70044242-1CORE02047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02047&h=500&w=500&type=live",
    name: "Core kh 47 sienna",
    rgb: {
      r: 154,
      g: 138,
      b: 129,
    },
  },
  {
    article_number: "1CORE02052",
    color: "52 faded blue",
    fabric_uuid: "VADAIN-70044243-1CORE02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02052&h=500&w=500&type=live",
    name: "Core kh 52 faded blue",
    rgb: {
      r: 149,
      g: 158,
      b: 160,
    },
  },
  {
    article_number: "1CORE02055",
    color: "55 sky",
    fabric_uuid: "VADAIN-70045478-1CORE02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02055&h=500&w=500&type=live",
    name: "Core kh 55 sky",
    rgb: {
      r: 156,
      g: 162,
      b: 164,
    },
  },
  {
    article_number: "1CORE02056",
    color: "56 mint",
    fabric_uuid: "VADAIN-70044196-1CORE02056",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02056.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02056&h=500&w=500&type=live",
    name: "Core kh 56 mint",
    rgb: {
      r: 152,
      g: 162,
      b: 157,
    },
  },
  {
    article_number: "1CORE02060",
    color: "60 lilac",
    fabric_uuid: "VADAIN-70044090-1CORE02060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02060&h=500&w=500&type=live",
    name: "Core kh 60 lilac",
    rgb: {
      r: 165,
      g: 154,
      b: 154,
    },
  },
  {
    article_number: "1CORE02080",
    color: "80 caramel",
    fabric_uuid: "VADAIN-70044245-1CORE02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02080&h=500&w=500&type=live",
    name: "Core kh 80 caramel",
    rgb: {
      r: 159,
      g: 148,
      b: 134,
    },
  },
  {
    article_number: "1CORE02090",
    color: "90 pewter",
    fabric_uuid: "VADAIN-70044246-1CORE02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02090&h=500&w=500&type=live",
    name: "Core kh 90 pewter",
    rgb: {
      r: 131,
      g: 131,
      b: 131,
    },
  },
  {
    article_number: "1CORE02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70044247-1CORE02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02092&h=500&w=500&type=live",
    name: "Core kh 92 silver",
    rgb: {
      r: 155,
      g: 155,
      b: 153,
    },
  },
  {
    article_number: "1CORE02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70044248-1CORE02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CORE02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CORE02095&h=500&w=500&type=live",
    name: "Core kh 95 black",
    rgb: {
      r: 128,
      g: 129,
      b: 126,
    },
  },
  {
    article_number: "1COSM01011",
    color: "11 ash white",
    fabric_uuid: "VADAIN-70022126-1COSM01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01011&h=500&w=500&type=live",
    name: "Cosmic 11 ash white",
    rgb: {
      r: 168,
      g: 164,
      b: 157,
    },
  },
  {
    article_number: "1COSM01012",
    color: "12 greige",
    fabric_uuid: "VADAIN-70022137-1COSM01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01012&h=500&w=500&type=live",
    name: "Cosmic 12 greige",
    rgb: {
      r: 176,
      g: 176,
      b: 172,
    },
  },
  {
    article_number: "1COSM01015",
    color: "15 dark linen",
    fabric_uuid: "VADAIN-70022142-1COSM01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01015&h=500&w=500&type=live",
    name: "Cosmic 15 dark linen",
    rgb: {
      r: 157,
      g: 152,
      b: 146,
    },
  },
  {
    article_number: "1COSM01016",
    color: "16 shoreline",
    fabric_uuid: "VADAIN-70022130-1COSM01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01016&h=500&w=500&type=live",
    name: "Cosmic 16 shoreline",
    rgb: {
      r: 164,
      g: 159,
      b: 153,
    },
  },
  {
    article_number: "1COSM01018",
    color: "18 linen",
    fabric_uuid: "VADAIN-70022134-1COSM01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01018&h=500&w=500&type=live",
    name: "Cosmic 18 linen",
    rgb: {
      r: 164,
      g: 152,
      b: 137,
    },
  },
  {
    article_number: "1COSM01019",
    color: "19 tortilla",
    fabric_uuid: "VADAIN-70022129-1COSM01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01019&h=500&w=500&type=live",
    name: "Cosmic 19 tortilla",
    rgb: {
      r: 164,
      g: 148,
      b: 129,
    },
  },
  {
    article_number: "1COSM01024",
    color: "24 sunrise",
    fabric_uuid: "VADAIN-70022138-1COSM01024",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01024.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01024&h=500&w=500&type=live",
    name: "Cosmic 24 sunrise",
    rgb: {
      r: 182,
      g: 158,
      b: 107,
    },
  },
  {
    article_number: "1COSM01025",
    color: "25 marigold",
    fabric_uuid: "VADAIN-70022121-1COSM01025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01025&h=500&w=500&type=live",
    name: "Cosmic 25 marigold",
    rgb: {
      r: 161,
      g: 131,
      b: 91,
    },
  },
  {
    article_number: "1COSM01032",
    color: "32 grey green",
    fabric_uuid: "VADAIN-70022132-1COSM01032",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01032.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01032&h=500&w=500&type=live",
    name: "Cosmic 32 grey green",
    rgb: {
      r: 126,
      g: 132,
      b: 131,
    },
  },
  {
    article_number: "1COSM01035",
    color: "35 minthy green",
    fabric_uuid: "VADAIN-70022143-1COSM01035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01035&h=500&w=500&type=live",
    name: "Cosmic 35 minty green",
    rgb: {
      r: 149,
      g: 172,
      b: 173,
    },
  },
  {
    article_number: "1COSM01036",
    color: "36 olive",
    fabric_uuid: "VADAIN-70022124-1COSM01036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01036&h=500&w=500&type=live",
    name: "Cosmic 36 olive",
    rgb: {
      r: 117,
      g: 132,
      b: 109,
    },
  },
  {
    article_number: "1COSM01037",
    color: "37 sea green",
    fabric_uuid: "VADAIN-70022128-1COSM01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01037&h=500&w=500&type=live",
    name: "Cosmic 37 sea green",
    rgb: {
      r: 114,
      g: 143,
      b: 148,
    },
  },
  {
    article_number: "1COSM01039",
    color: "39 dark green",
    fabric_uuid: "VADAIN-70022146-1COSM01039",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01039.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01039&h=500&w=500&type=live",
    name: "Cosmic 39 dark green",
    rgb: {
      r: 77,
      g: 104,
      b: 105,
    },
  },
  {
    article_number: "1COSM01042",
    color: "42 redwood",
    fabric_uuid: "VADAIN-70022122-1COSM01042",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01042.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01042&h=500&w=500&type=live",
    name: "Cosmic 42 redwood",
    rgb: {
      r: 142,
      g: 102,
      b: 111,
    },
  },
  {
    article_number: "1COSM01044",
    color: "44 indian red",
    fabric_uuid: "VADAIN-70022139-1COSM01044",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01044.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01044&h=500&w=500&type=live",
    name: "Cosmic 44 indian red",
    rgb: {
      r: 150,
      g: 99,
      b: 98,
    },
  },
  {
    article_number: "1COSM01045",
    color: "45 pastel pink",
    fabric_uuid: "VADAIN-70022144-1COSM01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01045&h=500&w=500&type=live",
    name: "Cosmic 45 pastel pink",
    rgb: {
      r: 167,
      g: 146,
      b: 143,
    },
  },
  {
    article_number: "1COSM01050",
    color: "50 sky blue",
    fabric_uuid: "VADAIN-70022125-1COSM01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01050&h=500&w=500&type=live",
    name: "Cosmic 50 sky blue",
    rgb: {
      r: 144,
      g: 165,
      b: 180,
    },
  },
  {
    article_number: "1COSM01052",
    color: "52 night",
    fabric_uuid: "VADAIN-70022135-1COSM01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01052&h=500&w=500&type=live",
    name: "Cosmic 52 night",
    rgb: {
      r: 107,
      g: 117,
      b: 126,
    },
  },
  {
    article_number: "1COSM01080",
    color: "80 chocolate",
    fabric_uuid: "VADAIN-70022147-1COSM01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01080&h=500&w=500&type=live",
    name: "Cosmic 80 chocolate",
    rgb: {
      r: 102,
      g: 100,
      b: 105,
    },
  },
  {
    article_number: "1COSM01082",
    color: "82 terracotta",
    fabric_uuid: "VADAIN-70022123-1COSM01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01082&h=500&w=500&type=live",
    name: "Cosmic 82 terracotta",
    rgb: {
      r: 166,
      g: 140,
      b: 128,
    },
  },
  {
    article_number: "1COSM01084",
    color: "84 brick",
    fabric_uuid: "VADAIN-70022140-1COSM01084",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01084.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01084&h=500&w=500&type=live",
    name: "Cosmic 84 brick",
    rgb: {
      r: 157,
      g: 121,
      b: 112,
    },
  },
  {
    article_number: "1COSM01085",
    color: "85 jerboa",
    fabric_uuid: "VADAIN-70022145-1COSM01085",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01085.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01085&h=500&w=500&type=live",
    name: "Cosmic 85 jerboa",
    rgb: {
      r: 151,
      g: 141,
      b: 135,
    },
  },
  {
    article_number: "1COSM01086",
    color: "86 bear",
    fabric_uuid: "VADAIN-70022127-1COSM01086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01086&h=500&w=500&type=live",
    name: "Cosmic 86 bear",
    rgb: {
      r: 116,
      g: 110,
      b: 107,
    },
  },
  {
    article_number: "1COSM01091",
    color: "91 dapple grey",
    fabric_uuid: "VADAIN-70022136-1COSM01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01091&h=500&w=500&type=live",
    name: "Cosmic 91 dapple grey",
    rgb: {
      r: 146,
      g: 145,
      b: 143,
    },
  },
  {
    article_number: "1COSM01092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70022141-1COSM01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01092&h=500&w=500&type=live",
    name: "Cosmic 92 silver",
    rgb: {
      r: 169,
      g: 173,
      b: 175,
    },
  },
  {
    article_number: "1COSM01093",
    color: "93 concrete",
    fabric_uuid: "VADAIN-70022131-1COSM01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01093&h=500&w=500&type=live",
    name: "Cosmic 93 concrete",
    rgb: {
      r: 131,
      g: 135,
      b: 138,
    },
  },
  {
    article_number: "1COSM01096",
    color: "96 koala",
    fabric_uuid: "VADAIN-70022133-1COSM01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COSM01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COSM01096&h=500&w=500&type=live",
    name: "Cosmic 96 koala",
    rgb: {
      r: 97,
      g: 100,
      b: 104,
    },
  },
  {
    article_number: "1COVE02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70043804-1COVE02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COVE02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COVE02010&h=500&w=500&type=live",
    name: "Cover kh 10 white",
    rgb: {
      r: 211,
      g: 215,
      b: 218,
    },
  },
  {
    article_number: "1COVE02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70043805-1COVE02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COVE02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COVE02015&h=500&w=500&type=live",
    name: "Cover kh 15 cream",
    rgb: {
      r: 215,
      g: 213,
      b: 200,
    },
  },
  {
    article_number: "1COVE02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70043806-1COVE02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COVE02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COVE02090&h=500&w=500&type=live",
    name: "Cover kh 90 grey",
    rgb: {
      r: 192,
      g: 185,
      b: 177,
    },
  },
  {
    article_number: "1COVE02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70043807-1COVE02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1COVE02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1COVE02095&h=500&w=500&type=live",
    name: "Cover kh 95 black",
    rgb: {
      r: 65,
      g: 65,
      b: 67,
    },
  },
  {
    article_number: "1CRIS02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70022061-1CRIS02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02010&h=500&w=500&type=live",
    name: "Criss Cross kh 10 white",
    rgb: {
      r: 163,
      g: 166,
      b: 167,
    },
  },
  {
    article_number: "1CRIS02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70022058-1CRIS02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02011&h=500&w=500&type=live",
    name: "Criss Cross kh 11 cotton",
    rgb: {
      r: 167,
      g: 168,
      b: 165,
    },
  },
  {
    article_number: "1CRIS02012",
    color: "12 canvas",
    fabric_uuid: "VADAIN-70022066-1CRIS02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02012&h=500&w=500&type=live",
    name: "Criss Cross kh 12 canvas",
    rgb: {
      r: 161,
      g: 161,
      b: 153,
    },
  },
  {
    article_number: "1CRIS02015",
    color: "15 sand",
    fabric_uuid: "VADAIN-70022059-1CRIS02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02015&h=500&w=500&type=live",
    name: "Criss Cross kh 15 sand",
    rgb: {
      r: 161,
      g: 155,
      b: 144,
    },
  },
  {
    article_number: "1CRIS02016",
    color: "16 mushroom",
    fabric_uuid: "VADAIN-70022063-1CRIS02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02016&h=500&w=500&type=live",
    name: "Criss Cross kh 16 mushroom",
    rgb: {
      r: 163,
      g: 158,
      b: 152,
    },
  },
  {
    article_number: "1CRIS02017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70022064-1CRIS02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02017&h=500&w=500&type=live",
    name: "Criss Cross kh 17 linen",
    rgb: {
      r: 159,
      g: 154,
      b: 146,
    },
  },
  {
    article_number: "1CRIS02027",
    color: "27 copper",
    fabric_uuid: "VADAIN-70022060-1CRIS02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02027&h=500&w=500&type=live",
    name: "Criss Cross kh 27 copper",
    rgb: {
      r: 161,
      g: 145,
      b: 129,
    },
  },
  {
    article_number: "1CRIS02045",
    color: "45 blush",
    fabric_uuid: "VADAIN-70022057-1CRIS02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02045&h=500&w=500&type=live",
    name: "Criss Cross kh 45 blush",
    rgb: {
      r: 170,
      g: 163,
      b: 155,
    },
  },
  {
    article_number: "1CRIS02052",
    color: "52 denim",
    fabric_uuid: "VADAIN-70021867-1CRIS02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02052&h=500&w=500&type=live",
    name: "Criss Cross kh 52 Denim",
    rgb: {
      r: 131,
      g: 136,
      b: 137,
    },
  },
  {
    article_number: "1CRIS02080",
    color: "80 otter",
    fabric_uuid: "VADAIN-70022062-1CRIS02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02080&h=500&w=500&type=live",
    name: "Criss Cross kh 80 otter",
    rgb: {
      r: 151,
      g: 146,
      b: 139,
    },
  },
  {
    article_number: "1CRIS02090",
    color: "90 ash",
    fabric_uuid: "VADAIN-70022056-1CRIS02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02090&h=500&w=500&type=live",
    name: "Criss Cross kh 90 ash",
    rgb: {
      r: 148,
      g: 148,
      b: 143,
    },
  },
  {
    article_number: "1CRIS02092",
    color: "92 silver sky",
    fabric_uuid: "VADAIN-70022065-1CRIS02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02092&h=500&w=500&type=live",
    name: "Criss Cross kh 92 silver sky",
    rgb: {
      r: 160,
      g: 161,
      b: 156,
    },
  },
  {
    article_number: "1CRIS02095",
    color: "95 brush",
    fabric_uuid: "VADAIN-70022055-1CRIS02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CRIS02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CRIS02095&h=500&w=500&type=live",
    name: "Criss Cross kh 95 brush",
    rgb: {
      r: 139,
      g: 138,
      b: 133,
    },
  },
  {
    article_number: "1CULT01011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70022106-1CULT01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01011&h=500&w=500&type=live",
    name: "Culture 11 off white",
    rgb: {
      r: 206,
      g: 204,
      b: 197,
    },
  },
  {
    article_number: "1CULT01012",
    color: "12 golden sand",
    fabric_uuid: "VADAIN-70022108-1CULT01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01012&h=500&w=500&type=live",
    name: "Culture 12 golden sand",
    rgb: {
      r: 184,
      g: 167,
      b: 144,
    },
  },
  {
    article_number: "1CULT01018",
    color: "18 pebble",
    fabric_uuid: "VADAIN-70022103-1CULT01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01018&h=500&w=500&type=live",
    name: "Culture 18 pebble",
    rgb: {
      r: 192,
      g: 190,
      b: 180,
    },
  },
  {
    article_number: "1CULT01037",
    color: "37 sea green",
    fabric_uuid: "VADAIN-70022107-1CULT01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01037&h=500&w=500&type=live",
    name: "Culture 37 sea green",
    rgb: {
      r: 154,
      g: 163,
      b: 164,
    },
  },
  {
    article_number: "1CULT01041",
    color: "41 brick",
    fabric_uuid: "VADAIN-70022109-1CULT01041",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01041.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01041&h=500&w=500&type=live",
    name: "Culture 41 brick",
    rgb: {
      r: 154,
      g: 131,
      b: 125,
    },
  },
  {
    article_number: "1CULT01092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70022104-1CULT01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01092&h=500&w=500&type=live",
    name: "Culture 92 silver",
    rgb: {
      r: 172,
      g: 174,
      b: 173,
    },
  },
  {
    article_number: "1CULT01095",
    color: "95 black and white",
    fabric_uuid: "VADAIN-70022105-1CULT01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01095&h=500&w=500&type=live",
    name: "Culture 95 black and white",
    rgb: {
      r: 133,
      g: 135,
      b: 138,
    },
  },
  {
    article_number: "1CULT01096",
    color: "96 rock",
    fabric_uuid: "VADAIN-70022110-1CULT01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1CULT01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1CULT01096&h=500&w=500&type=live",
    name: "Culture 96 rock",
    rgb: {
      r: 114,
      g: 110,
      b: 107,
    },
  },
  {
    article_number: "1DAWN02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70018491-1DAWN02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02011&h=500&w=500&type=live",
    name: "Dawn kh 11 off white",
    rgb: {
      r: 189,
      g: 189,
      b: 186,
    },
  },
  {
    article_number: "1DAWN02017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-70018492-1DAWN02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02017&h=500&w=500&type=live",
    name: "Dawn kh 17 linnen",
    rgb: {
      r: 151,
      g: 145,
      b: 130,
    },
  },
  {
    article_number: "1DAWN02018",
    color: "18 mushroom",
    fabric_uuid: "VADAIN-70018493-1DAWN02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02018&h=500&w=500&type=live",
    name: "Dawn kh 18 mushroom",
    rgb: {
      r: 147,
      g: 140,
      b: 130,
    },
  },
  {
    article_number: "1DAWN02019",
    color: "19 pebble",
    fabric_uuid: "VADAIN-70018494-1DAWN02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02019&h=500&w=500&type=live",
    name: "Dawn kh 19 pebble",
    rgb: {
      r: 152,
      g: 149,
      b: 141,
    },
  },
  {
    article_number: "1DAWN02030",
    color: "30 dusty mint",
    fabric_uuid: "VADAIN-70018495-1DAWN02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02030&h=500&w=500&type=live",
    name: "Dawn kh 30 dusty mint",
    rgb: {
      r: 147,
      g: 149,
      b: 146,
    },
  },
  {
    article_number: "1DAWN02045",
    color: "45 dusty pink",
    fabric_uuid: "VADAIN-70018496-1DAWN02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02045&h=500&w=500&type=live",
    name: "Dawn kh 45 dusty pink",
    rgb: {
      r: 171,
      g: 154,
      b: 143,
    },
  },
  {
    article_number: "1DAWN02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70018497-1DAWN02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02090&h=500&w=500&type=live",
    name: "Dawn kh 90 grey",
    rgb: {
      r: 133,
      g: 132,
      b: 130,
    },
  },
  {
    article_number: "1DAWN02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70018498-1DAWN02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02092&h=500&w=500&type=live",
    name: "Dawn kh 92 silver",
    rgb: {
      r: 161,
      g: 160,
      b: 158,
    },
  },
  {
    article_number: "1DAWN02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70018490-1DAWN02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAWN02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAWN02010&h=500&w=500&type=live",
    name: "Dawn kh10 white",
    rgb: {
      r: 198,
      g: 197,
      b: 201,
    },
  },
  {
    article_number: "1DAYD02030",
    color: "30 green",
    fabric_uuid: "VADAIN-70037748-1DAYD02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAYD02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAYD02030&h=500&w=500&type=live",
    name: "Day dream kh 30 green",
    rgb: {
      r: 192,
      g: 191,
      b: 180,
    },
  },
  {
    article_number: "1DAYD02049",
    color: "49 indian summer",
    fabric_uuid: "VADAIN-70037747-1DAYD02049",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DAYD02049.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DAYD02049&h=500&w=500&type=live",
    name: "Day dream kh 49 indian summer",
    rgb: {
      r: 207,
      g: 198,
      b: 191,
    },
  },
  {
    article_number: "1DISC02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70044249-1DISC02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DISC02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DISC02011&h=500&w=500&type=live",
    name: "Discover kh 11 off white",
    rgb: {
      r: 169,
      g: 170,
      b: 165,
    },
  },
  {
    article_number: "1DISC02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70044250-1DISC02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DISC02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DISC02015&h=500&w=500&type=live",
    name: "Discover kh 15 cream",
    rgb: {
      r: 168,
      g: 169,
      b: 163,
    },
  },
  {
    article_number: "1DISC02017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70044251-1DISC02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DISC02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DISC02017&h=500&w=500&type=live",
    name: "Discover kh 17 sand",
    rgb: {
      r: 165,
      g: 159,
      b: 148,
    },
  },
  {
    article_number: "1DISC02037",
    color: "37 sea foam",
    fabric_uuid: "VADAIN-70044252-1DISC02037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DISC02037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DISC02037&h=500&w=500&type=live",
    name: "Discover kh 37 sea foam",
    rgb: {
      r: 147,
      g: 149,
      b: 146,
    },
  },
  {
    article_number: "1DISC02090",
    color: "90 medium grey",
    fabric_uuid: "VADAIN-70044253-1DISC02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DISC02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DISC02090&h=500&w=500&type=live",
    name: "Discover kh 90 medium grey",
    rgb: {
      r: 137,
      g: 137,
      b: 135,
    },
  },
  {
    article_number: "1DISC02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70044254-1DISC02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DISC02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DISC02092&h=500&w=500&type=live",
    name: "Discover kh 92 silver",
    rgb: {
      r: 151,
      g: 153,
      b: 152,
    },
  },
  {
    article_number: "1DITO02010",
    color: "10 wit",
    fabric_uuid: "VADAIN-70001701-1DITO02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02010&h=500&w=500&type=live",
    name: "Dito kh fr 10 wit",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DITO02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70001703-1DITO02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02011&h=500&w=500&type=live",
    name: "Dito kh fr 11 off white",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DITO02016",
    color: "16 mushroom",
    fabric_uuid: "VADAIN-70001830-1DITO02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02016&h=500&w=500&type=live",
    name: "Dito kh fr 16 mushroom",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DITO02017",
    color: "17 zand",
    fabric_uuid: "VADAIN-70001704-1DITO02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02017&h=500&w=500&type=live",
    name: "Dito kh fr 17 zand",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DITO02082",
    color: "82 taupe",
    fabric_uuid: "VADAIN-70001705-1DITO02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02082&h=500&w=500&type=live",
    name: "Dito kh fr 82 taupe",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DITO02090",
    color: "90 grijs",
    fabric_uuid: "VADAIN-70001706-1DITO02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02090&h=500&w=500&type=live",
    name: "Dito kh fr 90 grijs",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DITO02092",
    color: "92 sky",
    fabric_uuid: "VADAIN-70001707-1DITO02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DITO02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DITO02092&h=500&w=500&type=live",
    name: "Dito kh fr 92 sky",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1DOODL02015",
    color: "15 sand",
    fabric_uuid: "VADAIN-70029836-1DOODL02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DOODL02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DOODL02015&h=500&w=500&type=live",
    name: "Doodle kh 15 sand",
    rgb: {
      r: 204,
      g: 204,
      b: 200,
    },
  },
  {
    article_number: "1DOODL02080",
    color: "80 cinnamon",
    fabric_uuid: "VADAIN-70029833-1DOODL02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DOODL02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DOODL02080&h=500&w=500&type=live",
    name: "Doodle kh 80 cinnamon",
    rgb: {
      r: 205,
      g: 205,
      b: 201,
    },
  },
  {
    article_number: "1DOODL02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70029834-1DOODL02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DOODL02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DOODL02090&h=500&w=500&type=live",
    name: "Doodle kh 90 grey",
    rgb: {
      r: 200,
      g: 200,
      b: 198,
    },
  },
  {
    article_number: "1DOODL02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70029835-1DOODL02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1DOODL02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1DOODL02095&h=500&w=500&type=live",
    name: "Doodle kh 95 black",
    rgb: {
      r: 202,
      g: 203,
      b: 198,
    },
  },
  {
    article_number: "1EART01012",
    color: "12 ecru",
    fabric_uuid: "VADAIN-70022080-1EART01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01012&h=500&w=500&type=live",
    name: "Earth 12 ecru",
    rgb: {
      r: 206,
      g: 201,
      b: 193,
    },
  },
  {
    article_number: "1EART01019",
    color: "19 hazel",
    fabric_uuid: "VADAIN-70022074-1EART01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01019&h=500&w=500&type=live",
    name: "Earth 19 hazel",
    rgb: {
      r: 185,
      g: 168,
      b: 157,
    },
  },
  {
    article_number: "1EART01038",
    color: "38 forest",
    fabric_uuid: "VADAIN-70022079-1EART01038",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01038.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01038&h=500&w=500&type=live",
    name: "Earth 38 forest",
    rgb: {
      r: 79,
      g: 90,
      b: 89,
    },
  },
  {
    article_number: "1EART01044",
    color: "44 sangria",
    fabric_uuid: "VADAIN-70022081-1EART01044",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01044.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01044&h=500&w=500&type=live",
    name: "Earth 44 sangria",
    rgb: {
      r: 108,
      g: 78,
      b: 77,
    },
  },
  {
    article_number: "1EART01046",
    color: "46 grey pink",
    fabric_uuid: "VADAIN-70022076-1EART01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01046&h=500&w=500&type=live",
    name: "Earth 46 grey pink",
    rgb: {
      r: 122,
      g: 103,
      b: 100,
    },
  },
  {
    article_number: "1EART01052",
    color: "52 night",
    fabric_uuid: "VADAIN-70022077-1EART01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01052&h=500&w=500&type=live",
    name: "Earth 52 night",
    rgb: {
      r: 79,
      g: 81,
      b: 86,
    },
  },
  {
    article_number: "1EART01083",
    color: "83 caramel",
    fabric_uuid: "VADAIN-70022082-1EART01083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01083&h=500&w=500&type=live",
    name: "Earth 83 caramel",
    rgb: {
      r: 105,
      g: 93,
      b: 82,
    },
  },
  {
    article_number: "1EART01094",
    color: "94 cement",
    fabric_uuid: "VADAIN-70022075-1EART01094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01094&h=500&w=500&type=live",
    name: "Earth 94 cement",
    rgb: {
      r: 157,
      g: 153,
      b: 148,
    },
  },
  {
    article_number: "1EART01095",
    color: "95 black",
    fabric_uuid: "VADAIN-70022078-1EART01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01095&h=500&w=500&type=live",
    name: "Earth 95 black",
    rgb: {
      r: 105,
      g: 105,
      b: 103,
    },
  },
  {
    article_number: "1EART01097",
    color: "97 granite",
    fabric_uuid: "VADAIN-70022073-1EART01097",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EART01097.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EART01097&h=500&w=500&type=live",
    name: "Earth 97 granite",
    rgb: {
      r: 116,
      g: 116,
      b: 116,
    },
  },
  {
    article_number: "1EMOT02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70037756-1EMOT02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02010&h=500&w=500&type=live",
    name: "Emotion kh 10 white",
    rgb: {
      r: 195,
      g: 194,
      b: 189,
    },
  },
  {
    article_number: "1EMOT02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70037760-1EMOT02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02011&h=500&w=500&type=live",
    name: "Emotion kh 11 off white",
    rgb: {
      r: 186,
      g: 185,
      b: 179,
    },
  },
  {
    article_number: "1EMOT02012",
    color: "12 fog",
    fabric_uuid: "VADAIN-70037752-1EMOT02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02012&h=500&w=500&type=live",
    name: "Emotion kh 12 fog",
    rgb: {
      r: 180,
      g: 181,
      b: 177,
    },
  },
  {
    article_number: "1EMOT02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70037758-1EMOT02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02015&h=500&w=500&type=live",
    name: "Emotion kh 15 cream",
    rgb: {
      r: 188,
      g: 182,
      b: 166,
    },
  },
  {
    article_number: "1EMOT02016",
    color: "16 biscuit",
    fabric_uuid: "VADAIN-70037759-1EMOT02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02016&h=500&w=500&type=live",
    name: "Emotion kh 16 biscuit",
    rgb: {
      r: 179,
      g: 166,
      b: 147,
    },
  },
  {
    article_number: "1EMOT02017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70037754-1EMOT02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02017&h=500&w=500&type=live",
    name: "Emotion kh 17 linen",
    rgb: {
      r: 164,
      g: 154,
      b: 141,
    },
  },
  {
    article_number: "1EMOT02018",
    color: "18 pebble",
    fabric_uuid: "VADAIN-70037755-1EMOT02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02018&h=500&w=500&type=live",
    name: "Emotion kh 18 pebble",
    rgb: {
      r: 187,
      g: 183,
      b: 170,
    },
  },
  {
    article_number: "1EMOT02055",
    color: "55 sky",
    fabric_uuid: "VADAIN-70037750-1EMOT02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02055&h=500&w=500&type=live",
    name: "Emotion kh 55 sky",
    rgb: {
      r: 174,
      g: 174,
      b: 172,
    },
  },
  {
    article_number: "1EMOT02082",
    color: "82 nutmeg",
    fabric_uuid: "VADAIN-70037751-1EMOT02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02082&h=500&w=500&type=live",
    name: "Emotion kh 82 nutmeg",
    rgb: {
      r: 151,
      g: 139,
      b: 124,
    },
  },
  {
    article_number: "1EMOT02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70037757-1EMOT02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02090&h=500&w=500&type=live",
    name: "Emotion kh 90 grey",
    rgb: {
      r: 153,
      g: 153,
      b: 151,
    },
  },
  {
    article_number: "1EMOT02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70037749-1EMOT02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02092&h=500&w=500&type=live",
    name: "Emotion kh 92 silver",
    rgb: {
      r: 169,
      g: 167,
      b: 163,
    },
  },
  {
    article_number: "1EMOT02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70037753-1EMOT02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EMOT02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EMOT02095&h=500&w=500&type=live",
    name: "Emotion kh 95 black",
    rgb: {
      r: 123,
      g: 122,
      b: 119,
    },
  },
  {
    article_number: "1ENSO001011",
    color: "11 cream off white",
    fabric_uuid: "VADAIN-70029547-1ENSO001011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001011&h=500&w=500&type=live",
    name: "Enso 11 cream off white",
    rgb: {
      r: 196,
      g: 186,
      b: 170,
    },
  },
  {
    article_number: "1ENSO001018",
    color: "18 natural cinnamon",
    fabric_uuid: "VADAIN-70029553-1ENSO001018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001018&h=500&w=500&type=live",
    name: "Enso 18 natural cinnamon",
    rgb: {
      r: 180,
      g: 164,
      b: 150,
    },
  },
  {
    article_number: "1ENSO001020",
    color: "20 sand honey",
    fabric_uuid: "VADAIN-70029548-1ENSO001020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001020&h=500&w=500&type=live",
    name: "Enso 20 sand honey",
    rgb: {
      r: 183,
      g: 166,
      b: 143,
    },
  },
  {
    article_number: "1ENSO001037",
    color: "37 forest green",
    fabric_uuid: "VADAIN-70029549-1ENSO001037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001037&h=500&w=500&type=live",
    name: "Enso 37 forest green",
    rgb: {
      r: 166,
      g: 160,
      b: 147,
    },
  },
  {
    article_number: "1ENSO001082",
    color: "82 linnen nutmeg",
    fabric_uuid: "VADAIN-70029550-1ENSO001082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001082&h=500&w=500&type=live",
    name: "Enso 82 linnen nutmeg",
    rgb: {
      r: 181,
      g: 166,
      b: 148,
    },
  },
  {
    article_number: "1ENSO001090",
    color: "90 cloud grey",
    fabric_uuid: "VADAIN-70029551-1ENSO001090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001090&h=500&w=500&type=live",
    name: "Enso 90 cloud grey",
    rgb: {
      r: 174,
      g: 167,
      b: 157,
    },
  },
  {
    article_number: "1ENSO001095",
    color: "95 canvas black",
    fabric_uuid: "VADAIN-70029552-1ENSO001095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ENSO001095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ENSO001095&h=500&w=500&type=live",
    name: "Enso 95 canvas black",
    rgb: {
      r: 162,
      g: 156,
      b: 147,
    },
  },
  {
    article_number: "1EOS002110",
    color: "110 off white",
    fabric_uuid: "VADAIN-70034006-1EOS002110",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002110.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002110&h=500&w=500&type=live",
    name: "Eos 110 off white",
    rgb: {
      r: 221,
      g: 220,
      b: 215,
    },
  },
  {
    article_number: "1EOS002150",
    color: "150 pebble",
    fabric_uuid: "VADAIN-70034018-1EOS002150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002150&h=500&w=500&type=live",
    name: "Eos 150 pebble",
    rgb: {
      r: 195,
      g: 188,
      b: 180,
    },
  },
  {
    article_number: "1EOS002160",
    color: "160 beach lover",
    fabric_uuid: "VADAIN-70034017-1EOS002160",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002160&h=500&w=500&type=live",
    name: "Eos 160 beach lover",
    rgb: {
      r: 190,
      g: 181,
      b: 170,
    },
  },
  {
    article_number: "1EOS002170",
    color: "170 sandy",
    fabric_uuid: "VADAIN-70034016-1EOS002170",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002170.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002170&h=500&w=500&type=live",
    name: "Eos 170 sandy",
    rgb: {
      r: 188,
      g: 168,
      b: 146,
    },
  },
  {
    article_number: "1EOS002200",
    color: "200 fresh lemon",
    fabric_uuid: "VADAIN-70034015-1EOS002200",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002200.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002200&h=500&w=500&type=live",
    name: "Eos 200 fresh lemon",
    rgb: {
      r: 210,
      g: 204,
      b: 151,
    },
  },
  {
    article_number: "1EOS002220",
    color: "220 mountarde",
    fabric_uuid: "VADAIN-70034007-1EOS002220",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002220.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002220&h=500&w=500&type=live",
    name: "Eos 220 mountarde",
    rgb: {
      r: 170,
      g: 133,
      b: 85,
    },
  },
  {
    article_number: "1EOS002280",
    color: "280 rust",
    fabric_uuid: "VADAIN-70034008-1EOS002280",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002280.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002280&h=500&w=500&type=live",
    name: "Eos 280 rust",
    rgb: {
      r: 152,
      g: 106,
      b: 93,
    },
  },
  {
    article_number: "1EOS002450",
    color: "450 summer blossom",
    fabric_uuid: "VADAIN-70034009-1EOS002450",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002450.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002450&h=500&w=500&type=live",
    name: "Eos 450 summer blossom",
    rgb: {
      r: 198,
      g: 169,
      b: 150,
    },
  },
  {
    article_number: "1EOS002520",
    color: "520 scandi blue",
    fabric_uuid: "VADAIN-70034010-1EOS002520",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002520.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002520&h=500&w=500&type=live",
    name: "Eos 520 scandi blue",
    rgb: {
      r: 106,
      g: 127,
      b: 137,
    },
  },
  {
    article_number: "1EOS002620",
    color: "620 lavender",
    fabric_uuid: "VADAIN-70034011-1EOS002620",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002620.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002620&h=500&w=500&type=live",
    name: "Eos 620 lavender",
    rgb: {
      r: 170,
      g: 155,
      b: 150,
    },
  },
  {
    article_number: "1EOS002925",
    color: "925 misty morning",
    fabric_uuid: "VADAIN-70034012-1EOS002925",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002925.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002925&h=500&w=500&type=live",
    name: "Eos 925 misty morning",
    rgb: {
      r: 162,
      g: 167,
      b: 170,
    },
  },
  {
    article_number: "1EOS002930",
    color: "930 warm grey",
    fabric_uuid: "VADAIN-70034013-1EOS002930",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002930.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002930&h=500&w=500&type=live",
    name: "Eos 930 warm grey",
    rgb: {
      r: 159,
      g: 150,
      b: 141,
    },
  },
  {
    article_number: "1EOS002960",
    color: "960 plummet",
    fabric_uuid: "VADAIN-70034014-1EOS002960",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EOS002960.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EOS002960&h=500&w=500&type=live",
    name: "Eos 960 plummet",
    rgb: {
      r: 119,
      g: 116,
      b: 117,
    },
  },
  {
    article_number: "1ESPR01011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70035106-1ESPR01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01011&h=500&w=500&type=live",
    name: "Espri 11 off white",
    rgb: {
      r: 204,
      g: 199,
      b: 192,
    },
  },
  {
    article_number: "1ESPR01014",
    color: "14 natural",
    fabric_uuid: "VADAIN-70035081-1ESPR01014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01014&h=500&w=500&type=live",
    name: "Espri 14 natural",
    rgb: {
      r: 171,
      g: 156,
      b: 141,
    },
  },
  {
    article_number: "1ESPR01015",
    color: "15 ecru",
    fabric_uuid: "VADAIN-70035097-1ESPR01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01015&h=500&w=500&type=live",
    name: "Espri 15 ecru",
    rgb: {
      r: 199,
      g: 190,
      b: 177,
    },
  },
  {
    article_number: "1ESPR01016",
    color: "16 linen",
    fabric_uuid: "VADAIN-70035102-1ESPR01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01016&h=500&w=500&type=live",
    name: "Espri 16 linen",
    rgb: {
      r: 180,
      g: 164,
      b: 143,
    },
  },
  {
    article_number: "1ESPR01017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70035084-1ESPR01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01017&h=500&w=500&type=live",
    name: "Espri 17 sand",
    rgb: {
      r: 157,
      g: 147,
      b: 133,
    },
  },
  {
    article_number: "1ESPR01018",
    color: "18 sesame",
    fabric_uuid: "VADAIN-70035095-1ESPR01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01018&h=500&w=500&type=live",
    name: "Espri 18 sesame",
    rgb: {
      r: 166,
      g: 145,
      b: 124,
    },
  },
  {
    article_number: "1ESPR01019",
    color: "19 beige",
    fabric_uuid: "VADAIN-70035100-1ESPR01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01019&h=500&w=500&type=live",
    name: "Espri 19 beige",
    rgb: {
      r: 189,
      g: 179,
      b: 164,
    },
  },
  {
    article_number: "1ESPR01020",
    color: "20 curry",
    fabric_uuid: "VADAIN-70035089-1ESPR01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01020&h=500&w=500&type=live",
    name: "Espri 20 curry",
    rgb: {
      r: 173,
      g: 132,
      b: 77,
    },
  },
  {
    article_number: "1ESPR01026",
    color: "26 autumn",
    fabric_uuid: "VADAIN-70035093-1ESPR01026",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01026.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01026&h=500&w=500&type=live",
    name: "Espri 26 autumn",
    rgb: {
      r: 168,
      g: 125,
      b: 95,
    },
  },
  {
    article_number: "1ESPR01028",
    color: "28 brique",
    fabric_uuid: "VADAIN-70035087-1ESPR01028",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01028.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01028&h=500&w=500&type=live",
    name: "Espri 28 brique",
    rgb: {
      r: 146,
      g: 98,
      b: 89,
    },
  },
  {
    article_number: "1ESPR01029",
    color: "29 mahogany",
    fabric_uuid: "VADAIN-70035096-1ESPR01029",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01029.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01029&h=500&w=500&type=live",
    name: "Espri 29 mahogany",
    rgb: {
      r: 112,
      g: 66,
      b: 57,
    },
  },
  {
    article_number: "1ESPR01030",
    color: "30 leaf",
    fabric_uuid: "VADAIN-70035080-1ESPR01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01030&h=500&w=500&type=live",
    name: "Espri 30 leaf",
    rgb: {
      r: 109,
      g: 110,
      b: 89,
    },
  },
  {
    article_number: "1ESPR01031",
    color: "31 sage",
    fabric_uuid: "VADAIN-70035088-1ESPR01031",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01031.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01031&h=500&w=500&type=live",
    name: "Espri 31 sage",
    rgb: {
      r: 103,
      g: 110,
      b: 103,
    },
  },
  {
    article_number: "1ESPR01045",
    color: "45 dusty pink",
    fabric_uuid: "VADAIN-70035092-1ESPR01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01045&h=500&w=500&type=live",
    name: "Espri 45 dusty pink",
    rgb: {
      r: 174,
      g: 147,
      b: 139,
    },
  },
  {
    article_number: "1ESPR01046",
    color: "46 blush",
    fabric_uuid: "VADAIN-70035083-1ESPR01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01046&h=500&w=500&type=live",
    name: "Espri 46 blush",
    rgb: {
      r: 156,
      g: 124,
      b: 121,
    },
  },
  {
    article_number: "1ESPR01054",
    color: "54 scandi blue",
    fabric_uuid: "VADAIN-70035086-1ESPR01054",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01054.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01054&h=500&w=500&type=live",
    name: "Espri 54 scandi blue",
    rgb: {
      r: 118,
      g: 134,
      b: 144,
    },
  },
  {
    article_number: "1ESPR01055",
    color: "55 duck egg",
    fabric_uuid: "VADAIN-70035103-1ESPR01055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01055&h=500&w=500&type=live",
    name: "Espri 55 duck egg",
    rgb: {
      r: 135,
      g: 143,
      b: 139,
    },
  },
  {
    article_number: "1ESPR01057",
    color: "57 ice blue",
    fabric_uuid: "VADAIN-70035079-1ESPR01057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01057&h=500&w=500&type=live",
    name: "Espri 57 ice blue",
    rgb: {
      r: 144,
      g: 149,
      b: 151,
    },
  },
  {
    article_number: "1ESPR01060",
    color: "60 lavender",
    fabric_uuid: "VADAIN-70035090-1ESPR01060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01060&h=500&w=500&type=live",
    name: "Espri 60 lavender",
    rgb: {
      r: 133,
      g: 116,
      b: 126,
    },
  },
  {
    article_number: "1ESPR01083",
    color: "83 caramel",
    fabric_uuid: "VADAIN-70035099-1ESPR01083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01083&h=500&w=500&type=live",
    name: "Espri 83 caramel",
    rgb: {
      r: 148,
      g: 106,
      b: 76,
    },
  },
  {
    article_number: "1ESPR01084",
    color: "84 hazel",
    fabric_uuid: "VADAIN-70035085-1ESPR01084",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01084.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01084&h=500&w=500&type=live",
    name: "Espri 84 hazel",
    rgb: {
      r: 133,
      g: 109,
      b: 89,
    },
  },
  {
    article_number: "1ESPR01090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70035094-1ESPR01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01090&h=500&w=500&type=live",
    name: "Espri 90 grey",
    rgb: {
      r: 118,
      g: 114,
      b: 110,
    },
  },
  {
    article_number: "1ESPR01091",
    color: "91 fog",
    fabric_uuid: "VADAIN-70035105-1ESPR01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01091&h=500&w=500&type=live",
    name: "Espri 91 fog",
    rgb: {
      r: 164,
      g: 162,
      b: 162,
    },
  },
  {
    article_number: "1ESPR01094",
    color: "94 lead",
    fabric_uuid: "VADAIN-70035098-1ESPR01094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01094&h=500&w=500&type=live",
    name: "Espri 94 lead",
    rgb: {
      r: 94,
      g: 94,
      b: 98,
    },
  },
  {
    article_number: "1ESPR01095",
    color: "95 black",
    fabric_uuid: "VADAIN-70035101-1ESPR01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01095&h=500&w=500&type=live",
    name: "Espri 95 black",
    rgb: {
      r: 55,
      g: 56,
      b: 58,
    },
  },
  {
    article_number: "1ESPR01096",
    color: "96 anthracite",
    fabric_uuid: "VADAIN-70035091-1ESPR01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1ESPR01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1ESPR01096&h=500&w=500&type=live",
    name: "Espri 96 anthracite",
    rgb: {
      r: 82,
      g: 81,
      b: 80,
    },
  },
  {
    article_number: "1EXCIT1111",
    color: "111 wool white",
    fabric_uuid: "VADAIN-70040944-1EXCIT1111",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1111.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1111&h=500&w=500&type=live",
    name: "Excite 111 wool white",
    rgb: {
      r: 218,
      g: 215,
      b: 210,
    },
  },
  {
    article_number: "1EXCIT1115",
    color: "115 beach",
    fabric_uuid: "VADAIN-70040935-1EXCIT1115",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1115.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1115&h=500&w=500&type=live",
    name: "Excite 115 beach",
    rgb: {
      r: 203,
      g: 194,
      b: 180,
    },
  },
  {
    article_number: "1EXCIT1116",
    color: "116 oyster",
    fabric_uuid: "VADAIN-70040941-1EXCIT1116",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1116.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1116&h=500&w=500&type=live",
    name: "Excite 116 oyster",
    rgb: {
      r: 195,
      g: 187,
      b: 178,
    },
  },
  {
    article_number: "1EXCIT1117",
    color: "117 greige",
    fabric_uuid: "VADAIN-70040942-1EXCIT1117",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1117.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1117&h=500&w=500&type=live",
    name: "Excite 117 greige",
    rgb: {
      r: 188,
      g: 173,
      b: 156,
    },
  },
  {
    article_number: "1EXCIT1127",
    color: "127 terracotta",
    fabric_uuid: "VADAIN-70040937-1EXCIT1127",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1127.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1127&h=500&w=500&type=live",
    name: "Excite 127 terracotta",
    rgb: {
      r: 178,
      g: 107,
      b: 81,
    },
  },
  {
    article_number: "1EXCIT1130",
    color: "130 swamp",
    fabric_uuid: "VADAIN-70040939-1EXCIT1130",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1130.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1130&h=500&w=500&type=live",
    name: "Excite 130 swamp",
    rgb: {
      r: 131,
      g: 124,
      b: 111,
    },
  },
  {
    article_number: "1EXCIT1145",
    color: "145 dusty pink",
    fabric_uuid: "VADAIN-70040932-1EXCIT1145",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1145.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1145&h=500&w=500&type=live",
    name: "Excite 145 dusty pink",
    rgb: {
      r: 196,
      g: 173,
      b: 163,
    },
  },
  {
    article_number: "1EXCIT1146",
    color: "146 blush",
    fabric_uuid: "VADAIN-70040934-1EXCIT1146",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1146.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1146&h=500&w=500&type=live",
    name: "Excite 146 blush",
    rgb: {
      r: 186,
      g: 132,
      b: 120,
    },
  },
  {
    article_number: "1EXCIT1150",
    color: "150 navy",
    fabric_uuid: "VADAIN-70040940-1EXCIT1150",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1150.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1150&h=500&w=500&type=live",
    name: "Excite 150 navy",
    rgb: {
      r: 64,
      g: 66,
      b: 79,
    },
  },
  {
    article_number: "1EXCIT1159",
    color: "159 mint",
    fabric_uuid: "VADAIN-70040931-1EXCIT1159",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1159.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1159&h=500&w=500&type=live",
    name: "Excite 159 mint",
    rgb: {
      r: 165,
      g: 168,
      b: 159,
    },
  },
  {
    article_number: "1EXCIT1180",
    color: "180 latte",
    fabric_uuid: "VADAIN-70040938-1EXCIT1180",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1180.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1180&h=500&w=500&type=live",
    name: "Excite 180 latte",
    rgb: {
      r: 156,
      g: 135,
      b: 114,
    },
  },
  {
    article_number: "1EXCIT1182",
    color: "182 chinchilla",
    fabric_uuid: "VADAIN-70040943-1EXCIT1182",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1182.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1182&h=500&w=500&type=live",
    name: "Excite 182 chinchilla",
    rgb: {
      r: 137,
      g: 120,
      b: 106,
    },
  },
  {
    article_number: "1EXCIT1191",
    color: "191 fog",
    fabric_uuid: "VADAIN-70040933-1EXCIT1191",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/NOIMAGE.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1191&h=500&w=500&type=live",
    name: "Excite 191 fog",
    rgb: {
      r: 189,
      g: 184,
      b: 179,
    },
  },
  {
    article_number: "1EXCIT1192",
    color: "192 silver",
    fabric_uuid: "VADAIN-70040936-1EXCIT1192",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1192.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1192&h=500&w=500&type=live",
    name: "Excite 192 silver",
    rgb: {
      r: 175,
      g: 169,
      b: 167,
    },
  },
  {
    article_number: "1EXCIT1193",
    color: "193 zinc",
    fabric_uuid: "VADAIN-70040945-1EXCIT1193",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1EXCIT1193.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1EXCIT1193&h=500&w=500&type=live",
    name: "Excite 193 zinc",
    rgb: {
      r: 147,
      g: 151,
      b: 155,
    },
  },
  {
    article_number: "1FAIT01030",
    color: "30 forest",
    fabric_uuid: "VADAIN-70037687-1FAIT01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FAIT01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FAIT01030&h=500&w=500&type=live",
    name: "Faith 30 forest",
    rgb: {
      r: 109,
      g: 110,
      b: 101,
    },
  },
  {
    article_number: "1FAIT01035",
    color: "35 mint",
    fabric_uuid: "VADAIN-70037685-1FAIT01035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FAIT01035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FAIT01035&h=500&w=500&type=live",
    name: "Faith 35 mint",
    rgb: {
      r: 147,
      g: 158,
      b: 157,
    },
  },
  {
    article_number: "1FAIT01082",
    color: "82 autumn",
    fabric_uuid: "VADAIN-70037686-1FAIT01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FAIT01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FAIT01082&h=500&w=500&type=live",
    name: "Faith 82 autumn",
    rgb: {
      r: 136,
      g: 112,
      b: 93,
    },
  },
  {
    article_number: "1FAIT01092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70037688-1FAIT01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FAIT01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FAIT01092&h=500&w=500&type=live",
    name: "Faith 92 silver",
    rgb: {
      r: 163,
      g: 164,
      b: 162,
    },
  },
  {
    article_number: "1FELT01015",
    color: "15 woll white",
    fabric_uuid: "VADAIN-70030448-1FELT01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01015&h=500&w=500&type=live",
    name: "Felt 15 wool white",
    rgb: {
      r: 216,
      g: 209,
      b: 191,
    },
  },
  {
    article_number: "1FELT01017",
    color: "17 oatmeal",
    fabric_uuid: "VADAIN-70030459-1FELT01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01017&h=500&w=500&type=live",
    name: "Felt 17 oatmeal",
    rgb: {
      r: 184,
      g: 163,
      b: 135,
    },
  },
  {
    article_number: "1FELT01019",
    color: "19 greige",
    fabric_uuid: "VADAIN-70030445-1FELT01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01019&h=500&w=500&type=live",
    name: "Felt 19 greige",
    rgb: {
      r: 172,
      g: 169,
      b: 159,
    },
  },
  {
    article_number: "1FELT01030",
    color: "30 fern",
    fabric_uuid: "VADAIN-70030450-1FELT01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01030&h=500&w=500&type=live",
    name: "Felt 30 fern",
    rgb: {
      r: 69,
      g: 72,
      b: 66,
    },
  },
  {
    article_number: "1FELT01032",
    color: "32 emerald",
    fabric_uuid: "VADAIN-70030456-1FELT01032",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01032.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01032&h=500&w=500&type=live",
    name: "Felt 32 emerald",
    rgb: {
      r: 47,
      g: 75,
      b: 76,
    },
  },
  {
    article_number: "1FELT01037",
    color: "37 rust",
    fabric_uuid: "VADAIN-70030443-1FELT01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01037&h=500&w=500&type=live",
    name: "Felt 37 Rust",
    rgb: {
      r: 133,
      g: 83,
      b: 55,
    },
  },
  {
    article_number: "1FELT01040",
    color: "40 warm red",
    fabric_uuid: "VADAIN-70030453-1FELT01040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01040&h=500&w=500&type=live",
    name: "Felt 40 warm red",
    rgb: {
      r: 107,
      g: 50,
      b: 50,
    },
  },
  {
    article_number: "1FELT01045",
    color: "45 lipstick",
    fabric_uuid: "VADAIN-70030460-1FELT01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01045&h=500&w=500&type=live",
    name: "Felt 45 lipstick",
    rgb: {
      r: 103,
      g: 46,
      b: 63,
    },
  },
  {
    article_number: "1FELT01050",
    color: "50 royal blue",
    fabric_uuid: "VADAIN-70030446-1FELT01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01050&h=500&w=500&type=live",
    name: "Felt 50 royal blue",
    rgb: {
      r: 48,
      g: 66,
      b: 90,
    },
  },
  {
    article_number: "1FELT01052",
    color: "52 dark blue",
    fabric_uuid: "VADAIN-70030451-1FELT01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01052&h=500&w=500&type=live",
    name: "Felt 52 dark blue",
    rgb: {
      r: 58,
      g: 61,
      b: 70,
    },
  },
  {
    article_number: "1FELT01055",
    color: "55 peaCock",
    fabric_uuid: "VADAIN-70030457-1FELT01055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01055&h=500&w=500&type=live",
    name: "Felt 55 peacock",
    rgb: {
      r: 44,
      g: 78,
      b: 96,
    },
  },
  {
    article_number: "1FELT01065",
    color: "65 aubergine",
    fabric_uuid: "VADAIN-70030444-1FELT01065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01065&h=500&w=500&type=live",
    name: "Felt 65 aubergine",
    rgb: {
      r: 64,
      g: 50,
      b: 55,
    },
  },
  {
    article_number: "1FELT01080",
    color: "80 brown",
    fabric_uuid: "VADAIN-70030454-1FELT01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01080&h=500&w=500&type=live",
    name: "Felt 80 brown",
    rgb: {
      r: 98,
      g: 84,
      b: 76,
    },
  },
  {
    article_number: "1FELT01082",
    color: "82 cacao",
    fabric_uuid: "VADAIN-70030455-1FELT01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01082&h=500&w=500&type=live",
    name: "Felt 82 cacao",
    rgb: {
      r: 69,
      g: 63,
      b: 63,
    },
  },
  {
    article_number: "1FELT01085",
    color: "85 taupe",
    fabric_uuid: "VADAIN-70030449-1FELT01085",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01085.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01085&h=500&w=500&type=live",
    name: "Felt 85 taupe",
    rgb: {
      r: 119,
      g: 114,
      b: 108,
    },
  },
  {
    article_number: "1FELT01087",
    color: "87 spices",
    fabric_uuid: "VADAIN-70030452-1FELT01087",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01087.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01087&h=500&w=500&type=live",
    name: "Felt 87 spices",
    rgb: {
      r: 113,
      g: 102,
      b: 94,
    },
  },
  {
    article_number: "1FELT01090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70030458-1FELT01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01090&h=500&w=500&type=live",
    name: "Felt 90 grey",
    rgb: {
      r: 115,
      g: 115,
      b: 115,
    },
  },
  {
    article_number: "1FELT01095",
    color: "95 nearly black",
    fabric_uuid: "VADAIN-70030447-1FELT01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FELT01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FELT01095&h=500&w=500&type=live",
    name: "Felt 95 nearly black",
    rgb: {
      r: 56,
      g: 58,
      b: 57,
    },
  },
  {
    article_number: "1FESTI02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70029482-1FESTI02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02010&h=500&w=500&type=live",
    name: "Festival kh 10 white",
    rgb: {
      r: 149,
      g: 149,
      b: 147,
    },
  },
  {
    article_number: "1FESTI02011",
    color: "11 off whit",
    fabric_uuid: "VADAIN-70029498-1FESTI02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02011&h=500&w=500&type=live",
    name: "Festival kh 11 off white",
    rgb: {
      r: 147,
      g: 148,
      b: 143,
    },
  },
  {
    article_number: "1FESTI02013",
    color: "13 pearl",
    fabric_uuid: "VADAIN-70029471-1FESTI02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02013&h=500&w=500&type=live",
    name: "Festival kh 13 pearl",
    rgb: {
      r: 149,
      g: 150,
      b: 145,
    },
  },
  {
    article_number: "1FESTI02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70029492-1FESTI02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02015&h=500&w=500&type=live",
    name: "Festival kh 15 cream",
    rgb: {
      r: 150,
      g: 147,
      b: 139,
    },
  },
  {
    article_number: "1FESTI02016",
    color: "16 almond",
    fabric_uuid: "VADAIN-70029502-1FESTI02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02016&h=500&w=500&type=live",
    name: "Festival kh 16 almond",
    rgb: {
      r: 146,
      g: 141,
      b: 133,
    },
  },
  {
    article_number: "1FESTI02017",
    color: "17 oatmeal",
    fabric_uuid: "VADAIN-70029468-1FESTI02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02017&h=500&w=500&type=live",
    name: "Festival kh 17 oatmeal",
    rgb: {
      r: 146,
      g: 143,
      b: 136,
    },
  },
  {
    article_number: "1FESTI02019",
    color: "19 pebble",
    fabric_uuid: "VADAIN-70029496-1FESTI02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02019&h=500&w=500&type=live",
    name: "Festival kh 19 pebble",
    rgb: {
      r: 139,
      g: 136,
      b: 129,
    },
  },
  {
    article_number: "1FESTI02020",
    color: "20 banana",
    fabric_uuid: "VADAIN-70029508-1FESTI02020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02020&h=500&w=500&type=live",
    name: "Festival kh 20 banana",
    rgb: {
      r: 151,
      g: 145,
      b: 125,
    },
  },
  {
    article_number: "1FESTI02021",
    color: "21 gold",
    fabric_uuid: "VADAIN-70029485-1FESTI02021",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02021.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02021&h=500&w=500&type=live",
    name: "Festival kh 21 gold",
    rgb: {
      r: 148,
      g: 138,
      b: 117,
    },
  },
  {
    article_number: "1FESTI02022",
    color: "22 vanilla",
    fabric_uuid: "VADAIN-70029490-1FESTI02022",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02022.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02022&h=500&w=500&type=live",
    name: "Festival kh 22 vanilia",
    rgb: {
      r: 150,
      g: 144,
      b: 131,
    },
  },
  {
    article_number: "1FESTI02025",
    color: "25 orange",
    fabric_uuid: "VADAIN-70029503-1FESTI02025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02025&h=500&w=500&type=live",
    name: "Festival kh 25 orange",
    rgb: {
      r: 148,
      g: 126,
      b: 114,
    },
  },
  {
    article_number: "1FESTI02026",
    color: "26 peach skin",
    fabric_uuid: "VADAIN-70029481-1FESTI02026",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02026.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02026&h=500&w=500&type=live",
    name: "Festival kh 26 peach skin",
    rgb: {
      r: 149,
      g: 138,
      b: 127,
    },
  },
  {
    article_number: "1FESTI02027",
    color: "27 baked clay",
    fabric_uuid: "VADAIN-70029497-1FESTI02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02027&h=500&w=500&type=live",
    name: "Festival kh 27 baked clay",
    rgb: {
      r: 142,
      g: 129,
      b: 119,
    },
  },
  {
    article_number: "1FESTI02030",
    color: "30 sage",
    fabric_uuid: "VADAIN-70029469-1FESTI02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02030&h=500&w=500&type=live",
    name: "Festival kh 30 sage",
    rgb: {
      r: 133,
      g: 134,
      b: 126,
    },
  },
  {
    article_number: "1FESTI02031",
    color: "31 antique green",
    fabric_uuid: "VADAIN-70029486-1FESTI02031",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02031.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02031&h=500&w=500&type=live",
    name: "Festival kh 31 antique green",
    rgb: {
      r: 130,
      g: 131,
      b: 125,
    },
  },
  {
    article_number: "1FESTI02032",
    color: "32 hunter",
    fabric_uuid: "VADAIN-70029491-1FESTI02032",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02032.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02032&h=500&w=500&type=live",
    name: "Festival kh 32 hunter",
    rgb: {
      r: 121,
      g: 125,
      b: 118,
    },
  },
  {
    article_number: "1FESTI02033",
    color: "33 jungle",
    fabric_uuid: "VADAIN-70029475-1FESTI02033",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02033.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02033&h=500&w=500&type=live",
    name: "Festival kh 33 jungle",
    rgb: {
      r: 117,
      g: 129,
      b: 123,
    },
  },
  {
    article_number: "1FESTI02034",
    color: "34 ocean green",
    fabric_uuid: "VADAIN-70029480-1FESTI02034",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02034.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02034&h=500&w=500&type=live",
    name: "Festival kh 34 ocean green",
    rgb: {
      r: 117,
      g: 129,
      b: 127,
    },
  },
  {
    article_number: "1FESTI02040",
    color: "40 crimson",
    fabric_uuid: "VADAIN-70029501-1FESTI02040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02040&h=500&w=500&type=live",
    name: "Festival kh 40 crimson",
    rgb: {
      r: 137,
      g: 115,
      b: 113,
    },
  },
  {
    article_number: "1FESTI02041",
    color: "41 tomato red",
    fabric_uuid: "VADAIN-70029470-1FESTI02041",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02041.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02041&h=500&w=500&type=live",
    name: "Festival kh 41 tomato red",
    rgb: {
      r: 145,
      g: 121,
      b: 117,
    },
  },
  {
    article_number: "1FESTI02042",
    color: "42 bordeaux",
    fabric_uuid: "VADAIN-70029476-1FESTI02042",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02042.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02042&h=500&w=500&type=live",
    name: "Festival kh 42 bordeaux",
    rgb: {
      r: 131,
      g: 115,
      b: 115,
    },
  },
  {
    article_number: "1FESTI02043",
    color: "43 dark red",
    fabric_uuid: "VADAIN-70029507-1FESTI02043",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02043.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02043&h=500&w=500&type=live",
    name: "Festival kh 43 dark red",
    rgb: {
      r: 134,
      g: 118,
      b: 115,
    },
  },
  {
    article_number: "1FESTI02044",
    color: "44 copper",
    fabric_uuid: "VADAIN-70029465-1FESTI02044",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02044.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02044&h=500&w=500&type=live",
    name: "Festival kh 44 copper",
    rgb: {
      r: 138,
      g: 120,
      b: 111,
    },
  },
  {
    article_number: "1FESTI02045",
    color: "45 fuchsia",
    fabric_uuid: "VADAIN-70029493-1FESTI02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02045&h=500&w=500&type=live",
    name: "Festival kh 45 fuchsia",
    rgb: {
      r: 145,
      g: 118,
      b: 125,
    },
  },
  {
    article_number: "1FESTI02046",
    color: "46 pale skin",
    fabric_uuid: "VADAIN-70029499-1FESTI02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02046&h=500&w=500&type=live",
    name: "Festival kh 46 pale skin",
    rgb: {
      r: 147,
      g: 142,
      b: 136,
    },
  },
  {
    article_number: "1FESTI02047",
    color: "47 blush",
    fabric_uuid: "VADAIN-70029472-1FESTI02047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02047&h=500&w=500&type=live",
    name: "Festival kh 47 blush",
    rgb: {
      r: 144,
      g: 134,
      b: 128,
    },
  },
  {
    article_number: "1FESTI02048",
    color: "48 baby pink",
    fabric_uuid: "VADAIN-70029488-1FESTI02048",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02048.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02048&h=500&w=500&type=live",
    name: "Festival kh 48 baby pink",
    rgb: {
      r: 146,
      g: 141,
      b: 137,
    },
  },
  {
    article_number: "1FESTI02049",
    color: "49 rose bisque",
    fabric_uuid: "VADAIN-70029505-1FESTI02049",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02049.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02049&h=500&w=500&type=live",
    name: "Festival kh 49 rose bisque",
    rgb: {
      r: 143,
      g: 129,
      b: 125,
    },
  },
  {
    article_number: "1FESTI02050",
    color: "50 ink",
    fabric_uuid: "VADAIN-70029477-1FESTI02050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02050&h=500&w=500&type=live",
    name: "Festival kh 50 ink",
    rgb: {
      r: 117,
      g: 118,
      b: 122,
    },
  },
  {
    article_number: "1FESTI02051",
    color: "51 greek blue",
    fabric_uuid: "VADAIN-70029494-1FESTI02051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02051&h=500&w=500&type=live",
    name: "Festival kh 51 greek blue",
    rgb: {
      r: 116,
      g: 120,
      b: 124,
    },
  },
  {
    article_number: "1FESTI02054",
    color: "54 cornflower blue",
    fabric_uuid: "VADAIN-70029500-1FESTI02054",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02054.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02054&h=500&w=500&type=live",
    name: "Festival kh 54 cornflower blue",
    rgb: {
      r: 124,
      g: 129,
      b: 133,
    },
  },
  {
    article_number: "1FESTI02055",
    color: "55 baby blue",
    fabric_uuid: "VADAIN-70029479-1FESTI02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02055&h=500&w=500&type=live",
    name: "Festival kh 55 baby blue",
    rgb: {
      r: 135,
      g: 139,
      b: 139,
    },
  },
  {
    article_number: "1FESTI02058",
    color: "58 calypso",
    fabric_uuid: "VADAIN-70029495-1FESTI02058",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02058.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02058&h=500&w=500&type=live",
    name: "Festival kh 58 calypso",
    rgb: {
      r: 123,
      g: 129,
      b: 127,
    },
  },
  {
    article_number: "1FESTI02059",
    color: "59 light duck egg",
    fabric_uuid: "VADAIN-70029467-1FESTI02059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02059&h=500&w=500&type=live",
    name: "Festival kh 59 light duck egg",
    rgb: {
      r: 132,
      g: 137,
      b: 133,
    },
  },
  {
    article_number: "1FESTI02060",
    color: "60 purple",
    fabric_uuid: "VADAIN-70029484-1FESTI02060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02060&h=500&w=500&type=live",
    name: "Festival kh 60 purple",
    rgb: {
      r: 125,
      g: 114,
      b: 118,
    },
  },
  {
    article_number: "1FESTI02061",
    color: "61 magenta",
    fabric_uuid: "VADAIN-70029489-1FESTI02061",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02061.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02061&h=500&w=500&type=live",
    name: "Festival kh 61 magenta",
    rgb: {
      r: 137,
      g: 119,
      b: 123,
    },
  },
  {
    article_number: "1FESTI02063",
    color: "63 mauve",
    fabric_uuid: "VADAIN-70029473-1FESTI02063",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02063.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02063&h=500&w=500&type=live",
    name: "Festival kh 63 mauve",
    rgb: {
      r: 132,
      g: 131,
      b: 129,
    },
  },
  {
    article_number: "1FESTI02065",
    color: "65 heather",
    fabric_uuid: "VADAIN-70029478-1FESTI02065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02065&h=500&w=500&type=live",
    name: "Festival kh 65 heather",
    rgb: {
      r: 134,
      g: 121,
      b: 121,
    },
  },
  {
    article_number: "1FESTI02083",
    color: "83 glazed ginger",
    fabric_uuid: "VADAIN-70029504-1FESTI02083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02083&h=500&w=500&type=live",
    name: "Festival kh 83 glazed ginger",
    rgb: {
      r: 136,
      g: 123,
      b: 109,
    },
  },
  {
    article_number: "1FESTI02086",
    color: "86 taupe",
    fabric_uuid: "VADAIN-70029466-1FESTI02086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02086&h=500&w=500&type=live",
    name: "Festival kh 86 taupe",
    rgb: {
      r: 133,
      g: 129,
      b: 123,
    },
  },
  {
    article_number: "1FESTI02090",
    color: "90 steel",
    fabric_uuid: "VADAIN-70029483-1FESTI02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02090&h=500&w=500&type=live",
    name: "Festival kh 90 steel",
    rgb: {
      r: 129,
      g: 129,
      b: 127,
    },
  },
  {
    article_number: "1FESTI02091",
    color: "91 green grey",
    fabric_uuid: "VADAIN-70029509-1FESTI02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02091&h=500&w=500&type=live",
    name: "Festival kh 91 green grey",
    rgb: {
      r: 122,
      g: 124,
      b: 121,
    },
  },
  {
    article_number: "1FESTI02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70029474-1FESTI02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02092&h=500&w=500&type=live",
    name: "Festival kh 92 silver",
    rgb: {
      r: 137,
      g: 136,
      b: 134,
    },
  },
  {
    article_number: "1FESTI02093",
    color: "93 pewter",
    fabric_uuid: "VADAIN-70029487-1FESTI02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02093&h=500&w=500&type=live",
    name: "Festival kh 93 pewter",
    rgb: {
      r: 135,
      g: 134,
      b: 129,
    },
  },
  {
    article_number: "1FESTI02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70029506-1FESTI02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02095&h=500&w=500&type=live",
    name: "Festival kh 95 black",
    rgb: {
      r: 113,
      g: 113,
      b: 111,
    },
  },
  {
    article_number: "1FESTI02096",
    color: "96 anthracite",
    fabric_uuid: "VADAIN-70029464-1FESTI02096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FESTI02096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FESTI02096&h=500&w=500&type=live",
    name: "Festival kh 96 anthracite",
    rgb: {
      r: 120,
      g: 119,
      b: 115,
    },
  },
  {
    article_number: "1FILT02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70037633-1FILT02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02010&h=500&w=500&type=live",
    name: "Filter 10 white",
    rgb: {
      r: 169,
      g: 169,
      b: 169,
    },
  },
  {
    article_number: "1FILT02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70037634-1FILT02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02011&h=500&w=500&type=live",
    name: "Filter 11 off white",
    rgb: {
      r: 170,
      g: 170,
      b: 165,
    },
  },
  {
    article_number: "1FILT02012",
    color: "12 beach",
    fabric_uuid: "VADAIN-70037614-1FILT02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02012&h=500&w=500&type=live",
    name: "Filter 12 beach",
    rgb: {
      r: 152,
      g: 147,
      b: 140,
    },
  },
  {
    article_number: "1FILT02013",
    color: "13 pearl cream",
    fabric_uuid: "VADAIN-70037615-1FILT02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02013&h=500&w=500&type=live",
    name: "Filter 13 pearl cream",
    rgb: {
      r: 169,
      g: 161,
      b: 150,
    },
  },
  {
    article_number: "1FILT02015",
    color: "15 beige",
    fabric_uuid: "VADAIN-70037616-1FILT02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02015&h=500&w=500&type=live",
    name: "Filter 15 beige",
    rgb: {
      r: 162,
      g: 157,
      b: 147,
    },
  },
  {
    article_number: "1FILT02017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70037617-1FILT02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02017&h=500&w=500&type=live",
    name: "Filter 17 linen",
    rgb: {
      r: 160,
      g: 153,
      b: 142,
    },
  },
  {
    article_number: "1FILT02024",
    color: "24 honey",
    fabric_uuid: "VADAIN-70037618-1FILT02024",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02024.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02024&h=500&w=500&type=live",
    name: "Filter 24 honey",
    rgb: {
      r: 154,
      g: 133,
      b: 115,
    },
  },
  {
    article_number: "1FILT02027",
    color: "27 copper",
    fabric_uuid: "VADAIN-70037619-1FILT02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02027&h=500&w=500&type=live",
    name: "Filter 27 copper",
    rgb: {
      r: 142,
      g: 120,
      b: 109,
    },
  },
  {
    article_number: "1FILT02034",
    color: "34 dusty mint",
    fabric_uuid: "VADAIN-70037620-1FILT02034",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02034.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02034&h=500&w=500&type=live",
    name: "Filter 34 dusty mint",
    rgb: {
      r: 159,
      g: 159,
      b: 153,
    },
  },
  {
    article_number: "1FILT02037",
    color: "37 sage",
    fabric_uuid: "VADAIN-70037621-1FILT02037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02037&h=500&w=500&type=live",
    name: "Filter 37 sage",
    rgb: {
      r: 145,
      g: 146,
      b: 140,
    },
  },
  {
    article_number: "1FILT02040",
    color: "40 rose",
    fabric_uuid: "VADAIN-70037622-1FILT02040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02040&h=500&w=500&type=live",
    name: "Filter 40 rose",
    rgb: {
      r: 164,
      g: 150,
      b: 143,
    },
  },
  {
    article_number: "1FILT02041",
    color: "41 rose beige",
    fabric_uuid: "VADAIN-70037623-1FILT02041",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02041.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02041&h=500&w=500&type=live",
    name: "Filter 41 rose beige",
    rgb: {
      r: 165,
      g: 153,
      b: 144,
    },
  },
  {
    article_number: "1FILT02042",
    color: "42 burgundy",
    fabric_uuid: "VADAIN-70037624-1FILT02042",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02042.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02042&h=500&w=500&type=live",
    name: "Filter 42 burgundy",
    rgb: {
      r: 152,
      g: 137,
      b: 129,
    },
  },
  {
    article_number: "1FILT02053",
    color: "53 blue grey",
    fabric_uuid: "VADAIN-70037625-1FILT02053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02053&h=500&w=500&type=live",
    name: "Filter 53 blue grey",
    rgb: {
      r: 130,
      g: 130,
      b: 128,
    },
  },
  {
    article_number: "1FILT02061",
    color: "61 mauve",
    fabric_uuid: "VADAIN-70037626-1FILT02061",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02061.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02061&h=500&w=500&type=live",
    name: "Filter 61 mauve",
    rgb: {
      r: 147,
      g: 138,
      b: 135,
    },
  },
  {
    article_number: "1FILT02063",
    color: "63 lavender",
    fabric_uuid: "VADAIN-70037632-1FILT02063",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02063.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02063&h=500&w=500&type=live",
    name: "Filter 63 lavender",
    rgb: {
      r: 150,
      g: 147,
      b: 145,
    },
  },
  {
    article_number: "1FILT02082",
    color: "82 cinnamon",
    fabric_uuid: "VADAIN-70037627-1FILT02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02082&h=500&w=500&type=live",
    name: "Filter 82 cinnamon",
    rgb: {
      r: 146,
      g: 127,
      b: 115,
    },
  },
  {
    article_number: "1FILT02090",
    color: "90 cloud",
    fabric_uuid: "VADAIN-70037628-1FILT02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02090&h=500&w=500&type=live",
    name: "Filter 90 cloud",
    rgb: {
      r: 151,
      g: 148,
      b: 144,
    },
  },
  {
    article_number: "1FILT02091",
    color: "91 coin",
    fabric_uuid: "VADAIN-70037629-1FILT02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02091&h=500&w=500&type=live",
    name: "Filter 91 coin",
    rgb: {
      r: 141,
      g: 140,
      b: 136,
    },
  },
  {
    article_number: "1FILT02093",
    color: "93 pewter",
    fabric_uuid: "VADAIN-70037630-1FILT02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02093&h=500&w=500&type=live",
    name: "Filter 93 pewter",
    rgb: {
      r: 148,
      g: 145,
      b: 144,
    },
  },
  {
    article_number: "1FILT02094",
    color: "94 iron",
    fabric_uuid: "VADAIN-70037631-1FILT02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FILT02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FILT02094&h=500&w=500&type=live",
    name: "Filter 94 iron",
    rgb: {
      r: 127,
      g: 127,
      b: 125,
    },
  },
  {
    article_number: "1FLAX02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70018479-1FLAX02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02010&h=500&w=500&type=live",
    name: "Flaxy kh 10 white",
    rgb: {
      r: 199,
      g: 199,
      b: 199,
    },
  },
  {
    article_number: "1FLAX02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70018480-1FLAX02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02011&h=500&w=500&type=live",
    name: "Flaxy kh 11 off white",
    rgb: {
      r: 211,
      g: 210,
      b: 205,
    },
  },
  {
    article_number: "1FLAX02015",
    color: "15 pebble",
    fabric_uuid: "VADAIN-70018481-1FLAX02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02015&h=500&w=500&type=live",
    name: "Flaxy kh 15 pebble",
    rgb: {
      r: 185,
      g: 184,
      b: 178,
    },
  },
  {
    article_number: "1FLAX02017",
    color: "17 oatmeal",
    fabric_uuid: "VADAIN-70018482-1FLAX02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02017&h=500&w=500&type=live",
    name: "Flaxy kh 17 oatmeal",
    rgb: {
      r: 174,
      g: 167,
      b: 158,
    },
  },
  {
    article_number: "1FLAX02019",
    color: "19 linen",
    fabric_uuid: "VADAIN-70018483-1FLAX02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02019&h=500&w=500&type=live",
    name: "Flaxy kh 19 linen",
    rgb: {
      r: 169,
      g: 160,
      b: 150,
    },
  },
  {
    article_number: "1FLAX02035",
    color: "35 sage",
    fabric_uuid: "VADAIN-70018484-1FLAX02035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02035&h=500&w=500&type=live",
    name: "Flaxy kh 35 sage",
    rgb: {
      r: 143,
      g: 146,
      b: 138,
    },
  },
  {
    article_number: "1FLAX02050",
    color: "50 denim",
    fabric_uuid: "VADAIN-70018485-1FLAX02050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02050&h=500&w=500&type=live",
    name: "Flaxy kh 50 denim",
    rgb: {
      r: 107,
      g: 113,
      b: 125,
    },
  },
  {
    article_number: "1FLAX02055",
    color: "55 sky",
    fabric_uuid: "VADAIN-70018486-1FLAX02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02055&h=500&w=500&type=live",
    name: "Flaxy kh 55 sky",
    rgb: {
      r: 139,
      g: 148,
      b: 154,
    },
  },
  {
    article_number: "1FLAX02080",
    color: "80 almond",
    fabric_uuid: "VADAIN-70018487-1FLAX02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02080&h=500&w=500&type=live",
    name: "Flaxy kh 80 almond",
    rgb: {
      r: 142,
      g: 130,
      b: 118,
    },
  },
  {
    article_number: "1FLAX02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70018488-1FLAX02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02090&h=500&w=500&type=live",
    name: "Flaxy kh 90 grey",
    rgb: {
      r: 130,
      g: 128,
      b: 129,
    },
  },
  {
    article_number: "1FLAX02096",
    color: "96 blue graphite",
    fabric_uuid: "VADAIN-70018489-1FLAX02096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLAX02096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLAX02096&h=500&w=500&type=live",
    name: "Flaxy kh 96 blue graphite",
    rgb: {
      r: 103,
      g: 109,
      b: 112,
    },
  },
  {
    article_number: "1FLORE01010",
    color: "10 snow white",
    fabric_uuid: "VADAIN-70029536-1FLORE01010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01010&h=500&w=500&type=live",
    name: "Flores fr 10 snow white",
    rgb: {
      r: 226,
      g: 227,
      b: 231,
    },
  },
  {
    article_number: "1FLORE01015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70029522-1FLORE01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01015&h=500&w=500&type=live",
    name: "Flores fr 15 cream",
    rgb: {
      r: 227,
      g: 227,
      b: 225,
    },
  },
  {
    article_number: "1FLORE01016",
    color: "16 oatmeal",
    fabric_uuid: "VADAIN-70029527-1FLORE01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01016&h=500&w=500&type=live",
    name: "Flores fr 16 oatmeal",
    rgb: {
      r: 219,
      g: 215,
      b: 204,
    },
  },
  {
    article_number: "1FLORE01017",
    color: "17 sand",
    fabric_uuid: "VADAIN-70029514-1FLORE01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01017&h=500&w=500&type=live",
    name: "Flores fr 17 sand",
    rgb: {
      r: 201,
      g: 181,
      b: 160,
    },
  },
  {
    article_number: "1FLORE01022",
    color: "22 ochre",
    fabric_uuid: "VADAIN-70029518-1FLORE01022",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01022.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01022&h=500&w=500&type=live",
    name: "Flores fr 22 ochre",
    rgb: {
      r: 207,
      g: 161,
      b: 55,
    },
  },
  {
    article_number: "1FLORE01023",
    color: "23 bright yellow",
    fabric_uuid: "VADAIN-70029530-1FLORE01023",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01023.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01023&h=500&w=500&type=live",
    name: "Flores fr 23 bright yellow",
    rgb: {
      r: 197,
      g: 166,
      b: 70,
    },
  },
  {
    article_number: "1FLORE01030",
    color: "30 forest",
    fabric_uuid: "VADAIN-70029511-1FLORE01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01030&h=500&w=500&type=live",
    name: "Flores fr 30 forest",
    rgb: {
      r: 104,
      g: 125,
      b: 99,
    },
  },
  {
    article_number: "1FLORE01036",
    color: "36 petrol",
    fabric_uuid: "VADAIN-70029523-1FLORE01036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01036&h=500&w=500&type=live",
    name: "Flores fr 36 petrol",
    rgb: {
      r: 67,
      g: 104,
      b: 104,
    },
  },
  {
    article_number: "1FLORE01038",
    color: "38 metal green",
    fabric_uuid: "VADAIN-70029533-1FLORE01038",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01038.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01038&h=500&w=500&type=live",
    name: "Flores fr 38 metal",
    rgb: {
      r: 89,
      g: 106,
      b: 104,
    },
  },
  {
    article_number: "1FLORE01040",
    color: "40 ruby",
    fabric_uuid: "VADAIN-70029516-1FLORE01040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01040&h=500&w=500&type=live",
    name: "Flores fr 40 ruby",
    rgb: {
      r: 109,
      g: 22,
      b: 33,
    },
  },
  {
    article_number: "1FLORE01045",
    color: "45 rose bisque",
    fabric_uuid: "VADAIN-70029520-1FLORE01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01045&h=500&w=500&type=live",
    name: "Flores fr 45 rose bisque",
    rgb: {
      r: 189,
      g: 132,
      b: 119,
    },
  },
  {
    article_number: "1FLORE01046",
    color: "46 soft pink",
    fabric_uuid: "VADAIN-70029538-1FLORE01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01046&h=500&w=500&type=live",
    name: "Flores fr 46 soft pink",
    rgb: {
      r: 220,
      g: 194,
      b: 185,
    },
  },
  {
    article_number: "1FLORE01047",
    color: "47 dusty pink",
    fabric_uuid: "VADAIN-70029513-1FLORE01047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01047&h=500&w=500&type=live",
    name: "Flores fr 47 dusty pink",
    rgb: {
      r: 206,
      g: 178,
      b: 168,
    },
  },
  {
    article_number: "1FLORE01049",
    color: "49 copper",
    fabric_uuid: "VADAIN-70029532-1FLORE01049",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01049.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01049&h=500&w=500&type=live",
    name: "Flores fr 49 copper",
    rgb: {
      r: 170,
      g: 99,
      b: 52,
    },
  },
  {
    article_number: "1FLORE01050",
    color: "50 royal blue",
    fabric_uuid: "VADAIN-70029534-1FLORE01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01050&h=500&w=500&type=live",
    name: "Flores fr 50 royal blue",
    rgb: {
      r: 41,
      g: 59,
      b: 79,
    },
  },
  {
    article_number: "1FLORE01051",
    color: "51 medium blue",
    fabric_uuid: "VADAIN-70029517-1FLORE01051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01051&h=500&w=500&type=live",
    name: "Flores fr 51 medium blue",
    rgb: {
      r: 90,
      g: 113,
      b: 134,
    },
  },
  {
    article_number: "1FLORE01055",
    color: "55 sky blue",
    fabric_uuid: "VADAIN-70029529-1FLORE01055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01055&h=500&w=500&type=live",
    name: "Flores fr 55 sky blue",
    rgb: {
      r: 103,
      g: 131,
      b: 152,
    },
  },
  {
    article_number: "1FLORE01056",
    color: "56 duck egg",
    fabric_uuid: "VADAIN-70029535-1FLORE01056",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01056.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01056&h=500&w=500&type=live",
    name: "Flores fr 56 duck egg",
    rgb: {
      r: 169,
      g: 188,
      b: 186,
    },
  },
  {
    article_number: "1FLORE01057",
    color: "57 peacock",
    fabric_uuid: "VADAIN-70029525-1FLORE01057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01057&h=500&w=500&type=live",
    name: "Flores fr 57 peacock",
    rgb: {
      r: 26,
      g: 110,
      b: 130,
    },
  },
  {
    article_number: "1FLORE01065",
    color: "65 mauve",
    fabric_uuid: "VADAIN-70029528-1FLORE01065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01065&h=500&w=500&type=live",
    name: "Flores fr 65 mauve",
    rgb: {
      r: 144,
      g: 120,
      b: 121,
    },
  },
  {
    article_number: "1FLORE01080",
    color: "80 brown",
    fabric_uuid: "VADAIN-70029539-1FLORE01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01080&h=500&w=500&type=live",
    name: "Flores fr 80 brown",
    rgb: {
      r: 133,
      g: 110,
      b: 91,
    },
  },
  {
    article_number: "1FLORE01082",
    color: "82 bronze",
    fabric_uuid: "VADAIN-70029521-1FLORE01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01082&h=500&w=500&type=live",
    name: "Flores fr 82 bronze",
    rgb: {
      r: 159,
      g: 111,
      b: 50,
    },
  },
  {
    article_number: "1FLORE01083",
    color: "83 otter",
    fabric_uuid: "VADAIN-70029531-1FLORE01083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01083&h=500&w=500&type=live",
    name: "Flores fr 83 otter",
    rgb: {
      r: 115,
      g: 98,
      b: 82,
    },
  },
  {
    article_number: "1FLORE01086",
    color: "86 taupe",
    fabric_uuid: "VADAIN-70029512-1FLORE01086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01086&h=500&w=500&type=live",
    name: "Flores fr 86 taupe",
    rgb: {
      r: 180,
      g: 164,
      b: 150,
    },
  },
  {
    article_number: "1FLORE01090",
    color: "90 medium grey",
    fabric_uuid: "VADAIN-70029524-1FLORE01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01090&h=500&w=500&type=live",
    name: "Flores fr 90 medium grey",
    rgb: {
      r: 133,
      g: 135,
      b: 134,
    },
  },
  {
    article_number: "1FLORE01091",
    color: "91 rainy day",
    fabric_uuid: "VADAIN-70029526-1FLORE01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01091&h=500&w=500&type=live",
    name: "Flores fr 91 rainy day",
    rgb: {
      r: 193,
      g: 183,
      b: 173,
    },
  },
  {
    article_number: "1FLORE01092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70029515-1FLORE01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01092&h=500&w=500&type=live",
    name: "Flores fr 92 silver",
    rgb: {
      r: 178,
      g: 176,
      b: 173,
    },
  },
  {
    article_number: "1FLORE01093",
    color: "93 zinc",
    fabric_uuid: "VADAIN-70029519-1FLORE01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01093&h=500&w=500&type=live",
    name: "Flores fr 93 zinc",
    rgb: {
      r: 165,
      g: 161,
      b: 156,
    },
  },
  {
    article_number: "1FLORE01095",
    color: "95 black",
    fabric_uuid: "VADAIN-70029537-1FLORE01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01095&h=500&w=500&type=live",
    name: "Flores fr 95 black",
    rgb: {
      r: 58,
      g: 58,
      b: 58,
    },
  },
  {
    article_number: "1FLORE01096",
    color: "96 pewter",
    fabric_uuid: "VADAIN-70029510-1FLORE01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FLORE01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FLORE01096&h=500&w=500&type=live",
    name: "Flores fr 96 pewter",
    rgb: {
      r: 87,
      g: 89,
      b: 88,
    },
  },
  {
    article_number: "1FOLL01011",
    color: "11 natural",
    fabric_uuid: "VADAIN-70018430-1FOLL01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOLL01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOLL01011&h=500&w=500&type=live",
    name: "Follow 11 natural",
    rgb: {
      r: 175,
      g: 162,
      b: 147,
    },
  },
  {
    article_number: "1FOLL01082",
    color: "82 autumn",
    fabric_uuid: "VADAIN-70018431-1FOLL01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOLL01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOLL01082&h=500&w=500&type=live",
    name: "Follow 82 autumn",
    rgb: {
      r: 143,
      g: 133,
      b: 121,
    },
  },
  {
    article_number: "1FOLL01090",
    color: "90 hurricane",
    fabric_uuid: "VADAIN-70018432-1FOLL01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOLL01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOLL01090&h=500&w=500&type=live",
    name: "Follow 90 hurricane",
    rgb: {
      r: 135,
      g: 127,
      b: 119,
    },
  },
  {
    article_number: "1FOLL01092",
    color: "92 dust",
    fabric_uuid: "VADAIN-70018433-1FOLL01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOLL01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOLL01092&h=500&w=500&type=live",
    name: "Follow 92 dust",
    rgb: {
      r: 149,
      g: 142,
      b: 132,
    },
  },
  {
    article_number: "1FORMA2300",
    color: "300 antique green",
    fabric_uuid: "VADAIN-70034021-1FORMA2300",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORMA2300.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORMA2300&h=500&w=500&type=live",
    name: "Forma 300 antique green",
    rgb: {
      r: 182,
      g: 181,
      b: 176,
    },
  },
  {
    article_number: "1FORMA2460",
    color: "460 dusty pink",
    fabric_uuid: "VADAIN-70034019-1FORMA2460",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORMA2460.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORMA2460&h=500&w=500&type=live",
    name: "Forma 460 dusty pink",
    rgb: {
      r: 179,
      g: 177,
      b: 173,
    },
  },
  {
    article_number: "1FORMA2920",
    color: "920 silver",
    fabric_uuid: "VADAIN-70034020-1FORMA2920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORMA2920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORMA2920&h=500&w=500&type=live",
    name: "Forma 920 silver",
    rgb: {
      r: 176,
      g: 175,
      b: 170,
    },
  },
  {
    article_number: "1FORTU2100",
    color: "100 off white",
    fabric_uuid: "VADAIN-70034023-1FORTU2100",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORTU2100.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORTU2100&h=500&w=500&type=live",
    name: "Fortuna 100 off white",
    rgb: {
      r: 181,
      g: 180,
      b: 175,
    },
  },
  {
    article_number: "1FORTU2200",
    color: "200 dusty gold",
    fabric_uuid: "VADAIN-70034024-1FORTU2200",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORTU2200.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORTU2200&h=500&w=500&type=live",
    name: "Fortuna 200 dusty gold",
    rgb: {
      r: 174,
      g: 169,
      b: 156,
    },
  },
  {
    article_number: "1FORTU2920",
    color: "920 silver",
    fabric_uuid: "VADAIN-70034022-1FORTU2920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORTU2920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORTU2920&h=500&w=500&type=live",
    name: "Fortuna 920 silver",
    rgb: {
      r: 162,
      g: 162,
      b: 160,
    },
  },
  {
    article_number: "1FORW02010",
    color: "10 wit",
    fabric_uuid: "VADAIN-70044255-1FORW02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02010&h=500&w=500&type=live",
    name: "Forward kh 10 wit",
    rgb: {
      r: 176,
      g: 176,
      b: 174,
    },
  },
  {
    article_number: "1FORW02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70044256-1FORW02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02011&h=500&w=500&type=live",
    name: "Forward kh 11 off white",
    rgb: {
      r: 172,
      g: 172,
      b: 172,
    },
  },
  {
    article_number: "1FORW02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70044257-1FORW02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02015&h=500&w=500&type=live",
    name: "Forward kh 15 cream",
    rgb: {
      r: 176,
      g: 176,
      b: 168,
    },
  },
  {
    article_number: "1FORW02016",
    color: "16 almond",
    fabric_uuid: "VADAIN-70044258-1FORW02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02016&h=500&w=500&type=live",
    name: "Forward kh 16 almond",
    rgb: {
      r: 170,
      g: 166,
      b: 156,
    },
  },
  {
    article_number: "1FORW02017",
    color: "17 shell",
    fabric_uuid: "VADAIN-70044259-1FORW02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02017&h=500&w=500&type=live",
    name: "Forward kh 17 shell",
    rgb: {
      r: 170,
      g: 171,
      b: 165,
    },
  },
  {
    article_number: "1FORW02020",
    color: "20 vanillia",
    fabric_uuid: "VADAIN-70044260-1FORW02020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02020&h=500&w=500&type=live",
    name: "Forward kh 20 vanillia",
    rgb: {
      r: 175,
      g: 174,
      b: 162,
    },
  },
  {
    article_number: "1FORW02035",
    color: "35 mint",
    fabric_uuid: "VADAIN-70044094-1FORW02035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02035&h=500&w=500&type=live",
    name: "Forward kh 35 mint",
    rgb: {
      r: 159,
      g: 167,
      b: 168,
    },
  },
  {
    article_number: "1FORW02044",
    color: "44 rose water",
    fabric_uuid: "VADAIN-70044261-1FORW02044",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02044.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02044&h=500&w=500&type=live",
    name: "Forward kh 44 rose water",
    rgb: {
      r: 173,
      g: 167,
      b: 162,
    },
  },
  {
    article_number: "1FORW02051",
    color: "51 sky",
    fabric_uuid: "VADAIN-70044262-1FORW02051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02051&h=500&w=500&type=live",
    name: "Forward kh 51 sky",
    rgb: {
      r: 162,
      g: 164,
      b: 163,
    },
  },
  {
    article_number: "1FORW02063",
    color: "63 lilac",
    fabric_uuid: "VADAIN-70044263-1FORW02063",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FORW02063.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02063&h=500&w=500&type=live",
    name: "Forward kh 63 lilac",
    rgb: {
      r: 168,
      g: 160,
      b: 167,
    },
  },
  {
    article_number: "1FORW02091",
    color: "91 cloudy",
    fabric_uuid: "VADAIN-70044264-1FORW02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/NOIMAGE.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02091&h=500&w=500&type=live",
    name: "Forward kh 91 cloudy",
    rgb: {
      r: 170,
      g: 169,
      b: 165,
    },
  },
  {
    article_number: "1FORW02092",
    color: "92 seal",
    fabric_uuid: "VADAIN-70044265-1FORW02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/NOIMAGE.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FORW02092&h=500&w=500&type=live",
    name: "Forward kh 92 seal",
    rgb: {
      r: 169,
      g: 165,
      b: 162,
    },
  },
  {
    article_number: "1FOST02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70037646-1FOST02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02011&h=500&w=500&type=live",
    name: "Foster 11 off white",
    rgb: {
      r: 196,
      g: 196,
      b: 194,
    },
  },
  {
    article_number: "1FOST02013",
    color: "13 oyster",
    fabric_uuid: "VADAIN-70037649-1FOST02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02013&h=500&w=500&type=live",
    name: "Foster 13 oyster",
    rgb: {
      r: 158,
      g: 149,
      b: 145,
    },
  },
  {
    article_number: "1FOST02014",
    color: "14 mist",
    fabric_uuid: "VADAIN-70037647-1FOST02014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02014&h=500&w=500&type=live",
    name: "Foster 14 mist",
    rgb: {
      r: 172,
      g: 165,
      b: 156,
    },
  },
  {
    article_number: "1FOST02015",
    color: "15 sand",
    fabric_uuid: "VADAIN-70037648-1FOST02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02015&h=500&w=500&type=live",
    name: "Foster 15 sand",
    rgb: {
      r: 184,
      g: 174,
      b: 158,
    },
  },
  {
    article_number: "1FOST02016",
    color: "16 oatmeal",
    fabric_uuid: "VADAIN-70037650-1FOST02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02016&h=500&w=500&type=live",
    name: "Foster 16 oatmeal",
    rgb: {
      r: 173,
      g: 156,
      b: 144,
    },
  },
  {
    article_number: "1FOST02017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70037651-1FOST02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02017&h=500&w=500&type=live",
    name: "Foster 17 linen",
    rgb: {
      r: 160,
      g: 145,
      b: 129,
    },
  },
  {
    article_number: "1FOST02018",
    color: "18 pebble",
    fabric_uuid: "VADAIN-70037652-1FOST02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02018&h=500&w=500&type=live",
    name: "Foster 18 pebble",
    rgb: {
      r: 166,
      g: 157,
      b: 148,
    },
  },
  {
    article_number: "1FOST02031",
    color: "31 antique green",
    fabric_uuid: "VADAIN-70037653-1FOST02031",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02031.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02031&h=500&w=500&type=live",
    name: "Foster 31 antique green",
    rgb: {
      r: 164,
      g: 158,
      b: 144,
    },
  },
  {
    article_number: "1FOST02043",
    color: "43 powder",
    fabric_uuid: "VADAIN-70037654-1FOST02043",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02043.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02043&h=500&w=500&type=live",
    name: "Foster 43 powder",
    rgb: {
      r: 178,
      g: 164,
      b: 157,
    },
  },
  {
    article_number: "1FOST02045",
    color: "45 dusty pink",
    fabric_uuid: "VADAIN-70037655-1FOST02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02045&h=500&w=500&type=live",
    name: "Foster 45 dusty pink",
    rgb: {
      r: 163,
      g: 142,
      b: 138,
    },
  },
  {
    article_number: "1FOST02046",
    color: "46 rose copper",
    fabric_uuid: "VADAIN-70037656-1FOST02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02046&h=500&w=500&type=live",
    name: "Foster 46 rose copper",
    rgb: {
      r: 160,
      g: 138,
      b: 127,
    },
  },
  {
    article_number: "1FOST02048",
    color: "48 platinum",
    fabric_uuid: "VADAIN-70037657-1FOST02048",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02048.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02048&h=500&w=500&type=live",
    name: "Foster 48 platinum",
    rgb: {
      r: 157,
      g: 146,
      b: 140,
    },
  },
  {
    article_number: "1FOST02051",
    color: "51 lake",
    fabric_uuid: "VADAIN-70037658-1FOST02051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02051&h=500&w=500&type=live",
    name: "Foster 51 lake",
    rgb: {
      r: 141,
      g: 143,
      b: 146,
    },
  },
  {
    article_number: "1FOST02053",
    color: "53 sea fog",
    fabric_uuid: "VADAIN-70037659-1FOST02053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02053&h=500&w=500&type=live",
    name: "Foster 53 sea fog",
    rgb: {
      r: 155,
      g: 162,
      b: 162,
    },
  },
  {
    article_number: "1FOST02055",
    color: "55 navy",
    fabric_uuid: "VADAIN-70037660-1FOST02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02055&h=500&w=500&type=live",
    name: "Foster 55 navy",
    rgb: {
      r: 110,
      g: 109,
      b: 116,
    },
  },
  {
    article_number: "1FOST02057",
    color: "57 ocean",
    fabric_uuid: "VADAIN-70037661-1FOST02057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02057&h=500&w=500&type=live",
    name: "Foster 57 ocean",
    rgb: {
      r: 135,
      g: 145,
      b: 143,
    },
  },
  {
    article_number: "1FOST02086",
    color: "86 moonstone",
    fabric_uuid: "VADAIN-70037662-1FOST02086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02086&h=500&w=500&type=live",
    name: "Foster 86 moonstone",
    rgb: {
      r: 158,
      g: 156,
      b: 149,
    },
  },
  {
    article_number: "1FOST02090",
    color: "90 silver",
    fabric_uuid: "VADAIN-70037663-1FOST02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02090&h=500&w=500&type=live",
    name: "Foster 90 silver",
    rgb: {
      r: 166,
      g: 161,
      b: 157,
    },
  },
  {
    article_number: "1FOST02091",
    color: "91 ice",
    fabric_uuid: "VADAIN-70037664-1FOST02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02091&h=500&w=500&type=live",
    name: "Foster 91 ice",
    rgb: {
      r: 182,
      g: 182,
      b: 181,
    },
  },
  {
    article_number: "1FOST02093",
    color: "93 elephant",
    fabric_uuid: "VADAIN-70037665-1FOST02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02093&h=500&w=500&type=live",
    name: "Foster 93 elephant",
    rgb: {
      r: 139,
      g: 132,
      b: 127,
    },
  },
  {
    article_number: "1FOST02094",
    color: "94 metal",
    fabric_uuid: "VADAIN-70037666-1FOST02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02094&h=500&w=500&type=live",
    name: "Foster 94 metal",
    rgb: {
      r: 126,
      g: 122,
      b: 123,
    },
  },
  {
    article_number: "1FOST02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70037667-1FOST02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FOST02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FOST02095&h=500&w=500&type=live",
    name: "Foster 95 black",
    rgb: {
      r: 118,
      g: 113,
      b: 112,
    },
  },
  {
    article_number: "1FRIDA02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70030103-1FRIDA02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02011&h=500&w=500&type=live",
    name: "Friday kh 11 off white",
    rgb: {
      r: 218,
      g: 218,
      b: 216,
    },
  },
  {
    article_number: "1FRIDA02012",
    color: "12 almond",
    fabric_uuid: "VADAIN-70030121-1FRIDA02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02012&h=500&w=500&type=live",
    name: "Friday kh 12 almond",
    rgb: {
      r: 202,
      g: 194,
      b: 181,
    },
  },
  {
    article_number: "1FRIDA02013",
    color: "13 beach",
    fabric_uuid: "VADAIN-70030125-1FRIDA02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02013&h=500&w=500&type=live",
    name: "Friday kh 13 beach",
    rgb: {
      r: 201,
      g: 188,
      b: 166,
    },
  },
  {
    article_number: "1FRIDA02014",
    color: "14 light linen",
    fabric_uuid: "VADAIN-70030119-1FRIDA02014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02014&h=500&w=500&type=live",
    name: "Friday kh 14 light linen",
    rgb: {
      r: 182,
      g: 169,
      b: 154,
    },
  },
  {
    article_number: "1FRIDA02015",
    color: "15 latte",
    fabric_uuid: "VADAIN-70030128-1FRIDA02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02015&h=500&w=500&type=live",
    name: "Friday kh 15 latte",
    rgb: {
      r: 187,
      g: 164,
      b: 138,
    },
  },
  {
    article_number: "1FRIDA02016",
    color: "16 pearl",
    fabric_uuid: "VADAIN-70030122-1FRIDA02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02016&h=500&w=500&type=live",
    name: "Friday kh 16 pearl",
    rgb: {
      r: 179,
      g: 170,
      b: 165,
    },
  },
  {
    article_number: "1FRIDA02017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70030126-1FRIDA02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02017&h=500&w=500&type=live",
    name: "Friday kh 17 linen",
    rgb: {
      r: 168,
      g: 159,
      b: 144,
    },
  },
  {
    article_number: "1FRIDA02020",
    color: "20 ochre",
    fabric_uuid: "VADAIN-70030127-1FRIDA02020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02020&h=500&w=500&type=live",
    name: "Friday kh 20 ochre",
    rgb: {
      r: 186,
      g: 143,
      b: 83,
    },
  },
  {
    article_number: "1FRIDA02025",
    color: "25 spiced honey",
    fabric_uuid: "VADAIN-70030123-1FRIDA02025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02025&h=500&w=500&type=live",
    name: "Friday kh 25 spiced honey",
    rgb: {
      r: 166,
      g: 132,
      b: 90,
    },
  },
  {
    article_number: "1FRIDA02027",
    color: "27 brick",
    fabric_uuid: "VADAIN-70030124-1FRIDA02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02027&h=500&w=500&type=live",
    name: "Friday kh 27 brick",
    rgb: {
      r: 170,
      g: 110,
      b: 85,
    },
  },
  {
    article_number: "1FRIDA02030",
    color: "30 linden green",
    fabric_uuid: "VADAIN-70030120-1FRIDA02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02030&h=500&w=500&type=live",
    name: "Friday kh 30 linden green",
    rgb: {
      r: 166,
      g: 151,
      b: 118,
    },
  },
  {
    article_number: "1FRIDA02037",
    color: "37 moss",
    fabric_uuid: "VADAIN-70030104-1FRIDA02037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02037&h=500&w=500&type=live",
    name: "Friday kh 37 moss",
    rgb: {
      r: 140,
      g: 139,
      b: 117,
    },
  },
  {
    article_number: "1FRIDA02045",
    color: "45 blush",
    fabric_uuid: "VADAIN-70030105-1FRIDA02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02045&h=500&w=500&type=live",
    name: "Friday kh 45 blush",
    rgb: {
      r: 188,
      g: 166,
      b: 164,
    },
  },
  {
    article_number: "1FRIDA02046",
    color: "46 blossom",
    fabric_uuid: "VADAIN-70030106-1FRIDA02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02046&h=500&w=500&type=live",
    name: "Friday kh 46 blossom",
    rgb: {
      r: 180,
      g: 126,
      b: 124,
    },
  },
  {
    article_number: "1FRIDA02047",
    color: "47 mahogany",
    fabric_uuid: "VADAIN-70030107-1FRIDA02047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02047&h=500&w=500&type=live",
    name: "Friday kh 47 mahogany",
    rgb: {
      r: 161,
      g: 99,
      b: 91,
    },
  },
  {
    article_number: "1FRIDA02048",
    color: "48 bordeaux",
    fabric_uuid: "VADAIN-70030108-1FRIDA02048",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02048.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02048&h=500&w=500&type=live",
    name: "Friday kh 48 bordeaux",
    rgb: {
      r: 132,
      g: 82,
      b: 79,
    },
  },
  {
    article_number: "1FRIDA02051",
    color: "51 light blue",
    fabric_uuid: "VADAIN-70030109-1FRIDA02051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02051&h=500&w=500&type=live",
    name: "Friday kh 51 light blue",
    rgb: {
      r: 166,
      g: 172,
      b: 174,
    },
  },
  {
    article_number: "1FRIDA02052",
    color: "52 storm",
    fabric_uuid: "VADAIN-70030110-1FRIDA02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02052&h=500&w=500&type=live",
    name: "Friday kh 52 storm",
    rgb: {
      r: 124,
      g: 131,
      b: 129,
    },
  },
  {
    article_number: "1FRIDA02053",
    color: "53 sky",
    fabric_uuid: "VADAIN-70030111-1FRIDA02053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02053&h=500&w=500&type=live",
    name: "Friday kh 53 sky",
    rgb: {
      r: 130,
      g: 148,
      b: 161,
    },
  },
  {
    article_number: "1FRIDA02057",
    color: "57 denim",
    fabric_uuid: "VADAIN-70030112-1FRIDA02057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02057&h=500&w=500&type=live",
    name: "Friday kh 57 denim",
    rgb: {
      r: 89,
      g: 98,
      b: 109,
    },
  },
  {
    article_number: "1FRIDA02082",
    color: "82 cinnamon",
    fabric_uuid: "VADAIN-70030113-1FRIDA02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02082&h=500&w=500&type=live",
    name: "Friday kh 82 cinnamon",
    rgb: {
      r: 145,
      g: 113,
      b: 87,
    },
  },
  {
    article_number: "1FRIDA02091",
    color: "91 melee grey",
    fabric_uuid: "VADAIN-70030114-1FRIDA02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02091&h=500&w=500&type=live",
    name: "Friday kh 91 melee grey",
    rgb: {
      r: 136,
      g: 136,
      b: 136,
    },
  },
  {
    article_number: "1FRIDA02092",
    color: "92 light grey",
    fabric_uuid: "VADAIN-70030115-1FRIDA02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02092&h=500&w=500&type=live",
    name: "Friday kh 92 light grey",
    rgb: {
      r: 189,
      g: 189,
      b: 191,
    },
  },
  {
    article_number: "1FRIDA02093",
    color: "93 fog",
    fabric_uuid: "VADAIN-70030116-1FRIDA02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02093&h=500&w=500&type=live",
    name: "Friday kh 93 fog",
    rgb: {
      r: 157,
      g: 157,
      b: 157,
    },
  },
  {
    article_number: "1FRIDA02094",
    color: "94 dark grey",
    fabric_uuid: "VADAIN-70030117-1FRIDA02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02094&h=500&w=500&type=live",
    name: "Friday kh 94 dark grey",
    rgb: {
      r: 108,
      g: 110,
      b: 113,
    },
  },
  {
    article_number: "1FRIDA02097",
    color: "97 ebony",
    fabric_uuid: "VADAIN-70030118-1FRIDA02097",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FRIDA02097.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FRIDA02097&h=500&w=500&type=live",
    name: "Friday kh 97 ebony",
    rgb: {
      r: 89,
      g: 80,
      b: 75,
    },
  },
  {
    article_number: "1FROS02012",
    color: "12 pebble",
    fabric_uuid: "VADAIN-70035030-1FROS02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02012&h=500&w=500&type=live",
    name: "Frosty velvet 12 pebble",
    rgb: {
      r: 186,
      g: 179,
      b: 171,
    },
  },
  {
    article_number: "1FROS02015",
    color: "15 cream",
    fabric_uuid: "VADAIN-70035011-1FROS02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02015&h=500&w=500&type=live",
    name: "Frosty velvet 15 cream",
    rgb: {
      r: 213,
      g: 206,
      b: 192,
    },
  },
  {
    article_number: "1FROS02016",
    color: "16 antique white",
    fabric_uuid: "VADAIN-70035023-1FROS02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02016&h=500&w=500&type=live",
    name: "Frosty velvet 16 antique white",
    rgb: {
      r: 191,
      g: 183,
      b: 170,
    },
  },
  {
    article_number: "1FROS02017",
    color: "17 greige",
    fabric_uuid: "VADAIN-70035027-1FROS02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02017&h=500&w=500&type=live",
    name: "Frosty velvet 17 greige",
    rgb: {
      r: 141,
      g: 131,
      b: 119,
    },
  },
  {
    article_number: "1FROS02018",
    color: "18 beach",
    fabric_uuid: "VADAIN-70035014-1FROS02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02018&h=500&w=500&type=live",
    name: "Frosty velvet 18 beach",
    rgb: {
      r: 179,
      g: 170,
      b: 153,
    },
  },
  {
    article_number: "1FROS02019",
    color: "19 sand",
    fabric_uuid: "VADAIN-70035041-1FROS02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02019&h=500&w=500&type=live",
    name: "Frosty velvet 19 sand",
    rgb: {
      r: 134,
      g: 119,
      b: 104,
    },
  },
  {
    article_number: "1FROS02022",
    color: "22 yellow",
    fabric_uuid: "VADAIN-70035018-1FROS02022",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02022.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02022&h=500&w=500&type=live",
    name: "Frosty velvet 22 yellow",
    rgb: {
      r: 190,
      g: 157,
      b: 63,
    },
  },
  {
    article_number: "1FROS02024",
    color: "24 honey dew",
    fabric_uuid: "VADAIN-70035039-1FROS02024",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02024.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02024&h=500&w=500&type=live",
    name: "Frosty velvet 24 honey dew",
    rgb: {
      r: 181,
      g: 176,
      b: 166,
    },
  },
  {
    article_number: "1FROS02027",
    color: "27 salmon",
    fabric_uuid: "VADAIN-70035015-1FROS02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02027&h=500&w=500&type=live",
    name: "Frosty velvet 27 salmon",
    rgb: {
      r: 154,
      g: 80,
      b: 67,
    },
  },
  {
    article_number: "1FROS02028",
    color: "28 sienna",
    fabric_uuid: "VADAIN-70035019-1FROS02028",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02028.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02028&h=500&w=500&type=live",
    name: "Frosty velvet 28 sienna",
    rgb: {
      r: 133,
      g: 74,
      b: 47,
    },
  },
  {
    article_number: "1FROS02029",
    color: "29 curry",
    fabric_uuid: "VADAIN-70035010-1FROS02029",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02029.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02029&h=500&w=500&type=live",
    name: "Frosty velvet 29 curry",
    rgb: {
      r: 129,
      g: 99,
      b: 30,
    },
  },
  {
    article_number: "1FROS02030",
    color: "30 emerald",
    fabric_uuid: "VADAIN-70035022-1FROS02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02030&h=500&w=500&type=live",
    name: "Frosty velvet 30 emerald",
    rgb: {
      r: 38,
      g: 71,
      b: 50,
    },
  },
  {
    article_number: "1FROS02032",
    color: "32 mint",
    fabric_uuid: "VADAIN-70035034-1FROS02032",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02032.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02032&h=500&w=500&type=live",
    name: "Frosty velvet 32 mint",
    rgb: {
      r: 150,
      g: 163,
      b: 140,
    },
  },
  {
    article_number: "1FROS02037",
    color: "37 pine tree",
    fabric_uuid: "VADAIN-70035016-1FROS02037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02037&h=500&w=500&type=live",
    name: "Frosty velvet 37 pine tree",
    rgb: {
      r: 34,
      g: 59,
      b: 55,
    },
  },
  {
    article_number: "1FROS02039",
    color: "39 dark green",
    fabric_uuid: "VADAIN-70035026-1FROS02039",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02039.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02039&h=500&w=500&type=live",
    name: "Frosty velvet 39 dark green",
    rgb: {
      r: 28,
      g: 43,
      b: 37,
    },
  },
  {
    article_number: "1FROS02043",
    color: "43 red",
    fabric_uuid: "VADAIN-70035040-1FROS02043",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02043.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02043&h=500&w=500&type=live",
    name: "Frosty velvet 43 red",
    rgb: {
      r: 116,
      g: 15,
      b: 34,
    },
  },
  {
    article_number: "1FROS02045",
    color: "45 dark pink",
    fabric_uuid: "VADAIN-70035012-1FROS02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02045&h=500&w=500&type=live",
    name: "Frosty velvet 45 dark pink",
    rgb: {
      r: 138,
      g: 74,
      b: 71,
    },
  },
  {
    article_number: "1FROS02046",
    color: "460 old pink",
    fabric_uuid: "VADAIN-70035031-1FROS02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02046&h=500&w=500&type=live",
    name: "Frosty velvet 46 old pink",
    rgb: {
      r: 165,
      g: 110,
      b: 103,
    },
  },
  {
    article_number: "1FROS02051",
    color: "51 sky blue",
    fabric_uuid: "VADAIN-70035038-1FROS02051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02051&h=500&w=500&type=live",
    name: "Frosty velvet 51 sky blue",
    rgb: {
      r: 148,
      g: 158,
      b: 165,
    },
  },
  {
    article_number: "1FROS02052",
    color: "52 navy",
    fabric_uuid: "VADAIN-70035017-1FROS02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02052&h=500&w=500&type=live",
    name: "Frosty velvet 52 navy",
    rgb: {
      r: 46,
      g: 50,
      b: 64,
    },
  },
  {
    article_number: "1FROS02053",
    color: "53 steal blue",
    fabric_uuid: "VADAIN-70035029-1FROS02053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02053&h=500&w=500&type=live",
    name: "Frosty velvet 53 steal blue",
    rgb: {
      r: 82,
      g: 104,
      b: 117,
    },
  },
  {
    article_number: "1FROS02054",
    color: "54 duck egg",
    fabric_uuid: "VADAIN-70035042-1FROS02054",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02054.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02054&h=500&w=500&type=live",
    name: "Frosty velvet 54 duck egg",
    rgb: {
      r: 99,
      g: 136,
      b: 135,
    },
  },
  {
    article_number: "1FROS02055",
    color: "55 stormy blue",
    fabric_uuid: "VADAIN-70035021-1FROS02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02055&h=500&w=500&type=live",
    name: "Frosty velvet 55 stormy blue",
    rgb: {
      r: 61,
      g: 77,
      b: 76,
    },
  },
  {
    article_number: "1FROS02056",
    color: "56 dark petrol",
    fabric_uuid: "VADAIN-70035033-1FROS02056",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02056.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02056&h=500&w=500&type=live",
    name: "Frosty velvet 56 dark petrol",
    rgb: {
      r: 61,
      g: 72,
      b: 75,
    },
  },
  {
    article_number: "1FROS02060",
    color: "60 lilac",
    fabric_uuid: "VADAIN-70035035-1FROS02060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02060&h=500&w=500&type=live",
    name: "Frosty velvet 60 lilac",
    rgb: {
      r: 157,
      g: 141,
      b: 142,
    },
  },
  {
    article_number: "1FROS02061",
    color: "61lavender blush",
    fabric_uuid: "VADAIN-70035013-1FROS02061",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02061.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02061&h=500&w=500&type=live",
    name: "Frosty velvet 61 lavender blus",
    rgb: {
      r: 139,
      g: 107,
      b: 100,
    },
  },
  {
    article_number: "1FROS02065",
    color: "65 medium purple",
    fabric_uuid: "VADAIN-70035032-1FROS02065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02065&h=500&w=500&type=live",
    name: "Frosty velvet 65 medium purple",
    rgb: {
      r: 98,
      g: 73,
      b: 82,
    },
  },
  {
    article_number: "1FROS02067",
    color: "67 eggplant",
    fabric_uuid: "VADAIN-70035036-1FROS02067",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02067.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02067&h=500&w=500&type=live",
    name: "Frosty velvet 67 eggplant",
    rgb: {
      r: 64,
      g: 28,
      b: 40,
    },
  },
  {
    article_number: "1FROS02080",
    color: "80 coffee latte",
    fabric_uuid: "VADAIN-70035020-1FROS02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02080&h=500&w=500&type=live",
    name: "Frosty velvet 80 coffee latte",
    rgb: {
      r: 104,
      g: 87,
      b: 73,
    },
  },
  {
    article_number: "1FROS02089",
    color: "89 elephant",
    fabric_uuid: "VADAIN-70035024-1FROS02089",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02089.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02089&h=500&w=500&type=live",
    name: "Frosty velvet 89 elephant",
    rgb: {
      r: 89,
      g: 80,
      b: 73,
    },
  },
  {
    article_number: "1FROS02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70035037-1FROS02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02092&h=500&w=500&type=live",
    name: "Frosty velvet 92 silver",
    rgb: {
      r: 128,
      g: 127,
      b: 125,
    },
  },
  {
    article_number: "1FROS02093",
    color: "93 warm grey",
    fabric_uuid: "VADAIN-70035028-1FROS02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02093&h=500&w=500&type=live",
    name: "Frosty velvet 93 warm grey",
    rgb: {
      r: 76,
      g: 74,
      b: 74,
    },
  },
  {
    article_number: "1FROS02096",
    color: "96 anthracite",
    fabric_uuid: "VADAIN-70035025-1FROS02096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1FROS02096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1FROS02096&h=500&w=500&type=live",
    name: "Frosty velvet 96 anthracite",
    rgb: {
      r: 44,
      g: 44,
      b: 45,
    },
  },
  {
    article_number: "1gambi2520",
    color: "520A",
    fabric_uuid: "VADAIN-70046456-1gambi2520",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/NOIMAGE.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1gambi2520&h=500&w=500&type=live",
    name: "Gambit KH 520 ocean",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1GIFT002035",
    color: "35 spring",
    fabric_uuid: "VADAIN-70029716-1GIFT002035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GIFT002035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GIFT002035&h=500&w=500&type=live",
    name: "Gift kh 35 spring",
    rgb: {
      r: 150,
      g: 148,
      b: 143,
    },
  },
  {
    article_number: "1GIFT002045",
    color: "45 summer",
    fabric_uuid: "VADAIN-70029717-1GIFT002045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GIFT002045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GIFT002045&h=500&w=500&type=live",
    name: "Gift kh 45 summer",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1GIFT002085",
    color: "85 autumn",
    fabric_uuid: "VADAIN-70029715-1GIFT002085",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GIFT002085.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GIFT002085&h=500&w=500&type=live",
    name: "Gift kh 85 autumn",
    rgb: {
      r: 149,
      g: 144,
      b: 140,
    },
  },
  {
    article_number: "1GING02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70012216-1GING02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02010&h=500&w=500&type=live",
    name: "Ginger kh 10 white",
    rgb: {
      r: 177,
      g: 177,
      b: 179,
    },
  },
  {
    article_number: "1GING02012",
    color: "12 cream",
    fabric_uuid: "VADAIN-70012217-1GING02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02012&h=500&w=500&type=live",
    name: "Ginger kh 12 cream",
    rgb: {
      r: 166,
      g: 166,
      b: 164,
    },
  },
  {
    article_number: "1GING02013",
    color: "13 sea breeze",
    fabric_uuid: "VADAIN-70012218-1GING02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02013&h=500&w=500&type=live",
    name: "Ginger kh 13 sea breeze",
    rgb: {
      r: 160,
      g: 159,
      b: 156,
    },
  },
  {
    article_number: "1GING02015",
    color: "15 beige",
    fabric_uuid: "VADAIN-70012219-1GING02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02015&h=500&w=500&type=live",
    name: "Ginger kh 15 beige",
    rgb: {
      r: 174,
      g: 168,
      b: 158,
    },
  },
  {
    article_number: "1GING02016",
    color: "16 mushroom",
    fabric_uuid: "VADAIN-70012220-1GING02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02016&h=500&w=500&type=live",
    name: "Ginger kh 16 mushroom",
    rgb: {
      r: 149,
      g: 145,
      b: 139,
    },
  },
  {
    article_number: "1GING02017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-70012221-1GING02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02017&h=500&w=500&type=live",
    name: "Ginger kh 17 linnen",
    rgb: {
      r: 142,
      g: 134,
      b: 125,
    },
  },
  {
    article_number: "1GING02018",
    color: "18 chinchilla",
    fabric_uuid: "VADAIN-70012222-1GING02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02018&h=500&w=500&type=live",
    name: "Ginger kh 18 chinchilla",
    rgb: {
      r: 132,
      g: 128,
      b: 123,
    },
  },
  {
    article_number: "1GING02086",
    color: "86 taupe",
    fabric_uuid: "VADAIN-70012223-1GING02086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02086&h=500&w=500&type=live",
    name: "Ginger kh 86 taupe",
    rgb: {
      r: 123,
      g: 119,
      b: 110,
    },
  },
  {
    article_number: "1GING02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70012224-1GING02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02090&h=500&w=500&type=live",
    name: "Ginger kh 90 grey",
    rgb: {
      r: 129,
      g: 129,
      b: 127,
    },
  },
  {
    article_number: "1GING02091",
    color: "91 frost",
    fabric_uuid: "VADAIN-70012159-1GING02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02091&h=500&w=500&type=live",
    name: "Ginger kh 91 frost",
    rgb: {
      r: 131,
      g: 135,
      b: 138,
    },
  },
  {
    article_number: "1GING02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70012225-1GING02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02092&h=500&w=500&type=live",
    name: "Ginger kh 92 silver",
    rgb: {
      r: 118,
      g: 118,
      b: 116,
    },
  },
  {
    article_number: "1GING02093",
    color: "93 steel grey",
    fabric_uuid: "VADAIN-70012226-1GING02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GING02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GING02093&h=500&w=500&type=live",
    name: "Ginger kh 93 steel grey",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1GLAM02017",
    color: "LINEN",
    fabric_uuid: "VADAIN-70038968-1GLAM02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02017&h=500&w=500&type=live",
    name: "Glam 17 sand",
    rgb: {
      r: 165,
      g: 155,
      b: 139,
    },
  },
  {
    article_number: "1GLAM02010",
    color: "WHITE",
    fabric_uuid: "VADAIN-70038974-1GLAM02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02010&h=500&w=500&type=live",
    name: "Glam kh 10 white",
    rgb: {
      r: 194,
      g: 194,
      b: 192,
    },
  },
  {
    article_number: "1GLAM02011",
    color: "PEARL",
    fabric_uuid: "VADAIN-70038982-1GLAM02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02011&h=500&w=500&type=live",
    name: "Glam kh 11 pearl",
    rgb: {
      r: 195,
      g: 194,
      b: 189,
    },
  },
  {
    article_number: "1GLAM02012",
    color: "CREAM",
    fabric_uuid: "VADAIN-70038972-1GLAM02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02012&h=500&w=500&type=live",
    name: "Glam kh 12 cream",
    rgb: {
      r: 188,
      g: 188,
      b: 180,
    },
  },
  {
    article_number: "1GLAM02014",
    color: "OYSTER",
    fabric_uuid: "VADAIN-70038983-1GLAM02014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02014&h=500&w=500&type=live",
    name: "Glam kh 14 oyster",
    rgb: {
      r: 179,
      g: 175,
      b: 164,
    },
  },
  {
    article_number: "1GLAM02016",
    color: "SAND",
    fabric_uuid: "VADAIN-70038976-1GLAM02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02016&h=500&w=500&type=live",
    name: "Glam kh 16 linen",
    rgb: {
      r: 162,
      g: 154,
      b: 140,
    },
  },
  {
    article_number: "1GLAM02045",
    color: "LIGHT PINK",
    fabric_uuid: "VADAIN-70038977-1GLAM02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02045&h=500&w=500&type=live",
    name: "Glam kh 45 blush",
    rgb: {
      r: 166,
      g: 147,
      b: 140,
    },
  },
  {
    article_number: "1GLAM02046",
    color: "ROSE COPPER",
    fabric_uuid: "VADAIN-70038971-1GLAM02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02046&h=500&w=500&type=live",
    name: "Glam kh 46 rose copper",
    rgb: {
      r: 159,
      g: 139,
      b: 126,
    },
  },
  {
    article_number: "1GLAM02083",
    color: "TAUPE",
    fabric_uuid: "VADAIN-70038978-1GLAM02083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02083&h=500&w=500&type=live",
    name: "Glam kh 83 taupe",
    rgb: {
      r: 143,
      g: 133,
      b: 123,
    },
  },
  {
    article_number: "1GLAM02090",
    color: "GREY",
    fabric_uuid: "VADAIN-70038979-1GLAM02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02090&h=500&w=500&type=live",
    name: "Glam kh 90 grey",
    rgb: {
      r: 148,
      g: 145,
      b: 138,
    },
  },
  {
    article_number: "1GLAM02091",
    color: "ICE",
    fabric_uuid: "VADAIN-70038975-1GLAM02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02091&h=500&w=500&type=live",
    name: "Glam kh 91 ice",
    rgb: {
      r: 184,
      g: 184,
      b: 176,
    },
  },
  {
    article_number: "1GLAM02092",
    color: "SILVER",
    fabric_uuid: "VADAIN-70038980-1GLAM02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02092&h=500&w=500&type=live",
    name: "Glam kh 92 silver",
    rgb: {
      r: 161,
      g: 160,
      b: 155,
    },
  },
  {
    article_number: "1GLAM02093",
    color: "STEEL",
    fabric_uuid: "VADAIN-70038973-1GLAM02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02093&h=500&w=500&type=live",
    name: "Glam kh 93 steel",
    rgb: {
      r: 132,
      g: 134,
      b: 133,
    },
  },
  {
    article_number: "1GLAM02095",
    color: "BLACK & WHITE",
    fabric_uuid: "VADAIN-70038981-1GLAM02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GLAM02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GLAM02095&h=500&w=500&type=live",
    name: "Glam kh 95 black and white",
    rgb: {
      r: 109,
      g: 109,
      b: 106,
    },
  },
  {
    article_number: "1GROO02010",
    color: "10 wit",
    fabric_uuid: "VADAIN-70020360-1GROO02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02010&h=500&w=500&type=live",
    name: "Groove kh 10 wit",
    rgb: {
      r: 195,
      g: 196,
      b: 203,
    },
  },
  {
    article_number: "1GROO02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-7153-1GROO02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02011&h=500&w=500&type=live",
    name: "Groove kh 11 off white",
    rgb: {
      r: 198,
      g: 198,
      b: 196,
    },
  },
  {
    article_number: "1GROO02012",
    color: "12 beach",
    fabric_uuid: "VADAIN-70018522-1GROO02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02012&h=500&w=500&type=live",
    name: "Groove kh 12 beach",
    rgb: {
      r: 175,
      g: 171,
      b: 161,
    },
  },
  {
    article_number: "1GROO02013",
    color: "13 oester",
    fabric_uuid: "VADAIN-7154-1GROO02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02013&h=500&w=500&type=live",
    name: "Groove kh 13 oester",
    rgb: {
      r: 158,
      g: 154,
      b: 152,
    },
  },
  {
    article_number: "1GROO02014",
    color: "14 natural",
    fabric_uuid: "VADAIN-70018523-1GROO02014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02014&h=500&w=500&type=live",
    name: "Groove kh 14 natural",
    rgb: {
      r: 160,
      g: 152,
      b: 144,
    },
  },
  {
    article_number: "1GROO02016",
    color: "16 vlas",
    fabric_uuid: "VADAIN-70018524-1GROO02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02016&h=500&w=500&type=live",
    name: "Groove kh 16 vlas",
    rgb: {
      r: 158,
      g: 152,
      b: 136,
    },
  },
  {
    article_number: "1GROO02017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-7155-1GROO02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02017&h=500&w=500&type=live",
    name: "Groove kh 17 linnen",
    rgb: {
      r: 149,
      g: 140,
      b: 136,
    },
  },
  {
    article_number: "1GROO02019",
    color: "19 spice",
    fabric_uuid: "VADAIN-7156-1GROO02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02019&h=500&w=500&type=live",
    name: "Groove kh 19 spice",
    rgb: {
      r: 133,
      g: 128,
      b: 126,
    },
  },
  {
    article_number: "1GROO02027",
    color: "27 copper",
    fabric_uuid: "VADAIN-70018525-1GROO02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02027&h=500&w=500&type=live",
    name: "Groove kh 27 copper",
    rgb: {
      r: 149,
      g: 117,
      b: 99,
    },
  },
  {
    article_number: "1GROO02030",
    color: "30 jade",
    fabric_uuid: "VADAIN-17677-1GROO02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02030&h=500&w=500&type=live",
    name: "Groove kh 30 jade",
    rgb: {
      r: 129,
      g: 147,
      b: 142,
    },
  },
  {
    article_number: "1GROO02035",
    color: "35 mint",
    fabric_uuid: "VADAIN-70018527-1GROO02035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02035&h=500&w=500&type=live",
    name: "Groove kh 35 mint",
    rgb: {
      r: 150,
      g: 155,
      b: 149,
    },
  },
  {
    article_number: "1GROO02045",
    color: "45 dusty pink",
    fabric_uuid: "VADAIN-70018526-1GROO02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02045&h=500&w=500&type=live",
    name: "Groove kh 45 dusty pink",
    rgb: {
      r: 184,
      g: 163,
      b: 152,
    },
  },
  {
    article_number: "1GROO02050",
    color: "50 denim blue",
    fabric_uuid: "VADAIN-70018528-1GROO02050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02050&h=500&w=500&type=live",
    name: "Groove kh 50 denim blue",
    rgb: {
      r: 78,
      g: 80,
      b: 95,
    },
  },
  {
    article_number: "1GROO02051",
    color: "51 sky",
    fabric_uuid: "VADAIN-70018529-1GROO02051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02051&h=500&w=500&type=live",
    name: "Groove kh 51 sky",
    rgb: {
      r: 125,
      g: 141,
      b: 148,
    },
  },
  {
    article_number: "1GROO02052",
    color: "52 ocean blue",
    fabric_uuid: "VADAIN-70018530-1GROO02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02052&h=500&w=500&type=live",
    name: "Groove kh 52 ocean blue",
    rgb: {
      r: 104,
      g: 115,
      b: 119,
    },
  },
  {
    article_number: "1GROO02053",
    color: "53 grey blue",
    fabric_uuid: "VADAIN-70018531-1GROO02053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02053&h=500&w=500&type=live",
    name: "Groove kh 53 grey blue",
    rgb: {
      r: 109,
      g: 119,
      b: 133,
    },
  },
  {
    article_number: "1GROO02060",
    color: "60 mauve",
    fabric_uuid: "VADAIN-70018532-1GROO02060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02060&h=500&w=500&type=live",
    name: "Groove kh 60 mauve",
    rgb: {
      r: 152,
      g: 140,
      b: 140,
    },
  },
  {
    article_number: "1GROO02090",
    color: "90 grijs",
    fabric_uuid: "VADAIN-7157-1GROO02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02090&h=500&w=500&type=live",
    name: "Groove kh 90 grijs",
    rgb: {
      r: 105,
      g: 104,
      b: 109,
    },
  },
  {
    article_number: "1GROO02091",
    color: "91 beton",
    fabric_uuid: "VADAIN-70018533-1GROO02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02091&h=500&w=500&type=live",
    name: "Groove kh 91 beton",
    rgb: {
      r: 144,
      g: 142,
      b: 140,
    },
  },
  {
    article_number: "1GROO02092",
    color: "92 zilver",
    fabric_uuid: "VADAIN-7158-1GROO02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02092&h=500&w=500&type=live",
    name: "Groove kh 92 zilver",
    rgb: {
      r: 147,
      g: 148,
      b: 152,
    },
  },
  {
    article_number: "1GROO02093",
    color: "93 pewter",
    fabric_uuid: "VADAIN-70018534-1GROO02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02093&h=500&w=500&type=live",
    name: "Groove kh 93 pewter",
    rgb: {
      r: 130,
      g: 131,
      b: 132,
    },
  },
  {
    article_number: "1GROO02096",
    color: "96 antraciet",
    fabric_uuid: "VADAIN-70021868-1GROO02096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02096&h=500&w=500&type=live",
    name: "Groove kh 96 antraciet",
    rgb: {
      r: 55,
      g: 44,
      b: 50,
    },
  },
  {
    article_number: "1GROO02096",
    color: "96 antraciet",
    fabric_uuid: "VADAIN-70042020-1GROO02096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02096&h=500&w=500&type=live",
    name: "Groove KH 96 antraciet",
    rgb: {
      r: 55,
      g: 44,
      b: 50,
    },
  },
  {
    article_number: "1GROO02097",
    color: "97 dark grey",
    fabric_uuid: "VADAIN-70018535-1GROO02097",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1GROO02097.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1GROO02097&h=500&w=500&type=live",
    name: "Groove kh 97 dark grey",
    rgb: {
      r: 94,
      g: 92,
      b: 94,
    },
  },
  {
    article_number: "1HABI02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70022072-1HABI02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HABI02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HABI02011&h=500&w=500&type=live",
    name: "Habitat kh 11 off white",
    rgb: {
      r: 177,
      g: 177,
      b: 175,
    },
  },
  {
    article_number: "1HABI02016",
    color: "16 natural",
    fabric_uuid: "VADAIN-70022070-1HABI02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HABI02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HABI02016&h=500&w=500&type=live",
    name: "Habitat kh 16 natural",
    rgb: {
      r: 181,
      g: 176,
      b: 168,
    },
  },
  {
    article_number: "1HABI02090",
    color: "90 light grey",
    fabric_uuid: "VADAIN-70022071-1HABI02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HABI02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HABI02090&h=500&w=500&type=live",
    name: "Habitat kh 90 light grey",
    rgb: {
      r: 173,
      g: 174,
      b: 170,
    },
  },
  {
    article_number: "1HARM01010",
    color: "10 white",
    fabric_uuid: "VADAIN-70022019-1HARM01010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01010&h=500&w=500&type=live",
    name: "Harmony 10 white",
    rgb: {
      r: 213,
      g: 214,
      b: 217,
    },
  },
  {
    article_number: "1HARM01011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70022007-1HARM01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01011&h=500&w=500&type=live",
    name: "Harmony 11 off white",
    rgb: {
      r: 219,
      g: 219,
      b: 219,
    },
  },
  {
    article_number: "1HARM01012",
    color: "12 cotton",
    fabric_uuid: "VADAIN-70022013-1HARM01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01012&h=500&w=500&type=live",
    name: "Harmony 12 cotton",
    rgb: {
      r: 204,
      g: 199,
      b: 193,
    },
  },
  {
    article_number: "1HARM01013",
    color: "13 beach",
    fabric_uuid: "VADAIN-70022031-1HARM01013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01013&h=500&w=500&type=live",
    name: "Harmony 13 beach",
    rgb: {
      r: 183,
      g: 176,
      b: 167,
    },
  },
  {
    article_number: "1HARM01015",
    color: "15 sand",
    fabric_uuid: "VADAIN-70022021-1HARM01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01015&h=500&w=500&type=live",
    name: "Harmony 15 sand",
    rgb: {
      r: 172,
      g: 164,
      b: 151,
    },
  },
  {
    article_number: "1HARM01016",
    color: "16 mushroom",
    fabric_uuid: "VADAIN-70022033-1HARM01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01016&h=500&w=500&type=live",
    name: "Harmony 16 mushroom",
    rgb: {
      r: 160,
      g: 148,
      b: 130,
    },
  },
  {
    article_number: "1HARM01017",
    color: "17 oak",
    fabric_uuid: "VADAIN-70022006-1HARM01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01017&h=500&w=500&type=live",
    name: "Harmony 17 oak",
    rgb: {
      r: 154,
      g: 138,
      b: 116,
    },
  },
  {
    article_number: "1HARM01018",
    color: "18 salt and pepper",
    fabric_uuid: "VADAIN-70022003-1HARM01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01018&h=500&w=500&type=live",
    name: "Harmony 18 salt and pepper",
    rgb: {
      r: 157,
      g: 152,
      b: 147,
    },
  },
  {
    article_number: "1HARM01020",
    color: "20 corn",
    fabric_uuid: "VADAIN-70022025-1HARM01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01020&h=500&w=500&type=live",
    name: "Harmony 20 corn",
    rgb: {
      r: 171,
      g: 154,
      b: 108,
    },
  },
  {
    article_number: "1HARM01021",
    color: "21 mustard",
    fabric_uuid: "VADAIN-70022030-1HARM01021",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01021.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01021&h=500&w=500&type=live",
    name: "Harmony 21 mustard",
    rgb: {
      r: 162,
      g: 133,
      b: 87,
    },
  },
  {
    article_number: "1HARM01030",
    color: "30 emerald",
    fabric_uuid: "VADAIN-70022017-1HARM01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01030&h=500&w=500&type=live",
    name: "Harmony 30 emerald",
    rgb: {
      r: 85,
      g: 112,
      b: 116,
    },
  },
  {
    article_number: "1HARM01031",
    color: "31 jade",
    fabric_uuid: "VADAIN-70022023-1HARM01031",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01031.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01031&h=500&w=500&type=live",
    name: "Harmony 31 jade",
    rgb: {
      r: 99,
      g: 121,
      b: 101,
    },
  },
  {
    article_number: "1HARM01032",
    color: "32 moss",
    fabric_uuid: "VADAIN-70022034-1HARM01032",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01032.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01032&h=500&w=500&type=live",
    name: "Harmony 32 moss",
    rgb: {
      r: 132,
      g: 125,
      b: 93,
    },
  },
  {
    article_number: "1HARM01037",
    color: "37 minty",
    fabric_uuid: "VADAIN-70022014-1HARM01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01037&h=500&w=500&type=live",
    name: "Harmony 37 minty",
    rgb: {
      r: 132,
      g: 150,
      b: 150,
    },
  },
  {
    article_number: "1HARM01040",
    color: "40 fire",
    fabric_uuid: "VADAIN-70022026-1HARM01040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01040&h=500&w=500&type=live",
    name: "Harmony 40 fire",
    rgb: {
      r: 161,
      g: 79,
      b: 69,
    },
  },
  {
    article_number: "1HARM01041",
    color: "41 brick",
    fabric_uuid: "VADAIN-70022005-1HARM01041",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01041.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01041&h=500&w=500&type=live",
    name: "Harmony 41 brick",
    rgb: {
      r: 149,
      g: 100,
      b: 85,
    },
  },
  {
    article_number: "1HARM01042",
    color: "42 wine red",
    fabric_uuid: "VADAIN-70022018-1HARM01042",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01042.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01042&h=500&w=500&type=live",
    name: "Harmony 42 wine red",
    rgb: {
      r: 135,
      g: 70,
      b: 72,
    },
  },
  {
    article_number: "1HARM01045",
    color: "45 soft pink",
    fabric_uuid: "VADAIN-70022016-1HARM01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01045&h=500&w=500&type=live",
    name: "Harmony 45 soft pink",
    rgb: {
      r: 201,
      g: 186,
      b: 181,
    },
  },
  {
    article_number: "1HARM01046",
    color: "46 tweed pink",
    fabric_uuid: "VADAIN-70022029-1HARM01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01046&h=500&w=500&type=live",
    name: "Harmony 46 tweed pink",
    rgb: {
      r: 165,
      g: 149,
      b: 147,
    },
  },
  {
    article_number: "1HARM01049",
    color: "49 bright pink",
    fabric_uuid: "VADAIN-70022022-1HARM01049",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01049.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01049&h=500&w=500&type=live",
    name: "Harmony 49 bright pink",
    rgb: {
      r: 162,
      g: 112,
      b: 116,
    },
  },
  {
    article_number: "1HARM01050",
    color: "50 medium blue",
    fabric_uuid: "VADAIN-70022010-1HARM01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01050&h=500&w=500&type=live",
    name: "Harmony 50 medium blue",
    rgb: {
      r: 152,
      g: 157,
      b: 161,
    },
  },
  {
    article_number: "1HARM01053",
    color: "53 aquamarine",
    fabric_uuid: "VADAIN-70022011-1HARM01053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01053&h=500&w=500&type=live",
    name: "Harmony 53 aquamarine",
    rgb: {
      r: 103,
      g: 129,
      b: 138,
    },
  },
  {
    article_number: "1HARM01054",
    color: "54 denim",
    fabric_uuid: "VADAIN-70022015-1HARM01054",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01054.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01054&h=500&w=500&type=live",
    name: "Harmony 54 denim",
    rgb: {
      r: 84,
      g: 108,
      b: 125,
    },
  },
  {
    article_number: "1HARM01057",
    color: "57 dark topaz",
    fabric_uuid: "VADAIN-70022027-1HARM01057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01057&h=500&w=500&type=live",
    name: "Harmony 57 dark topaz",
    rgb: {
      r: 115,
      g: 127,
      b: 130,
    },
  },
  {
    article_number: "1HARM01060",
    color: "60 purple",
    fabric_uuid: "VADAIN-70022054-1HARM01060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01060&h=500&w=500&type=live",
    name: "Harmony 60 purple",
    rgb: {
      r: 128,
      g: 114,
      b: 125,
    },
  },
  {
    article_number: "1HARM01061",
    color: "61 heather",
    fabric_uuid: "VADAIN-70022008-1HARM01061",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01061.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01061&h=500&w=500&type=live",
    name: "Harmony 61 heather",
    rgb: {
      r: 134,
      g: 126,
      b: 128,
    },
  },
  {
    article_number: "1HARM01080",
    color: "80 brown",
    fabric_uuid: "VADAIN-70022012-1HARM01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01080&h=500&w=500&type=live",
    name: "Harmony 80 brown",
    rgb: {
      r: 118,
      g: 103,
      b: 87,
    },
  },
  {
    article_number: "1HARM01082",
    color: "82 caramel",
    fabric_uuid: "VADAIN-70022032-1HARM01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01082&h=500&w=500&type=live",
    name: "Harmony 82 caramel",
    rgb: {
      r: 163,
      g: 125,
      b: 87,
    },
  },
  {
    article_number: "1HARM01090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70022004-1HARM01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01090&h=500&w=500&type=live",
    name: "Harmony 90 grey",
    rgb: {
      r: 135,
      g: 141,
      b: 145,
    },
  },
  {
    article_number: "1HARM01092",
    color: "92 silver grey",
    fabric_uuid: "VADAIN-70022024-1HARM01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01092&h=500&w=500&type=live",
    name: "Harmony 92 silver",
    rgb: {
      r: 174,
      g: 178,
      b: 181,
    },
  },
  {
    article_number: "1HARM01093",
    color: "93 melee grey",
    fabric_uuid: "VADAIN-70022028-1HARM01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01093&h=500&w=500&type=live",
    name: "Harmony 93 melee grey",
    rgb: {
      r: 147,
      g: 147,
      b: 149,
    },
  },
  {
    article_number: "1HARM01095",
    color: "95 black",
    fabric_uuid: "VADAIN-70022009-1HARM01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01095&h=500&w=500&type=live",
    name: "Harmony 95 black",
    rgb: {
      r: 87,
      g: 89,
      b: 93,
    },
  },
  {
    article_number: "1HARM01096",
    color: "96 slate",
    fabric_uuid: "VADAIN-70022020-1HARM01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HARM01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HARM01096&h=500&w=500&type=live",
    name: "Harmony 96 slate",
    rgb: {
      r: 109,
      g: 111,
      b: 114,
    },
  },
  {
    article_number: "1HUG002010",
    color: "10 white",
    fabric_uuid: "VADAIN-70030398-1HUG002010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002010&h=500&w=500&type=live",
    name: "Hug kh 10 white",
    rgb: {
      r: 211,
      g: 211,
      b: 213,
    },
  },
  {
    article_number: "1HUG002011",
    color: "11 cream",
    fabric_uuid: "VADAIN-70030402-1HUG002011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002011&h=500&w=500&type=live",
    name: "Hug kh 11 cream",
    rgb: {
      r: 207,
      g: 206,
      b: 202,
    },
  },
  {
    article_number: "1HUG002013",
    color: "13 pearl",
    fabric_uuid: "VADAIN-70030390-1HUG002013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002013&h=500&w=500&type=live",
    name: "Hug kh 13 pearl",
    rgb: {
      r: 199,
      g: 195,
      b: 189,
    },
  },
  {
    article_number: "1HUG002015",
    color: "15 sand",
    fabric_uuid: "VADAIN-70030394-1HUG002015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002015&h=500&w=500&type=live",
    name: "Hug kh 15 sand",
    rgb: {
      r: 178,
      g: 165,
      b: 149,
    },
  },
  {
    article_number: "1HUG002016",
    color: "16 dove",
    fabric_uuid: "VADAIN-70030385-1HUG002016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002016&h=500&w=500&type=live",
    name: "Hug kh 16 dove",
    rgb: {
      r: 168,
      g: 165,
      b: 158,
    },
  },
  {
    article_number: "1HUG002017",
    color: "17 linen",
    fabric_uuid: "VADAIN-70030388-1HUG002017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002017&h=500&w=500&type=live",
    name: "Hug kh 17 linen",
    rgb: {
      r: 160,
      g: 156,
      b: 142,
    },
  },
  {
    article_number: "1HUG002018",
    color: "18 pebble",
    fabric_uuid: "VADAIN-70030397-1HUG002018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002018&h=500&w=500&type=live",
    name: "Hug kh 18 pebble",
    rgb: {
      r: 137,
      g: 125,
      b: 110,
    },
  },
  {
    article_number: "1HUG002020",
    color: "20 ochre",
    fabric_uuid: "VADAIN-70030381-1HUG002020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002020&h=500&w=500&type=live",
    name: "Hug kh 20 ochre",
    rgb: {
      r: 173,
      g: 131,
      b: 68,
    },
  },
  {
    article_number: "1HUG002023",
    color: "23 wheat",
    fabric_uuid: "VADAIN-70030391-1HUG002023",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002023.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002023&h=500&w=500&type=live",
    name: "Hug kh 23 wheat",
    rgb: {
      r: 173,
      g: 137,
      b: 89,
    },
  },
  {
    article_number: "1HUG002025",
    color: "25 spice",
    fabric_uuid: "VADAIN-70030399-1HUG002025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002025&h=500&w=500&type=live",
    name: "Hug kh 25 spice",
    rgb: {
      r: 160,
      g: 145,
      b: 117,
    },
  },
  {
    article_number: "1HUG002027",
    color: "27 burnt orange",
    fabric_uuid: "VADAIN-70030383-1HUG002027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002027&h=500&w=500&type=live",
    name: "Hug kh 27 burnt orange",
    rgb: {
      r: 150,
      g: 82,
      b: 49,
    },
  },
  {
    article_number: "1HUG002030",
    color: "30 advocado",
    fabric_uuid: "VADAIN-70030386-1HUG002030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002030&h=500&w=500&type=live",
    name: "Hug kh 30 advocado",
    rgb: {
      r: 138,
      g: 125,
      b: 81,
    },
  },
  {
    article_number: "1HUG002040",
    color: "40 burgundy",
    fabric_uuid: "VADAIN-70030404-1HUG002040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002040&h=500&w=500&type=live",
    name: "Hug kh 40 burgundy",
    rgb: {
      r: 97,
      g: 51,
      b: 59,
    },
  },
  {
    article_number: "1HUG002042",
    color: "42 marsala",
    fabric_uuid: "VADAIN-70030380-1HUG002042",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002042.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002042&h=500&w=500&type=live",
    name: "Hug kh 42 marsala",
    rgb: {
      r: 126,
      g: 63,
      b: 58,
    },
  },
  {
    article_number: "1HUG002045",
    color: "45 dusty rose",
    fabric_uuid: "VADAIN-70030395-1HUG002045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002045&h=500&w=500&type=live",
    name: "Hug kh 45 dusty rose",
    rgb: {
      r: 167,
      g: 136,
      b: 125,
    },
  },
  {
    article_number: "1HUG002046",
    color: "46 rose bisque",
    fabric_uuid: "VADAIN-70030401-1HUG002046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002046&h=500&w=500&type=live",
    name: "Hug kh 46 rose bisque",
    rgb: {
      r: 172,
      g: 103,
      b: 96,
    },
  },
  {
    article_number: "1HUG002051",
    color: "51 duck egg",
    fabric_uuid: "VADAIN-70030384-1HUG002051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002051&h=500&w=500&type=live",
    name: "Hug kh 51 duck egg",
    rgb: {
      r: 159,
      g: 175,
      b: 175,
    },
  },
  {
    article_number: "1HUG002053",
    color: "53 teal",
    fabric_uuid: "VADAIN-70030392-1HUG002053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002053&h=500&w=500&type=live",
    name: "Hug kh 53 teal",
    rgb: {
      r: 118,
      g: 138,
      b: 135,
    },
  },
  {
    article_number: "1HUG002054",
    color: "54 denim",
    fabric_uuid: "VADAIN-70030403-1HUG002054",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002054.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002054&h=500&w=500&type=live",
    name: "Hug kh 54 denim",
    rgb: {
      r: 92,
      g: 110,
      b: 125,
    },
  },
  {
    article_number: "1HUG002055",
    color: "55 navy",
    fabric_uuid: "VADAIN-70030387-1HUG002055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002055&h=500&w=500&type=live",
    name: "Hug kh 55 navy",
    rgb: {
      r: 61,
      g: 71,
      b: 80,
    },
  },
  {
    article_number: "1HUG002060",
    color: "60 heather",
    fabric_uuid: "VADAIN-70030396-1HUG002060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002060&h=500&w=500&type=live",
    name: "Hug kh 60 heather",
    rgb: {
      r: 129,
      g: 104,
      b: 96,
    },
  },
  {
    article_number: "1HUG002090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70030400-1HUG002090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002090&h=500&w=500&type=live",
    name: "Hug kh 90 grey",
    rgb: {
      r: 145,
      g: 146,
      b: 150,
    },
  },
  {
    article_number: "1HUG002091",
    color: "91 silver",
    fabric_uuid: "VADAIN-70030389-1HUG002091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002091&h=500&w=500&type=live",
    name: "Hug kh 91 silver",
    rgb: {
      r: 158,
      g: 159,
      b: 161,
    },
  },
  {
    article_number: "1HUG002094",
    color: "94 stormy grey",
    fabric_uuid: "VADAIN-70030393-1HUG002094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002094&h=500&w=500&type=live",
    name: "Hug kh 94 stormy grey",
    rgb: {
      r: 102,
      g: 100,
      b: 101,
    },
  },
  {
    article_number: "1HUG002096",
    color: "96 charcoal",
    fabric_uuid: "VADAIN-70030382-1HUG002096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUG002096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUG002096&h=500&w=500&type=live",
    name: "Hug kh 96 charcoal",
    rgb: {
      r: 72,
      g: 74,
      b: 78,
    },
  },
  {
    article_number: "1HUMB02094",
    color: "94 steel",
    fabric_uuid: "VADAIN-70021976-1HUMB02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02094&h=500&w=500&type=live",
    name: "Humble 94 steel",
    rgb: {
      r: 113,
      g: 113,
      b: 113,
    },
  },
  {
    article_number: "1HUMB02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70012378-1HUMB02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02010&h=500&w=500&type=live",
    name: "Humble kh 10 white",
    rgb: {
      r: 164,
      g: 164,
      b: 170,
    },
  },
  {
    article_number: "1HUMB02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70012376-1HUMB02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02011&h=500&w=500&type=live",
    name: "Humble kh 11 off white",
    rgb: {
      r: 167,
      g: 167,
      b: 165,
    },
  },
  {
    article_number: "1HUMB02012",
    color: "12 cream",
    fabric_uuid: "VADAIN-70012379-1HUMB02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02012&h=500&w=500&type=live",
    name: "Humble kh 12 cream",
    rgb: {
      r: 168,
      g: 165,
      b: 161,
    },
  },
  {
    article_number: "1HUMB02013",
    color: "13 biscuit",
    fabric_uuid: "VADAIN-70012380-1HUMB02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02013&h=500&w=500&type=live",
    name: "Humble kh 13 biscuit",
    rgb: {
      r: 161,
      g: 152,
      b: 141,
    },
  },
  {
    article_number: "1HUMB02018",
    color: "18 oatmeal",
    fabric_uuid: "VADAIN-70012386-1HUMB02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02018&h=500&w=500&type=live",
    name: "Humble kh 18 oatmeal",
    rgb: {
      r: 182,
      g: 176,
      b: 170,
    },
  },
  {
    article_number: "1HUMB02019",
    color: "19 humus",
    fabric_uuid: "VADAIN-70012170-1HUMB02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02019&h=500&w=500&type=live",
    name: "Humble kh 19 humus",
    rgb: {
      r: 147,
      g: 140,
      b: 133,
    },
  },
  {
    article_number: "1HUMB02030",
    color: "30 wasabi",
    fabric_uuid: "VADAIN-70012387-1HUMB02030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02030&h=500&w=500&type=live",
    name: "Humble kh 30 wasabi",
    rgb: {
      r: 146,
      g: 139,
      b: 119,
    },
  },
  {
    article_number: "1HUMB02035",
    color: "35 celadon",
    fabric_uuid: "VADAIN-70012388-1HUMB02035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02035&h=500&w=500&type=live",
    name: "Humble kh 35 celadon",
    rgb: {
      r: 141,
      g: 148,
      b: 141,
    },
  },
  {
    article_number: "1HUMB02036",
    color: "36 waterlily",
    fabric_uuid: "VADAIN-70012389-1HUMB02036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02036&h=500&w=500&type=live",
    name: "Humble kh 36 water lily",
    rgb: {
      r: 159,
      g: 161,
      b: 160,
    },
  },
  {
    article_number: "1HUMB02046",
    color: "46 marsala",
    fabric_uuid: "VADAIN-70012374-1HUMB02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02046&h=500&w=500&type=live",
    name: "Humble kh 46 marsala",
    rgb: {
      r: 128,
      g: 93,
      b: 91,
    },
  },
  {
    article_number: "1HUMB02055",
    color: "55 pale blue",
    fabric_uuid: "VADAIN-70012390-1HUMB02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02055&h=500&w=500&type=live",
    name: "Humble kh 55 pale blue",
    rgb: {
      r: 148,
      g: 152,
      b: 158,
    },
  },
  {
    article_number: "1HUMB02060",
    color: "60 port",
    fabric_uuid: "VADAIN-70012391-1HUMB02060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02060&h=500&w=500&type=live",
    name: "Humble kh 60 port",
    rgb: {
      r: 118,
      g: 103,
      b: 100,
    },
  },
  {
    article_number: "1HUMB02065",
    color: "65 iris",
    fabric_uuid: "VADAIN-70012373-1HUMB02065",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02065.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02065&h=500&w=500&type=live",
    name: "Humble kh 65 iris",
    rgb: {
      r: 147,
      g: 142,
      b: 145,
    },
  },
  {
    article_number: "1HUMB02080",
    color: "80 cafe",
    fabric_uuid: "VADAIN-70012392-1HUMB02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02080&h=500&w=500&type=live",
    name: "Humble kh 80 cafe",
    rgb: {
      r: 127,
      g: 110,
      b: 96,
    },
  },
  {
    article_number: "1HUMB02082",
    color: "82 nougat",
    fabric_uuid: "VADAIN-70012377-1HUMB02082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02082&h=500&w=500&type=live",
    name: "Humble kh 82 nougat",
    rgb: {
      r: 145,
      g: 134,
      b: 123,
    },
  },
  {
    article_number: "1HUMB02087",
    color: "87 taupe",
    fabric_uuid: "VADAIN-70012372-1HUMB02087",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02087.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02087&h=500&w=500&type=live",
    name: "Humble kh 87 taupe",
    rgb: {
      r: 140,
      g: 135,
      b: 130,
    },
  },
  {
    article_number: "1HUMB02090",
    color: "90 lead",
    fabric_uuid: "VADAIN-70012215-1HUMB02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02090&h=500&w=500&type=live",
    name: "Humble kh 90 lead",
    rgb: {
      r: 124,
      g: 117,
      b: 112,
    },
  },
  {
    article_number: "1HUMB02091",
    color: "91 grey",
    fabric_uuid: "VADAIN-70012452-1HUMB02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02091&h=500&w=500&type=live",
    name: "Humble kh 91 grey",
    rgb: {
      r: 155,
      g: 154,
      b: 150,
    },
  },
  {
    article_number: "1HUMB02093",
    color: "93 pewter",
    fabric_uuid: "VADAIN-70012162-1HUMB02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02093&h=500&w=500&type=live",
    name: "Humble kh 93 pewter",
    rgb: {
      r: 99,
      g: 99,
      b: 98,
    },
  },
  {
    article_number: "1HUMB02095",
    color: "95 licorice",
    fabric_uuid: "VADAIN-70012371-1HUMB02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUMB02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUMB02095&h=500&w=500&type=live",
    name: "Humble kh 95 licorice",
    rgb: {
      r: 87,
      g: 87,
      b: 86,
    },
  },
  {
    article_number: "1HUNT01009",
    color: "09 snow white",
    fabric_uuid: "VADAIN-70021701-1HUNT01009",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01009.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01009&h=500&w=500&type=live",
    name: "Hunter 09 snow white",
    rgb: {
      r: 199,
      g: 203,
      b: 214,
    },
  },
  {
    article_number: "1HUNT01010",
    color: "10 white",
    fabric_uuid: "VADAIN-70021708-1HUNT01010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01010&h=500&w=500&type=live",
    name: "Hunter 10 white",
    rgb: {
      r: 201,
      g: 202,
      b: 205,
    },
  },
  {
    article_number: "1HUNT01011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70021754-1HUNT01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01011&h=500&w=500&type=live",
    name: "Hunter 11 off white",
    rgb: {
      r: 196,
      g: 192,
      b: 183,
    },
  },
  {
    article_number: "1HUNT01012",
    color: "12 ecru",
    fabric_uuid: "VADAIN-70021692-1HUNT01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01012&h=500&w=500&type=live",
    name: "Hunter 12 ecru",
    rgb: {
      r: 201,
      g: 187,
      b: 166,
    },
  },
  {
    article_number: "1HUNT01013",
    color: "13 cream",
    fabric_uuid: "VADAIN-70021732-1HUNT01013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01013&h=500&w=500&type=live",
    name: "Hunter 13 cream",
    rgb: {
      r: 201,
      g: 200,
      b: 187,
    },
  },
  {
    article_number: "1HUNT01014",
    color: "14 desert sand",
    fabric_uuid: "VADAIN-70021743-1HUNT01014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01014&h=500&w=500&type=live",
    name: "Hunter 14 desert sant",
    rgb: {
      r: 181,
      g: 148,
      b: 113,
    },
  },
  {
    article_number: "1HUNT01016",
    color: "16 latte",
    fabric_uuid: "VADAIN-70021700-1HUNT01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01016&h=500&w=500&type=live",
    name: "Hunter 16 latte",
    rgb: {
      r: 168,
      g: 148,
      b: 127,
    },
  },
  {
    article_number: "1HUNT01017",
    color: "17 mushroom",
    fabric_uuid: "VADAIN-70021726-1HUNT01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01017&h=500&w=500&type=live",
    name: "Hunter 17 mushroom",
    rgb: {
      r: 157,
      g: 142,
      b: 127,
    },
  },
  {
    article_number: "1HUNT01018",
    color: "18 stone",
    fabric_uuid: "VADAIN-70021757-1HUNT01018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01018&h=500&w=500&type=live",
    name: "Hunter 18 stone",
    rgb: {
      r: 160,
      g: 158,
      b: 152,
    },
  },
  {
    article_number: "1HUNT01019",
    color: "19 fossil",
    fabric_uuid: "VADAIN-70021694-1HUNT01019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01019&h=500&w=500&type=live",
    name: "Hunter 19 fossil",
    rgb: {
      r: 146,
      g: 143,
      b: 140,
    },
  },
  {
    article_number: "1HUNT01020",
    color: "20 yellow",
    fabric_uuid: "VADAIN-70021735-1HUNT01020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01020&h=500&w=500&type=live",
    name: "Hunter 20 yellow",
    rgb: {
      r: 202,
      g: 171,
      b: 71,
    },
  },
  {
    article_number: "1HUNT01021",
    color: "21 vanille",
    fabric_uuid: "VADAIN-70021746-1HUNT01021",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01021.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01021&h=500&w=500&type=live",
    name: "Hunter 21 vanilla",
    rgb: {
      r: 200,
      g: 185,
      b: 162,
    },
  },
  {
    article_number: "1HUNT01022",
    color: "22 soft yellow",
    fabric_uuid: "VADAIN-70021718-1HUNT01022",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01022.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01022&h=500&w=500&type=live",
    name: "Hunter 22 soft yellow",
    rgb: {
      r: 200,
      g: 195,
      b: 155,
    },
  },
  {
    article_number: "1HUNT01023",
    color: "23 sunshine",
    fabric_uuid: "VADAIN-70021727-1HUNT01023",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01023.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01023&h=500&w=500&type=live",
    name: "Hunter 23 sunshine",
    rgb: {
      r: 208,
      g: 190,
      b: 109,
    },
  },
  {
    article_number: "1HUNT01024",
    color: "24 ochre",
    fabric_uuid: "VADAIN-70021758-1HUNT01024",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01024.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01024&h=500&w=500&type=live",
    name: "Hunter 24 ochre",
    rgb: {
      r: 203,
      g: 154,
      b: 61,
    },
  },
  {
    article_number: "1HUNT01025",
    color: "25 melon",
    fabric_uuid: "VADAIN-70021709-1HUNT01025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01025&h=500&w=500&type=live",
    name: "Hunter 25 melon",
    rgb: {
      r: 218,
      g: 125,
      b: 114,
    },
  },
  {
    article_number: "1HUNT01026",
    color: "26 mango",
    fabric_uuid: "VADAIN-70021729-1HUNT01026",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01026.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01026&h=500&w=500&type=live",
    name: "Hunter 26 mango",
    rgb: {
      r: 211,
      g: 112,
      b: 78,
    },
  },
  {
    article_number: "1HUNT01027",
    color: "27 orange",
    fabric_uuid: "VADAIN-70021703-1HUNT01027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01027&h=500&w=500&type=live",
    name: "Hunter 27 orange",
    rgb: {
      r: 188,
      g: 102,
      b: 50,
    },
  },
  {
    article_number: "1HUNT01028",
    color: "28 brick",
    fabric_uuid: "VADAIN-70021710-1HUNT01028",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01028.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01028&h=500&w=500&type=live",
    name: "Hunter 28 brick",
    rgb: {
      r: 144,
      g: 88,
      b: 53,
    },
  },
  {
    article_number: "1HUNT01029",
    color: "29 apricot",
    fabric_uuid: "VADAIN-70021737-1HUNT01029",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01029.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01029&h=500&w=500&type=live",
    name: "Hunter 29 apricot",
    rgb: {
      r: 211,
      g: 177,
      b: 147,
    },
  },
  {
    article_number: "1HUNT01030",
    color: "30 green",
    fabric_uuid: "VADAIN-70021695-1HUNT01030",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01030.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01030&h=500&w=500&type=live",
    name: "Hunter 30 green",
    rgb: {
      r: 50,
      g: 123,
      b: 98,
    },
  },
  {
    article_number: "1HUNT01031",
    color: "31 pine",
    fabric_uuid: "VADAIN-70021717-1HUNT01031",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01031.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01031&h=500&w=500&type=live",
    name: "Hunter 31 pine",
    rgb: {
      r: 40,
      g: 78,
      b: 81,
    },
  },
  {
    article_number: "1HUNT01033",
    color: "33 leave",
    fabric_uuid: "VADAIN-70021747-1HUNT01033",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01033.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01033&h=500&w=500&type=live",
    name: "Hunter 33 leaf",
    rgb: {
      r: 100,
      g: 122,
      b: 81,
    },
  },
  {
    article_number: "1HUNT01034",
    color: "34 grass green",
    fabric_uuid: "VADAIN-70021702-1HUNT01034",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01034.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01034&h=500&w=500&type=live",
    name: "Hunter 34 grass green",
    rgb: {
      r: 154,
      g: 166,
      b: 97,
    },
  },
  {
    article_number: "1HUNT01035",
    color: "35 mint",
    fabric_uuid: "VADAIN-70021721-1HUNT01035",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01035.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01035&h=500&w=500&type=live",
    name: "Hunter 35 mint",
    rgb: {
      r: 143,
      g: 173,
      b: 170,
    },
  },
  {
    article_number: "1HUNT01036",
    color: "36 pastel green",
    fabric_uuid: "VADAIN-70021730-1HUNT01036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01036&h=500&w=500&type=live",
    name: "Hunter 36 pastel green",
    rgb: {
      r: 167,
      g: 190,
      b: 186,
    },
  },
  {
    article_number: "1HUNT01037",
    color: "37 moss",
    fabric_uuid: "VADAIN-70021707-1HUNT01037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01037&h=500&w=500&type=live",
    name: "Hunter 37 moss",
    rgb: {
      r: 103,
      g: 126,
      b: 101,
    },
  },
  {
    article_number: "1HUNT01038",
    color: "38 sage green",
    fabric_uuid: "VADAIN-70021760-1HUNT01038",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01038.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01038&h=500&w=500&type=live",
    name: "Hunter 38 sage green",
    rgb: {
      r: 123,
      g: 143,
      b: 145,
    },
  },
  {
    article_number: "1HUNT01039",
    color: "39 teal",
    fabric_uuid: "VADAIN-70021756-1HUNT01039",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01039.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01039&h=500&w=500&type=live",
    name: "Hunter 39 teal",
    rgb: {
      r: 79,
      g: 92,
      b: 102,
    },
  },
  {
    article_number: "1HUNT01040",
    color: "40 red",
    fabric_uuid: "VADAIN-70021693-1HUNT01040",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01040.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01040&h=500&w=500&type=live",
    name: "Hunter 40 red",
    rgb: {
      r: 184,
      g: 63,
      b: 65,
    },
  },
  {
    article_number: "1HUNT01041",
    color: "41 salmon",
    fabric_uuid: "VADAIN-70021733-1HUNT01041",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01041.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01041&h=500&w=500&type=live",
    name: "Hunter 41 salmon",
    rgb: {
      r: 212,
      g: 150,
      b: 145,
    },
  },
  {
    article_number: "1HUNT01042",
    color: "42 ruby",
    fabric_uuid: "VADAIN-70021744-1HUNT01042",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01042.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01042&h=500&w=500&type=live",
    name: "Hunter 42 ruby",
    rgb: {
      r: 149,
      g: 60,
      b: 70,
    },
  },
  {
    article_number: "1HUNT01043",
    color: "43 maroon",
    fabric_uuid: "VADAIN-70021699-1HUNT01043",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01043.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01043&h=500&w=500&type=live",
    name: "Hunter 43 maroon",
    rgb: {
      r: 116,
      g: 67,
      b: 56,
    },
  },
  {
    article_number: "1HUNT01044",
    color: "44 flamingo",
    fabric_uuid: "VADAIN-70021725-1HUNT01044",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01044.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01044&h=500&w=500&type=live",
    name: "Hunter 44 flamingo",
    rgb: {
      r: 193,
      g: 133,
      b: 134,
    },
  },
  {
    article_number: "1HUNT01045",
    color: "45 blush",
    fabric_uuid: "VADAIN-70021753-1HUNT01045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01045&h=500&w=500&type=live",
    name: "Hunter 45 blush",
    rgb: {
      r: 205,
      g: 176,
      b: 171,
    },
  },
  {
    article_number: "1HUNT01046",
    color: "46 nude",
    fabric_uuid: "VADAIN-70021712-1HUNT01046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01046&h=500&w=500&type=live",
    name: "Hunter 46 nude",
    rgb: {
      r: 209,
      g: 184,
      b: 171,
    },
  },
  {
    article_number: "1HUNT01047",
    color: "47 candy",
    fabric_uuid: "VADAIN-70021740-1HUNT01047",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01047.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01047&h=500&w=500&type=live",
    name: "Hunter 47 candy",
    rgb: {
      r: 206,
      g: 153,
      b: 171,
    },
  },
  {
    article_number: "1HUNT01048",
    color: "48 fuchsia",
    fabric_uuid: "VADAIN-70021750-1HUNT01048",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01048.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01048&h=500&w=500&type=live",
    name: "Hunter 48 fuchsia",
    rgb: {
      r: 215,
      g: 40,
      b: 98,
    },
  },
  {
    article_number: "1HUNT01049",
    color: "49 sweet",
    fabric_uuid: "VADAIN-70021722-1HUNT01049",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01049.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01049&h=500&w=500&type=live",
    name: "Hunter 49 sweet",
    rgb: {
      r: 205,
      g: 101,
      b: 128,
    },
  },
  {
    article_number: "1HUNT01050",
    color: "50 baby blue",
    fabric_uuid: "VADAIN-70021714-1HUNT01050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01050&h=500&w=500&type=live",
    name: "Hunter 50 baby blue",
    rgb: {
      r: 159,
      g: 181,
      b: 195,
    },
  },
  {
    article_number: "1HUNT01051",
    color: "51 night blue",
    fabric_uuid: "VADAIN-70021741-1HUNT01051",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01051.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01051&h=500&w=500&type=live",
    name: "Hunter 51 night blue",
    rgb: {
      r: 55,
      g: 62,
      b: 82,
    },
  },
  {
    article_number: "1HUNT01052",
    color: "52 aqua",
    fabric_uuid: "VADAIN-70021751-1HUNT01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01052&h=500&w=500&type=live",
    name: "Hunter 52 aqua",
    rgb: {
      r: 101,
      g: 155,
      b: 162,
    },
  },
  {
    article_number: "1HUNT01053",
    color: "53 sky",
    fabric_uuid: "VADAIN-70021719-1HUNT01053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01053&h=500&w=500&type=live",
    name: "Hunter 53 sky",
    rgb: {
      r: 128,
      g: 159,
      b: 172,
    },
  },
  {
    article_number: "1HUNT01054",
    color: "54 kobalt blue",
    fabric_uuid: "VADAIN-70021728-1HUNT01054",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01054.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01054&h=500&w=500&type=live",
    name: "Hunter 54 kobalt blue",
    rgb: {
      r: 36,
      g: 79,
      b: 150,
    },
  },
  {
    article_number: "1HUNT01055",
    color: "55 duck egg",
    fabric_uuid: "VADAIN-70021704-1HUNT01055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01055&h=500&w=500&type=live",
    name: "Hunter 55 duck egg",
    rgb: {
      r: 144,
      g: 182,
      b: 184,
    },
  },
  {
    article_number: "1HUNT01056",
    color: "56 smoke blue",
    fabric_uuid: "VADAIN-70021711-1HUNT01056",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01056.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01056&h=500&w=500&type=live",
    name: "Hunter 56 smoke blue",
    rgb: {
      r: 87,
      g: 117,
      b: 136,
    },
  },
  {
    article_number: "1HUNT01057",
    color: "57 marine",
    fabric_uuid: "VADAIN-70021738-1HUNT01057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01057&h=500&w=500&type=live",
    name: "Hunter 57 marine",
    rgb: {
      r: 42,
      g: 64,
      b: 87,
    },
  },
  {
    article_number: "1HUNT01058",
    color: "58 vintage blue",
    fabric_uuid: "VADAIN-70021698-1HUNT01058",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01058.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01058&h=500&w=500&type=live",
    name: "Hunter 58 vintage blue",
    rgb: {
      r: 60,
      g: 94,
      b: 115,
    },
  },
  {
    article_number: "1HUNT01059",
    color: "59 royal blue",
    fabric_uuid: "VADAIN-70021723-1HUNT01059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01059&h=500&w=500&type=live",
    name: "Hunter 59 royal blue",
    rgb: {
      r: 47,
      g: 59,
      b: 91,
    },
  },
  {
    article_number: "1HUNT01060",
    color: "60 purple",
    fabric_uuid: "VADAIN-70021731-1HUNT01060",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01060.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01060&h=500&w=500&type=live",
    name: "Hunter 60 purple",
    rgb: {
      r: 72,
      g: 54,
      b: 105,
    },
  },
  {
    article_number: "1HUNT01061",
    color: "61 lilac",
    fabric_uuid: "VADAIN-70021705-1HUNT01061",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01061.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01061&h=500&w=500&type=live",
    name: "Hunter 61 lilac",
    rgb: {
      r: 175,
      g: 170,
      b: 183,
    },
  },
  {
    article_number: "1HUNT01062",
    color: "62 heather",
    fabric_uuid: "VADAIN-70021715-1HUNT01062",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01062.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01062&h=500&w=500&type=live",
    name: "Hunter 62 heather",
    rgb: {
      r: 110,
      g: 86,
      b: 106,
    },
  },
  {
    article_number: "1HUNT01063",
    color: "63 lavender",
    fabric_uuid: "VADAIN-70021742-1HUNT01063",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01063.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01063&h=500&w=500&type=live",
    name: "Hunter 63 lavender",
    rgb: {
      r: 131,
      g: 121,
      b: 129,
    },
  },
  {
    article_number: "1HUNT01064",
    color: "64 grape",
    fabric_uuid: "VADAIN-70021696-1HUNT01064",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01064.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01064&h=500&w=500&type=live",
    name: "Hunter 64 grape",
    rgb: {
      r: 139,
      g: 93,
      b: 102,
    },
  },
  {
    article_number: "1HUNT01080",
    color: "80 clay",
    fabric_uuid: "VADAIN-70021720-1HUNT01080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01080&h=500&w=500&type=live",
    name: "Hunter 80 clay",
    rgb: {
      r: 141,
      g: 132,
      b: 116,
    },
  },
  {
    article_number: "1HUNT01081",
    color: "81 loam",
    fabric_uuid: "VADAIN-70021748-1HUNT01081",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01081.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01081&h=500&w=500&type=live",
    name: "Hunter 81 loam",
    rgb: {
      r: 103,
      g: 94,
      b: 87,
    },
  },
  {
    article_number: "1HUNT01082",
    color: "82 otter",
    fabric_uuid: "VADAIN-70021706-1HUNT01082",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01082.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01082&h=500&w=500&type=live",
    name: "Hunter 82 otter",
    rgb: {
      r: 138,
      g: 118,
      b: 99,
    },
  },
  {
    article_number: "1HUNT01086",
    color: "86 dark brown",
    fabric_uuid: "VADAIN-70021713-1HUNT01086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01086&h=500&w=500&type=live",
    name: "Hunter 86 dark brown",
    rgb: {
      r: 78,
      g: 64,
      b: 59,
    },
  },
  {
    article_number: "1HUNT01088",
    color: "88 light grey",
    fabric_uuid: "VADAIN-70021736-1HUNT01088",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01088.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01088&h=500&w=500&type=live",
    name: "Hunter 88 light grey",
    rgb: {
      r: 146,
      g: 151,
      b: 155,
    },
  },
  {
    article_number: "1HUNT01089",
    color: "89 heron",
    fabric_uuid: "VADAIN-70021759-1HUNT01089",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01089.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01089&h=500&w=500&type=live",
    name: "Hunter 89 heron",
    rgb: {
      r: 125,
      g: 130,
      b: 134,
    },
  },
  {
    article_number: "1HUNT01090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70021697-1HUNT01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01090&h=500&w=500&type=live",
    name: "Hunter 90 grey",
    rgb: {
      r: 129,
      g: 131,
      b: 130,
    },
  },
  {
    article_number: "1HUNT01091",
    color: "91 cloud grey",
    fabric_uuid: "VADAIN-70021739-1HUNT01091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01091&h=500&w=500&type=live",
    name: "Hunter 91 cloud grey",
    rgb: {
      r: 174,
      g: 180,
      b: 186,
    },
  },
  {
    article_number: "1HUNT01092",
    color: "92 grey blue",
    fabric_uuid: "VADAIN-70021749-1HUNT01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01092&h=500&w=500&type=live",
    name: "Hunter 92 grey blue",
    rgb: {
      r: 102,
      g: 124,
      b: 147,
    },
  },
  {
    article_number: "1HUNT01093",
    color: "93 elephant",
    fabric_uuid: "VADAIN-70021755-1HUNT01093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01093&h=500&w=500&type=live",
    name: "Hunter 93 elephant",
    rgb: {
      r: 106,
      g: 104,
      b: 105,
    },
  },
  {
    article_number: "1HUNT01094",
    color: "94 cool grey",
    fabric_uuid: "VADAIN-70021691-1HUNT01094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01094&h=500&w=500&type=live",
    name: "Hunter 94 cool grey",
    rgb: {
      r: 130,
      g: 140,
      b: 152,
    },
  },
  {
    article_number: "1HUNT01095",
    color: "95 black",
    fabric_uuid: "VADAIN-70021734-1HUNT01095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01095&h=500&w=500&type=live",
    name: "Hunter 95 black",
    rgb: {
      r: 45,
      g: 47,
      b: 49,
    },
  },
  {
    article_number: "1HUNT01096",
    color: "96 taupe",
    fabric_uuid: "VADAIN-70021745-1HUNT01096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01096&h=500&w=500&type=live",
    name: "Hunter 96 taupe",
    rgb: {
      r: 116,
      g: 111,
      b: 110,
    },
  },
  {
    article_number: "1HUNT01097",
    color: "97 gravel",
    fabric_uuid: "VADAIN-70021716-1HUNT01097",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01097.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01097&h=500&w=500&type=live",
    name: "Hunter 97 gravel",
    rgb: {
      r: 103,
      g: 101,
      b: 104,
    },
  },
  {
    article_number: "1HUNT01098",
    color: "98 dark grey",
    fabric_uuid: "VADAIN-70021724-1HUNT01098",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01098.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01098&h=500&w=500&type=live",
    name: "Hunter 98 dark grey",
    rgb: {
      r: 81,
      g: 79,
      b: 84,
    },
  },
  {
    article_number: "1HUNT01099",
    color: "99 graphite",
    fabric_uuid: "VADAIN-70021752-1HUNT01099",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HUNT01099.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HUNT01099&h=500&w=500&type=live",
    name: "Hunter 99 graphite",
    rgb: {
      r: 62,
      g: 61,
      b: 65,
    },
  },
  {
    article_number: "1HYDRO2110",
    color: "110 cotton",
    fabric_uuid: "VADAIN-70034026-1HYDRO2110",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2110.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2110&h=500&w=500&type=live",
    name: "Hydro 110 cotton",
    rgb: {
      r: 224,
      g: 225,
      b: 220,
    },
  },
  {
    article_number: "1HYDRO216",
    color: "160 oatmeal",
    fabric_uuid: "VADAIN-70034031-1HYDRO216",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2160.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO216&h=500&w=500&type=live",
    name: "Hydro 160 oatmeal",
    rgb: {
      r: 222,
      g: 222,
      b: 222,
    },
  },
  {
    article_number: "1HYDRO2170",
    color: "170 linen",
    fabric_uuid: "VADAIN-70034036-1HYDRO2170",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2170.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2170&h=500&w=500&type=live",
    name: "Hydro 170 linen",
    rgb: {
      r: 171,
      g: 157,
      b: 140,
    },
  },
  {
    article_number: "1HYDRO2330",
    color: "330 sage",
    fabric_uuid: "VADAIN-70034028-1HYDRO2330",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2330.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2330&h=500&w=500&type=live",
    name: "Hydro 330 sage",
    rgb: {
      r: 163,
      g: 165,
      b: 154,
    },
  },
  {
    article_number: "1HYDRO2550",
    color: "550 steel",
    fabric_uuid: "VADAIN-70034033-1HYDRO2550",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2550.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2550&h=500&w=500&type=live",
    name: "Hydro 550 steel",
    rgb: {
      r: 104,
      g: 113,
      b: 106,
    },
  },
  {
    article_number: "1HYDRO2560",
    color: "560 denim",
    fabric_uuid: "VADAIN-70034035-1HYDRO2560",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2560.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2560&h=500&w=500&type=live",
    name: "Hydro 560 denim",
    rgb: {
      r: 119,
      g: 122,
      b: 126,
    },
  },
  {
    article_number: "1HYDRO2850",
    color: "850 otter",
    fabric_uuid: "VADAIN-70034030-1HYDRO2850",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2850.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2850&h=500&w=500&type=live",
    name: "Hydro 850 otter",
    rgb: {
      r: 125,
      g: 113,
      b: 99,
    },
  },
  {
    article_number: "1HYDRO2860",
    color: "860 hippo",
    fabric_uuid: "VADAIN-70034032-1HYDRO2860",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2860.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2860&h=500&w=500&type=live",
    name: "Hydro 860 hippo",
    rgb: {
      r: 121,
      g: 112,
      b: 97,
    },
  },
  {
    article_number: "1HYDRO2900",
    color: "900 grey",
    fabric_uuid: "VADAIN-70034027-1HYDRO2900",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2900.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2900&h=500&w=500&type=live",
    name: "Hydro 900 grey",
    rgb: {
      r: 128,
      g: 124,
      b: 121,
    },
  },
  {
    article_number: "1HYDRO2920",
    color: "920 grey melee",
    fabric_uuid: "VADAIN-70034029-1HYDRO2920",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2920.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2920&h=500&w=500&type=live",
    name: "Hydro 920 grey melee",
    rgb: {
      r: 192,
      g: 192,
      b: 184,
    },
  },
  {
    article_number: "1HYDRO2930",
    color: "930 rock",
    fabric_uuid: "VADAIN-70034034-1HYDRO2930",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2930.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2930&h=500&w=500&type=live",
    name: "Hydro 930 rock",
    rgb: {
      r: 167,
      g: 163,
      b: 153,
    },
  },
  {
    article_number: "1HYDRO2960",
    color: "960 black",
    fabric_uuid: "VADAIN-70034025-1HYDRO2960",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1HYDRO2960.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1HYDRO2960&h=500&w=500&type=live",
    name: "Hydro 960 black",
    rgb: {
      r: 66,
      g: 65,
      b: 61,
    },
  },
  {
    article_number: "1IDYL02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70018604-1IDYL02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02010&h=500&w=500&type=live",
    name: "Idylle kh 10 white",
    rgb: {
      r: 194,
      g: 194,
      b: 194,
    },
  },
  {
    article_number: "1IDYL02011",
    color: "11 natural",
    fabric_uuid: "VADAIN-70018605-1IDYL02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02011&h=500&w=500&type=live",
    name: "Idylle kh 11 natural",
    rgb: {
      r: 205,
      g: 201,
      b: 192,
    },
  },
  {
    article_number: "1IDYL02012",
    color: "12 sand",
    fabric_uuid: "VADAIN-70018606-1IDYL02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02012&h=500&w=500&type=live",
    name: "Idylle kh 12 sand",
    rgb: {
      r: 163,
      g: 153,
      b: 141,
    },
  },
  {
    article_number: "1IDYL02017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-70018607-1IDYL02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02017&h=500&w=500&type=live",
    name: "Idylle kh 17 linnen",
    rgb: {
      r: 167,
      g: 160,
      b: 144,
    },
  },
  {
    article_number: "1IDYL02027",
    color: "27 copper",
    fabric_uuid: "VADAIN-70018608-1IDYL02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02027&h=500&w=500&type=live",
    name: "Idylle kh 27 copper",
    rgb: {
      r: 163,
      g: 124,
      b: 102,
    },
  },
  {
    article_number: "1IDYL02052",
    color: "52 denim",
    fabric_uuid: "VADAIN-70018609-1IDYL02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02052&h=500&w=500&type=live",
    name: "Idylle kh 52 denim",
    rgb: {
      r: 101,
      g: 101,
      b: 109,
    },
  },
  {
    article_number: "1IDYL02055",
    color: "55 duck egg",
    fabric_uuid: "VADAIN-70018610-1IDYL02055",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02055.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02055&h=500&w=500&type=live",
    name: "Idylle kh 55 duck egg",
    rgb: {
      r: 149,
      g: 151,
      b: 148,
    },
  },
  {
    article_number: "1IDYL02083",
    color: "83 chinchilla",
    fabric_uuid: "VADAIN-70018611-1IDYL02083",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02083.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02083&h=500&w=500&type=live",
    name: "Idylle kh 83 chinchilla",
    rgb: {
      r: 129,
      g: 123,
      b: 120,
    },
  },
  {
    article_number: "1IDYL02090",
    color: "90 grey",
    fabric_uuid: "VADAIN-70018612-1IDYL02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02090&h=500&w=500&type=live",
    name: "Idylle kh 90 grey",
    rgb: {
      r: 144,
      g: 140,
      b: 138,
    },
  },
  {
    article_number: "1IDYL02092",
    color: "92 silver grey",
    fabric_uuid: "VADAIN-70018613-1IDYL02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02092&h=500&w=500&type=live",
    name: "Idylle kh 92 silver grey",
    rgb: {
      r: 161,
      g: 157,
      b: 156,
    },
  },
  {
    article_number: "1IDYL02093",
    color: "93 dark grey",
    fabric_uuid: "VADAIN-70018614-1IDYL02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IDYL02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IDYL02093&h=500&w=500&type=live",
    name: "Idylle kh 93 dark grey",
    rgb: {
      r: 115,
      g: 112,
      b: 113,
    },
  },
  {
    article_number: "1IMPA02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70044266-1IMPA02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02011&h=500&w=500&type=live",
    name: "Impact kh 11 off white",
    rgb: {
      r: 209,
      g: 209,
      b: 209,
    },
  },
  {
    article_number: "1IMPA02016",
    color: "16 almond",
    fabric_uuid: "VADAIN-70044267-1IMPA02016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02016&h=500&w=500&type=live",
    name: "Impact kh 16 almond",
    rgb: {
      r: 204,
      g: 195,
      b: 185,
    },
  },
  {
    article_number: "1IMPA02017",
    color: "17 beige",
    fabric_uuid: "VADAIN-70044268-1IMPA02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02017&h=500&w=500&type=live",
    name: "Impact kh 17 beige",
    rgb: {
      r: 194,
      g: 189,
      b: 183,
    },
  },
  {
    article_number: "1IMPA02018",
    color: "18 biscuit",
    fabric_uuid: "VADAIN-70044269-1IMPA02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02018&h=500&w=500&type=live",
    name: "Impact kh 18 biscuit",
    rgb: {
      r: 195,
      g: 179,
      b: 163,
    },
  },
  {
    article_number: "1IMPA02019",
    color: "19 flax",
    fabric_uuid: "VADAIN-70044270-1IMPA02019",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02019.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02019&h=500&w=500&type=live",
    name: "Impact kh 19 flax",
    rgb: {
      r: 169,
      g: 163,
      b: 148,
    },
  },
  {
    article_number: "1IMPA02029",
    color: "29 light mahogany",
    fabric_uuid: "VADAIN-70044271-1IMPA02029",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02029.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02029&h=500&w=500&type=live",
    name: "Impact kh 29 light mahogany",
    rgb: {
      r: 164,
      g: 127,
      b: 114,
    },
  },
  {
    article_number: "1IMPA02037",
    color: "caramel",
    fabric_uuid: "VADAIN-70044272-1IMPA02037",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02037.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02037&h=500&w=500&type=live",
    name: "Impact kh 37 caramel",
    rgb: {
      r: 174,
      g: 151,
      b: 130,
    },
  },
  {
    article_number: "1IMPA02045",
    color: "45 soft blush",
    fabric_uuid: "VADAIN-70044273-1IMPA02045",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02045.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02045&h=500&w=500&type=live",
    name: "Impact kh 45 soft blush",
    rgb: {
      r: 204,
      g: 183,
      b: 171,
    },
  },
  {
    article_number: "1IMPA02050",
    color: "50 midnight",
    fabric_uuid: "VADAIN-70044274-1IMPA02050",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02050.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02050&h=500&w=500&type=live",
    name: "Impact kh 50 midnight",
    rgb: {
      r: 92,
      g: 95,
      b: 108,
    },
  },
  {
    article_number: "1IMPA02052",
    color: "52 vintage blue",
    fabric_uuid: "VADAIN-70044275-1IMPA02052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02052&h=500&w=500&type=live",
    name: "Impact kh 52 vintage blue",
    rgb: {
      r: 156,
      g: 171,
      b: 173,
    },
  },
  {
    article_number: "1IMPA02090",
    color: "90 medium grey",
    fabric_uuid: "VADAIN-70044276-1IMPA02090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02090&h=500&w=500&type=live",
    name: "Impact kh 90 medium grey",
    rgb: {
      r: 142,
      g: 141,
      b: 143,
    },
  },
  {
    article_number: "1IMPA02091",
    color: "91 pebble",
    fabric_uuid: "VADAIN-70044277-1IMPA02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02091&h=500&w=500&type=live",
    name: "Impact kh 91 pebble",
    rgb: {
      r: 190,
      g: 187,
      b: 186,
    },
  },
  {
    article_number: "1IMPA02092",
    color: "92 light grey",
    fabric_uuid: "VADAIN-70044278-1IMPA02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02092&h=500&w=500&type=live",
    name: "Impact kh 92 light grey",
    rgb: {
      r: 168,
      g: 168,
      b: 170,
    },
  },
  {
    article_number: "1IMPA02095",
    color: "95 nearly black",
    fabric_uuid: "VADAIN-70044279-1IMPA02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02095&h=500&w=500&type=live",
    name: "Impact kh 95 nearly black",
    rgb: {
      r: 69,
      g: 72,
      b: 73,
    },
  },
  {
    article_number: "1IMPA02098",
    color: "98 antracite",
    fabric_uuid: "VADAIN-70044280-1IMPA02098",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1IMPA02098.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1IMPA02098&h=500&w=500&type=live",
    name: "Impact kh 98 anthracite",
    rgb: {
      r: 103,
      g: 102,
      b: 102,
    },
  },
  {
    article_number: "1JONE02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70022260-1JONE02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02010&h=500&w=500&type=live",
    name: "Jones kh 10 white",
    rgb: {
      r: 221,
      g: 223,
      b: 228,
    },
  },
  {
    article_number: "1JONE02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70022266-1JONE02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02011&h=500&w=500&type=live",
    name: "Jones kh 11 off white",
    rgb: {
      r: 238,
      g: 238,
      b: 238,
    },
  },
  {
    article_number: "1JONE02012",
    color: "12 cream",
    fabric_uuid: "VADAIN-70022272-1JONE02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02012&h=500&w=500&type=live",
    name: "Jones kh 12 cream",
    rgb: {
      r: 216,
      g: 207,
      b: 192,
    },
  },
  {
    article_number: "1JONE02013",
    color: "13 beach",
    fabric_uuid: "VADAIN-70022262-1JONE02013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02013&h=500&w=500&type=live",
    name: "Jones kh 13 beach",
    rgb: {
      r: 183,
      g: 175,
      b: 158,
    },
  },
  {
    article_number: "1JONE02015",
    color: "15 sand",
    fabric_uuid: "VADAIN-70022269-1JONE02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02015&h=500&w=500&type=live",
    name: "Jones kh 15 sand",
    rgb: {
      r: 180,
      g: 155,
      b: 128,
    },
  },
  {
    article_number: "1JONE02017",
    color: "17 dark linnen",
    fabric_uuid: "VADAIN-70022271-1JONE02017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02017&h=500&w=500&type=live",
    name: "Jones kh 17 dark linnen",
    rgb: {
      r: 147,
      g: 127,
      b: 103,
    },
  },
  {
    article_number: "1JONE02027",
    color: "27 copper",
    fabric_uuid: "VADAIN-70022265-1JONE02027",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02027.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02027&h=500&w=500&type=live",
    name: "Jones kh 27 copper",
    rgb: {
      r: 157,
      g: 113,
      b: 80,
    },
  },
  {
    article_number: "1JONE02038",
    color: "38 forest green",
    fabric_uuid: "VADAIN-70022267-1JONE02038",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02038.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02038&h=500&w=500&type=live",
    name: "Jones kh 38 forest green",
    rgb: {
      r: 73,
      g: 94,
      b: 95,
    },
  },
  {
    article_number: "1JONE02063",
    color: "63 heather",
    fabric_uuid: "VADAIN-70022261-1JONE02063",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02063.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02063&h=500&w=500&type=live",
    name: "Jones kh 63 heather",
    rgb: {
      r: 151,
      g: 113,
      b: 114,
    },
  },
  {
    article_number: "1JONE02086",
    color: "86 stone",
    fabric_uuid: "VADAIN-70022263-1JONE02086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02086&h=500&w=500&type=live",
    name: "Jones kh 86 stone",
    rgb: {
      r: 134,
      g: 125,
      b: 119,
    },
  },
  {
    article_number: "1JONE02087",
    color: "87 nutmeg",
    fabric_uuid: "VADAIN-70022268-1JONE02087",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02087.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02087&h=500&w=500&type=live",
    name: "Jones kh 87 nutmeg",
    rgb: {
      r: 154,
      g: 137,
      b: 126,
    },
  },
  {
    article_number: "1JONE02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70022259-1JONE02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02092&h=500&w=500&type=live",
    name: "Jones kh 92 silver",
    rgb: {
      r: 143,
      g: 143,
      b: 142,
    },
  },
  {
    article_number: "1JONE02093",
    color: "93 grey melee",
    fabric_uuid: "VADAIN-70022264-1JONE02093",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02093.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02093&h=500&w=500&type=live",
    name: "Jones kh 93 grey melee",
    rgb: {
      r: 114,
      g: 114,
      b: 114,
    },
  },
  {
    article_number: "1JONE02094",
    color: "94 anthracite",
    fabric_uuid: "VADAIN-70022270-1JONE02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1JONE02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1JONE02094&h=500&w=500&type=live",
    name: "Jones kh 94 anthracite",
    rgb: {
      r: 93,
      g: 93,
      b: 91,
    },
  },
  {
    article_number: "1LAND01011",
    color: "11 cream",
    fabric_uuid: "VADAIN-70018516-1LAND01011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LAND01011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LAND01011&h=500&w=500&type=live",
    name: "Landmark 11 cream",
    rgb: {
      r: 199,
      g: 189,
      b: 174,
    },
  },
  {
    article_number: "1LAND01012",
    color: "12 sand",
    fabric_uuid: "VADAIN-70018517-1LAND01012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LAND01012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LAND01012&h=500&w=500&type=live",
    name: "Landmark 12 sand",
    rgb: {
      r: 168,
      g: 155,
      b: 140,
    },
  },
  {
    article_number: "1LAND01052",
    color: "52 denim",
    fabric_uuid: "VADAIN-70018518-1LAND01052",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LAND01052.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LAND01052&h=500&w=500&type=live",
    name: "Landmark 52 denim",
    rgb: {
      r: 94,
      g: 96,
      b: 100,
    },
  },
  {
    article_number: "1LAND01053",
    color: "53 ocean",
    fabric_uuid: "VADAIN-70018519-1LAND01053",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LAND01053.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LAND01053&h=500&w=500&type=live",
    name: "Landmark 53 ocean",
    rgb: {
      r: 133,
      g: 138,
      b: 134,
    },
  },
  {
    article_number: "1LAND01090",
    color: "90 mouse grey",
    fabric_uuid: "VADAIN-70018520-1LAND01090",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LAND01090.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LAND01090&h=500&w=500&type=live",
    name: "Landmark 90 mouse grey",
    rgb: {
      r: 143,
      g: 142,
      b: 140,
    },
  },
  {
    article_number: "1LAND01092",
    color: "92 chinchilla",
    fabric_uuid: "VADAIN-70018521-1LAND01092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LAND01092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LAND01092&h=500&w=500&type=live",
    name: "Landmark 92 chinchilla",
    rgb: {
      r: 151,
      g: 149,
      b: 143,
    },
  },
  {
    article_number: "1LIBE02010",
    color: "10 white",
    fabric_uuid: "VADAIN-70022232-1LIBE02010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02010&h=500&w=500&type=live",
    name: "Liberty kh 10 white",
    rgb: {
      r: 224,
      g: 224,
      b: 222,
    },
  },
  {
    article_number: "1LIBE02012",
    color: "12 sand",
    fabric_uuid: "VADAIN-70022233-1LIBE02012",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02012.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02012&h=500&w=500&type=live",
    name: "Liberty kh 12 sand",
    rgb: {
      r: 207,
      g: 199,
      b: 192,
    },
  },
  {
    article_number: "1LIBE02014",
    color: "14 natural",
    fabric_uuid: "VADAIN-70022234-1LIBE02014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02014&h=500&w=500&type=live",
    name: "Liberty kh 14 natural",
    rgb: {
      r: 185,
      g: 179,
      b: 177,
    },
  },
  {
    article_number: "1LIBE02018",
    color: "18 pebble",
    fabric_uuid: "VADAIN-70022235-1LIBE02018",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02018.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02018&h=500&w=500&type=live",
    name: "Liberty kh 18 peble",
    rgb: {
      r: 184,
      g: 180,
      b: 173,
    },
  },
  {
    article_number: "1LIBE02020",
    color: "20 vanilla",
    fabric_uuid: "VADAIN-70022236-1LIBE02020",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02020.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02020&h=500&w=500&type=live",
    name: "Liberty kh 20 vanilla",
    rgb: {
      r: 200,
      g: 192,
      b: 176,
    },
  },
  {
    article_number: "1LIBE02025",
    color: "25 ochre",
    fabric_uuid: "VADAIN-70022239-1LIBE02025",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02025.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02025&h=500&w=500&type=live",
    name: "Liberty kh 25 ochre",
    rgb: {
      r: 146,
      g: 116,
      b: 75,
    },
  },
  {
    article_number: "1LIBE02029",
    color: "29 brick",
    fabric_uuid: "VADAIN-70022240-1LIBE02029",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02029.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02029&h=500&w=500&type=live",
    name: "Liberty kh 29 brick",
    rgb: {
      r: 143,
      g: 82,
      b: 49,
    },
  },
  {
    article_number: "1LIBE02036",
    color: "36 dusty mint",
    fabric_uuid: "VADAIN-70022241-1LIBE02036",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02036.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02036&h=500&w=500&type=live",
    name: "Liberty kh 36 dusty mint",
    rgb: {
      r: 143,
      g: 154,
      b: 148,
    },
  },
  {
    article_number: "1LIBE02038",
    color: "38 moss",
    fabric_uuid: "VADAIN-70022242-1LIBE02038",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02038.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02038&h=500&w=500&type=live",
    name: "Liberty kh 38 moss",
    rgb: {
      r: 93,
      g: 91,
      b: 78,
    },
  },
  {
    article_number: "1LIBE02046",
    color: "46 blush pink",
    fabric_uuid: "VADAIN-70022243-1LIBE02046",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02046.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02046&h=500&w=500&type=live",
    name: "Liberty kh 46 blush pink",
    rgb: {
      r: 176,
      g: 146,
      b: 136,
    },
  },
  {
    article_number: "1LIBE02057",
    color: "57 cloud",
    fabric_uuid: "VADAIN-70022246-1LIBE02057",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02057.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02057&h=500&w=500&type=live",
    name: "Liberty kh 57 cloud",
    rgb: {
      r: 95,
      g: 111,
      b: 125,
    },
  },
  {
    article_number: "1LIBE02059",
    color: "59 royal blue",
    fabric_uuid: "VADAIN-70022247-1LIBE02059",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02059.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02059&h=500&w=500&type=live",
    name: "Liberty kh 59 royal blue",
    rgb: {
      r: 48,
      g: 65,
      b: 82,
    },
  },
  {
    article_number: "1LIBE02062",
    color: "62 purple",
    fabric_uuid: "VADAIN-70022248-1LIBE02062",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02062.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02062&h=500&w=500&type=live",
    name: "Liberty kh 62 purple",
    rgb: {
      r: 120,
      g: 95,
      b: 103,
    },
  },
  {
    article_number: "1LIBE02080",
    color: "80 nutmeg",
    fabric_uuid: "VADAIN-70022250-1LIBE02080",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02080.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02080&h=500&w=500&type=live",
    name: "Liberty kh 80 nutmeg",
    rgb: {
      r: 149,
      g: 124,
      b: 107,
    },
  },
  {
    article_number: "1LIBE02086",
    color: "86 mocha",
    fabric_uuid: "VADAIN-70022252-1LIBE02086",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02086.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02086&h=500&w=500&type=live",
    name: "Liberty kh 86 mocha",
    rgb: {
      r: 86,
      g: 72,
      b: 65,
    },
  },
  {
    article_number: "1LIBE02091",
    color: "91 sky grey",
    fabric_uuid: "VADAIN-70022253-1LIBE02091",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02091.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02091&h=500&w=500&type=live",
    name: "Liberty kh 91 sky grey",
    rgb: {
      r: 161,
      g: 160,
      b: 156,
    },
  },
  {
    article_number: "1LIBE02092",
    color: "92 silver",
    fabric_uuid: "VADAIN-70022254-1LIBE02092",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02092.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02092&h=500&w=500&type=live",
    name: "Liberty kh 92 silver",
    rgb: {
      r: 157,
      g: 155,
      b: 156,
    },
  },
  {
    article_number: "1LIBE02094",
    color: "94 mid grey",
    fabric_uuid: "VADAIN-70022255-1LIBE02094",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02094.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02094&h=500&w=500&type=live",
    name: "Liberty kh 94 mid grey",
    rgb: {
      r: 93,
      g: 95,
      b: 98,
    },
  },
  {
    article_number: "1LIBE02095",
    color: "95 black",
    fabric_uuid: "VADAIN-70022256-1LIBE02095",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02095.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02095&h=500&w=500&type=live",
    name: "Liberty kh 95 black",
    rgb: {
      r: 50,
      g: 50,
      b: 52,
    },
  },
  {
    article_number: "1LIBE02096",
    color: "96 anthracite",
    fabric_uuid: "VADAIN-70022257-1LIBE02096",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LIBE02096.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LIBE02096&h=500&w=500&type=live",
    name: "Liberty kh 96 anthracite",
    rgb: {
      r: 77,
      g: 79,
      b: 78,
    },
  },
  {
    article_number: "1LINE02011",
    color: "11 off white",
    fabric_uuid: "VADAIN-70000536-1LINE02011",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINE02011.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINE02011&h=500&w=500&type=live",
    name: "Linea kh 11 off white",
    rgb: {
      r: 174,
      g: 174,
      b: 174,
    },
  },
  {
    article_number: "1LINE02015",
    color: "15 room",
    fabric_uuid: "VADAIN-70005864-1LINE02015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINE02015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINE02015&h=500&w=500&type=live",
    name: "Linea kh 15 room",
    rgb: {
      r: 172,
      g: 172,
      b: 170,
    },
  },
  {
    article_number: "1LINO01008",
    color: "08 beach",
    fabric_uuid: "VADAIN-70000195-1LINO01008",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01008.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01008&h=500&w=500&type=live",
    name: "Linos 08 beach",
    rgb: {
      r: 206,
      g: 191,
      b: 173,
    },
  },
  {
    article_number: "1LINO01009",
    color: "09 shelve",
    fabric_uuid: "VADAIN-70000196-1LINO01009",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01009.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01009&h=500&w=500&type=live",
    name: "Linos 09 shelve",
    rgb: {
      r: 192,
      g: 177,
      b: 161,
    },
  },
  {
    article_number: "1LINO01010",
    color: "10 wit",
    fabric_uuid: "VADAIN-2685-1LINO01010",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01010.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01010&h=500&w=500&type=live",
    name: "Linos 10 wit",
    rgb: {
      r: 215,
      g: 216,
      b: 218,
    },
  },
  {
    article_number: "1LINO01013",
    color: "13 biscuit",
    fabric_uuid: "VADAIN-2687-1LINO01013",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01013.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01013&h=500&w=500&type=live",
    name: "Linos 13 biscuit",
    rgb: {
      r: 197,
      g: 181,
      b: 160,
    },
  },
  {
    article_number: "1LINO01014",
    color: "14 dessert",
    fabric_uuid: "VADAIN-2688-1LINO01014",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01014.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01014&h=500&w=500&type=live",
    name: "Linos 14 desert",
    rgb: {
      r: 189,
      g: 173,
      b: 156,
    },
  },
  {
    article_number: "1LINO01015",
    color: "15 beige",
    fabric_uuid: "VADAIN-2689-1LINO01015",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01015.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01015&h=500&w=500&type=live",
    name: "Linos 15 beige",
    rgb: {
      r: 193,
      g: 189,
      b: 183,
    },
  },
  {
    article_number: "1LINO01016",
    color: "16 nevel",
    fabric_uuid: "VADAIN-2690-1LINO01016",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01016.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01016&h=500&w=500&type=live",
    name: "Linos 16 nevel",
    rgb: {
      r: 196,
      g: 195,
      b: 190,
    },
  },
  {
    article_number: "1LINO01017",
    color: "17 linnen",
    fabric_uuid: "VADAIN-2600-1LINO01017",
    image: "https://fotorepo-staging.vadain.nl/SAMPLES/THUMBS/1LINO01017.JPG",
    rendered_image:
      "https://integrate.materialo.com/v2.0/get_img_material.php?cnf=vadain&artnr=1LINO01017&h=500&w=500&type=live",
    name: "Linos 17 linnen",
    rgb: {
      r: 169,
      g: 163,
      b: 156,
    },
  },
];
