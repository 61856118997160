var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('fabric-info-dialog',{attrs:{"show-dialog":_vm.fabricInfoDialog,"fabric-details":_vm.fabricDetails},on:{"closeDialog":function($event){_vm.fabricInfoDialog = false}}}),(_vm.colors && _vm.avgColor)?_c('div',{staticClass:"colorsGrid"},_vm._l((_vm.colors),function(color,key){return _c('v-tooltip',{key:key,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"auto","margin-bottom":"5px"},attrs:{"text":"","icon":""},on:{"click":function($event){_vm.showInfo(_vm.suggestFabric(color.rgb))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v("Info")])])}),1):_vm._e(),(_vm.colors && _vm.avgColor)?_c('div',{staticClass:"colorsGrid"},_vm._l((_vm.colors),function(color,key){return _c('v-tooltip',{key:key,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.suggestFabric(color.rgb).rendered_image},on:{"click":function($event){_vm.$emit(
              'selectPreviewImage',
              _vm.suggestFabric(color.rgb).rendered_image
            )}}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("See on picture")])])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }